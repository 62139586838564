import React from "react";
import { Instagram, Mail, Share2, Info } from "react-feather";
import StarRatings from "react-star-ratings";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import ImageSection from "components/AboutMe/ImageSection";
import ImageSlider from "components/AboutMe/ImageSlider";
import Food1 from "assets/images/food_1.jpg";
import Food2 from "assets/images/food_2.jpg";
import Food3 from "assets/images/food_3.jpg";
import Food4 from "assets/images/food_4.jpg";
import IOSFemale from "assets/images/ios-female.png";
import * as A from "./AboutMe.styles";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import FooterNew from "components/FooterNew/FooterNew";
const AboutMe = () => {
  return (
    <>
      <StickyNavbar openSurveyModal={() => {}} />
      <A.OverviewWrapper>
        <div className="backColor" />

        <A.CardContainer>
          <ImageSection>
            <A.SocialButtons>
              <A.IconButton>
                <Instagram size={20} />
              </A.IconButton>
              <A.IconButton>
                <Mail size={20} />
              </A.IconButton>
              <A.IconButton>
                <Share2 size={20} />
              </A.IconButton>
            </A.SocialButtons>
          </ImageSection>

          <A.InfoCard>
            <A.Header>
              <A.Name className="text-2xl">Sarah Ostwald</A.Name>
              <A.Reviews>
                <img className="img" src={IOSFemale} />
                <A.Age className="text-xs">25 Jahre</A.Age>
                <A.ResponsiveStarRatings>
                  <StarRatings
                    rating={5}
                    starRatedColor="#FFD700"
                    numberOfStars={5}
                    starDimension="16px"
                    starSpacing="2px"
                  />
                </A.ResponsiveStarRatings>
                <span className="text-sm">12 reviews / 14 sessions</span>
              </A.Reviews>
            </A.Header>
            <A.BreakLine />

            <A.Grid>
              <A.GridItem>
                <A.GridTitle>Spezialgebiet</A.GridTitle>
                <A.GridText className="text-lg">Gesunde Ernährung</A.GridText>
              </A.GridItem>
              <A.GridItem>
                <A.GridTitle>Coach seit </A.GridTitle>
                <A.GridText className="text-lg">2020</A.GridText>
              </A.GridItem>
              <A.GridItem>
                <A.GridTitle>Aus/Weiterbildung</A.GridTitle>
                <A.GridText className="text-lg">3</A.GridText>
              </A.GridItem>
              <A.GridItem>
                <A.GridTitle>Praxiserfahrung</A.GridTitle>
                <A.GridText className="text-lg">ja</A.GridText>
              </A.GridItem>
            </A.Grid>
            <A.BreakLine />

            <A.Qualifications>
              <h3>Meine Qualifikationen:</h3>
              <A.QualificationItem>
                <span>Abschluss: 2024</span>
                <span className="border">|</span>
                <span>Kind of: Master of Nutritionist</span>
                <Info size={16} />
              </A.QualificationItem>
              <A.QualificationItem>
                <span>Abschluss: 2024</span>
                <span className="border">|</span>
                <span>Kind of: Master of Nutritionist</span>
                <Info size={16} />
              </A.QualificationItem>
              <A.QualificationItem>
                <span>Abschluss: 2024</span>
                <span className="border">|</span>
                <span>Kind of: Master of Nutritionist</span>
                <Info size={16} />
              </A.QualificationItem>
            </A.Qualifications>

            <A.Button className="text-lg">Erstgespräch Vereinbaren</A.Button>
          </A.InfoCard>
        </A.CardContainer>
      </A.OverviewWrapper>
      <A.VideoSectionWrapper>
        <A.ContentGrid>
          <A.ContentSection>
            <A.SectionTag>ÜBER MICH</A.SectionTag>
            <h1>Ich unterstütze dich mit meinem kostenlosen Erstgespräch</h1>
            <p>
              Als Trainer und Ernährungsberater für große Fitnessstudios wie
              Cleverfit und Fitone habe ich schon vor 10 Jahren damit begonnen
              Menschen die Ernährungsumstellung zu vereinfachen und zahlreiche
              Ernährungspläne erstellt.
              <br />
              <br />
              Im Jahr 2015 habe ich dafür eine Software entwickelt, mit welchen
              Fitnessstudios ihre Kunden betreuen können und im Jahr 2020 die
              App von Foodiary gegründet.
            </p>
            <A.ConsultButton>Erstgespräch vereinbaren</A.ConsultButton>
          </A.ContentSection>

          <ImageSection headerText={"Video von Coachfinder"} />
        </A.ContentGrid>
      </A.VideoSectionWrapper>
      <A.ImagesSection>
        <A.ImageSectionTitle>
          EIN ONLINE-KURS, DER VON ALLEN KRANKENKASSEN
        </A.ImageSectionTitle>
        <A.ImageSectionText className="text-3xl font-light">
          ANERKANNT IST UND BIS ZU 100% ERSTATTET WERDEN
        </A.ImageSectionText>
        <A.ImageGrid>
          <A.ImageSectionParent>
            <A.SectionImage src={Food4} alt="Coach profile" />
          </A.ImageSectionParent>
          <A.ImageSectionParent>
            <A.SectionImage src={Food3} alt="Coach profile" />
          </A.ImageSectionParent>
          <A.ImageSectionParent>
            <A.SectionImage src={Food2} alt="Coach profile" />
          </A.ImageSectionParent>
          <A.ImageSectionParent>
            <A.SectionImage src={Food1} alt="Coach profile" />
          </A.ImageSectionParent>
        </A.ImageGrid>
      </A.ImagesSection>
      <A.BannerSection>
        <ImageSection />
        <A.BannerContent>
          {[1, 2, 3, 4].map((index) => (
            <div key={index}>
              <A.BannerHeading className="text-2xl">
                Gemeinsam mit mir zu einem gesünderen Leben
              </A.BannerHeading>
              <A.BannerText>
                Seit über 12 Jahren beschäftige ich mich als aktiver Sportler
                mit dem Thema Sport und Ernährung.
              </A.BannerText>
              <A.BreakLine style={{ marginBottom: -10 }} />
            </div>
          ))}
          <A.ConsultationButton className="text-lg">
            Erstgespräch vereinbaren
          </A.ConsultationButton>
        </A.BannerContent>
      </A.BannerSection>
      <A.TestimonialsSection>
        <ImageSlider />
      </A.TestimonialsSection>
      <InitialConsultationBox />
      <FooterNew />
    </>
  );
};

export default AboutMe;
