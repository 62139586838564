import styled from "styled-components";

export const SectionContainer = styled.div`
  background: #eeeeee;
  padding: 60px;
  padding-inline: 30px;

  @media (max-width: 600px) {
    padding: 7rem 30px 7rem 30px !important;
    /* padding-top: 5rem; */
  }
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    max-width: 151.7rem;
    margin: 0px auto;
    align-items: center;

    @media screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;
    }
  }

  .survey-content {
    display: flex;
    flex-direction: column;
    color: #142c1f;

    @media screen and (max-width: 600px) {
      .survey-headline h3 {
        font-size: 3.2rem !important;
      }

      p {
        text-align: center;
      }
    }

    .survey-description {
      > * {
        margin-bottom: 20px;
        text-align: left;
      }
    }

    > * {
      margin-bottom: 20px;
    }
  }

  .form-wrapper {
    width: 100%;
  }
`;
