const ONBOARDING_STORE_KEY = 'onboarding_store_key'
export const GENDER_STEP = 'onboarding_gender'
export const GOAL_STEP = 'onboarding_goal'
export const BODY_DETAILS_STEP = 'onboarding_body_details'
export const DAILY_ROUTINE_STEP = 'onboarding_daily_routine'
export const ACTIITY_LEVEL_STEP = 'onboarding_activity_level'
export const TRAINING_DAYS_STEP = 'onboarding_training_days'
export const PREFERRED_MEALS_STEP = 'onboarding_preferred_meals'
export const REGISTER_STEP = 'onboarding_register'

export {
  setOnboardingStorage,
  getOnboardingStorage,
  clearOnboardingStorage
}

function setOnboardingStorage(step, data) {
  const existingData = JSON.parse(localStorage.getItem(ONBOARDING_STORE_KEY)) || {}
  const updatedData = { ...existingData, [step]: data };
  localStorage.setItem(
    ONBOARDING_STORE_KEY,
    JSON.stringify(updatedData)
  )
}

function getOnboardingStorage(step = null) {
  const savedData = JSON.parse(localStorage.getItem(ONBOARDING_STORE_KEY)) || {}
  if (!step) return savedData
  return savedData[step] || {}
}

function clearOnboardingStorage() {
  localStorage.removeItem(ONBOARDING_STORE_KEY)
}
