import React, { useState } from "react";
import Chip from "components/General/formComponents/chip/Chip";
import Accordian from "components/Accordians/Accordian";
import * as C from "./FullSupport.styled";
// Images
import FoodiaryRezepte from "assets/images/Coach-Rezepte-App.jpg";
import FoodiaryRezeptkarte from "assets/images/Coach-Rezepte.jpg";
import FoodiaryCoachCard from "assets/images/Coach-eigener-Coach.jpg";
import FoodiaryCoaching from "assets/images/Coach-Lernacademy.jpg";
import FoodiaryDashboard from "assets/images/planer-App.jpg";
import FoodiaryKalorienverlauf from "assets/images/Coach-Fortschritt.jpg";

const Questions = [
  {
    id: 1,
    img: FoodiaryRezepte,
    title: "Persönlicher Ernährungsplan",
    info: "Als dein Foodiarv Coach ermittle ich in einem persönlichen Gespräch dein Ziel, deine tägliche Aktivität und erstelle deinen persönlichen Ernährungsplan.",
  },
  {
    id: 2,
    img: FoodiaryRezeptkarte,
    title: "Über 1700 Rezepte",
    info: `Über 1700 Rezepte werden dank künstlicher Intelligenz optimal auf deinen
    Ernährungsplan angepasst. Du entscheidest ganz nach deinen Wünschen, welches
    Rezept du zubereiten möchtest.`,
  },
  {
    id: 3,
    img: FoodiaryCoachCard,
    title: "Eigener Ernährungscoach",
    info: "Während deiner Ernährungsumstellung betreue ich dich als dein persönlicher Ernährungscoach, überwache deinen Fortschritt und helfe dir zum Erfolg.",
  },
  {
    id: 4,
    img: FoodiaryCoaching,
    title: "Digitale Lernacademy",
    info: "Damit deine Ernährungsumstellung ein dauerhafter Erfolg bleibt, erhältst du von uns in der Foodiary Academy über 15 Lernvideos und E-Books.",
  },
  {
    id: 5,
    img: FoodiaryDashboard,
    title: "Moderne Ernährungsplaner-App",
    info: "Die tägliche Ernährungsplanung wird dir durch unsere Ernährungsplaner-App deutlich vereinfacht. Du wählst flexibel deine Rezepte und erreichst dein Tagesziel.",
  },
  {
    id: 6,
    img: FoodiaryKalorienverlauf,
    title: "Wöchentliche Erfolgsüberprüfung",
    info: "In einer wöchentlichen Erfolgskontrolle überprüfen wir deinen Fortschritt. Ich schaue mir diesen an und greife bei Auffälligkeiten sofort ein.",
  },
];

const FullSupport = () => {
  const [image, setImage] = useState(FoodiaryRezepte);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const onToggle = (question) => {
    setImage(question?.img);
    setActiveQuestion(question);
  };

  return (
    <C.CoachingAccordianContainer>
      <C.TextContent>
        <Chip text="Ganzheitlich" margin="0 0 20px 0" />
        <C.Title className="ct-text-block color-dark text-left font-medium">
          Umfassender Support mit zahlreichen Benefits.
        </C.Title>
        <C.Description>
          Meine persönliche Betreuung beinhaltet nicht nur den regelmäßigen
          Austausch mit mir, sondern auch zahlreiche praktische Tools für eine
          einfache und nachhaltige Umstellung.
        </C.Description>
        <Accordian
          data={Questions}
          activeQuestion={activeQuestion}
          onExpanded={(question) => onToggle(question)}
        />
      </C.TextContent>
      <C.VisualContent>
        <img src={image} alt="advantage" />
      </C.VisualContent>
    </C.CoachingAccordianContainer>
  );
};

export default FullSupport;
