import styled from "styled-components";

export const WhyIamYourCoachContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 60px;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 50px;
  }
  .image-preview {
    display: flex;
    justify-content: flex-start;
    .image-preview-wrapper {
      background: white;
      padding: 10px;
      border-radius: 15px;
      height: auto; /* Let content drive height for better flexibility */
      box-shadow: ${(props) =>
        !props.isDefaultImg ? "0px 10px 15px -3px rgba(0, 0, 0, 0.1)" : "none"};

      @media screen and (max-width: 600px) {
        padding: 8px;
        height: auto;
        img {
          width: 100% !important;
          height: auto !important;
        }
      }

      img {
        display: block; /* Avoid inline spacing issues */
        height: auto; /* Preserve aspect ratio */
        border-radius: inherit; /* Match parent border-radius for consistent look */
        object-fit: cover; /* Ensure image covers the entire area without distortion */
        width: ${(props) =>
          !props.isDefaultImg
            ? "470px"
            : "100%"}; /* Use max-width instead of fixed width */
        height: ${(props) => (!props.isDefaultImg ? "580px" : "auto")};
      }
    }
  }

  .content-preview {
    display: flex;
    flex-direction: column;
    /* color: #44554c; */

    @media screen and (max-width: 600px) {
      h3 {
        font-size: 3.2rem !important;
      }
    }

    > * {
      margin-bottom: 20px;
    }

    /* .cta-overview {
      @media screen and (max-width: 600px) {
        display: flex;
        justify-content: flex-start;
      }
    } */
  }
`;
