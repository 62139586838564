import styled from "styled-components";
import {
  RegistrationContainer,
  RegistrationHeading,
  RegistrationSubHeading,
} from "pages/registration/registration.styles";

import {
  ButtonContainer,
  NutritionFormAdvantagesMobileHeading,
  NutritionFormAdvantagesImageContainer,
  NutritionFormAdvantagesHeadingContainer,
} from "pages/formAdvantages/formAdvantages.styles";

export const NutritionFormContainer = styled(RegistrationContainer)``;

export const NutritionFormHeading = styled(RegistrationHeading)``;

export const NutritionFormSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormImageContainer = styled(
  NutritionFormAdvantagesImageContainer
)``;

export const NutritionFormHeadingContainer = styled(
  NutritionFormAdvantagesHeadingContainer
)``;

export const NextButtonContainer = styled(ButtonContainer)``;

export const NutritionFormMobileHeading = styled(
  NutritionFormAdvantagesMobileHeading
)``;

export const NutritionFormDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const NutritionFormDescription = styled.div`
  font-size: 16px;
  font-family: RubikRegular;
  color: rgba(68, 68, 68, 0.6);
  line-height: 23px;
  margin: 40px 25px 30px 25px;
  text-align: left;
  max-width: 70%;
  @media (max-width: 600px) {
    margin-top: 0px;
    margin-left: 0px;
    font-size: 14px;
    color: #44554c;
    line-height: 18px;
    padding: 0px 10px 0px 30px;
    height: 200px;
    overflow: scroll;
  }
`;

export const NutritionFormCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
`;

export const NutritionFormCardContainerModal = styled.div`
  display: inline-flex;
  //flex-direction: row;
  justify-content: space-around;
  max-width: 650px;
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
`;

export const NutritionFormMobileDescriptionCard = styled.div`
  margin: 0px 100px 0px 100px;
  @media (max-width: 600px) {
    background: white;
    height: 100%;
    margin: 40px 10px 0px 10px;
  }
`;

export const NutritionFormCard = styled.div`
  width: 172px;
  height: 151px;
  cursor: pointer;
  background: ${(props) => {
    if (props.backgroundColor) return props.backgroundColor;
    else if (props.selected) return "#F3FFFD";
    else return "#F5F5F5";
  }};
  text-align: center;
  margin-top: 10px;
  border-radius: 10px;
  color: #142c1f;
  border: ${(props) => {
    if (props.selected) return "2px solid #2ECF96";
    else if (props.backgroundColor) return "2px solid white";
    else return "2px solid #F5F5F5";
  }};
`;

export const NutritionFormCardInModal = styled.div`
  width: 172px;
  height: 151px;
  cursor: pointer;
  background: ${(props) => {
    if (props.backgroundColor) return props.backgroundColor;
    else if (props.selected) return "#F3FFFD";
    else return "#F5F5F5";
  }};
  text-align: center;
  margin-top: 10px;
  border-radius: 10px;
  margin-right: 10px;
  color: #142c1f;
  border: ${(props) => {
    if (props.selected) return "2px solid #2ECF96";
    else if (props.backgroundColor) return "2px solid white";
    else return "2px solid #F5F5F5";
  }};
  @media (max-width: 600px) {
    display: flex;
    width: 150px;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
  }
`;

export const NutritionFormDescriptionInModal = styled.div`
  font-size: 16px;
  font-family: RubikRegular;
  color: rgba(68, 68, 68, 0.6);
  line-height: 23px;
  margin: 40px 25px 30px 25px;
  text-align: left;
  @media (max-width: 600px) {
    max-width: 100%;
    height: 150px;
    overflow-y: scroll;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
    font-size: 14px;
    color: #44554c;
    line-height: 18px;
    padding: 0px 0px 0px 0px;
  }
`;

export const NutritionCardImage = styled.img`
  margin: 33px 67px 19px;
  @media (max-width: 600px) {
    margin: 16px 60px 16px;
    width: 40px;
    height: 49px;
    opacity: 0.5;
  }
`;

export const NutritionFormMobileTitle = styled.div`
  @media (max-width: 600px) {
    color: #142c1f;
    font-size: 22px;
    font-family: RubikRegular;
  }
`;
