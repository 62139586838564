import styled from "styled-components"

export const MainContainer = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 45%;
    margin: auto;
  }
`

export const NutritionTypeContainer = styled.div`
  .type-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .icon {
      padding: 20px;
      border: 1px solid #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
      border-radius: 10px;
    }

    .name {
      font-weight: bold;
    }
    
    .description {
      font-optical-sizing: auto;
      color: #44554C;
      font-size: 15px;
    }
  }
  
  .advantages-container {
    .headline {
      font-weight: bold;
      margin-bottom: 10px;
    }
    
    .list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 14px;

      > * {
        border: 1px solid #d0d5dd;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
        display: flex;
        align-items: flex-start;
        gap: 10px;

        .advantage-icon {
          background: #2ecf96;
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #ededed;
          color: white;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
`

export const Separator = styled.div`
  height: 1px;
  background: #e2e4e9;
  margin: 30px 0px;
`

export const ModalContent = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    .choice {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
      border: 2px solid #EFEFEF;
      box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 100ms ease-in-out;

      &:hover {
        transform: translateY(-5px);
      }
    }

    .active {
      border: 2px solid rgb(46, 207, 150);
      background: #F2FFFD;
    }
  }
  
  .action {
    display: flex;
    justify-content: center;
  }
  
  @media (min-width: 1000px) {
    .list {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .choice {
        min-width: 172px;
        min-height: 151px;
      }
    }
    
    .content {
      max-width: 800px;
    }
  }
`
