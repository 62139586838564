import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;

  .gender-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 45%;
    margin: auto;
  }
`

export const TermsContainer = styled.div`
  margin-top: 20px;

  .footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .check-item {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    > * {
      flex: 1;
    }

    .label {
      font-weight: 500;
      margin-bottom: 10px;
    }

    input {
      width: 100% !important;
      padding: 10px;
    }
  }
  
  @media (min-width: 1000px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`
