import backIcon from "assets/images/back-icon.svg";
import { Link, useHistory } from "react-router-dom";
import {
  getNextOnboardingPage,
  getPreviousOnboardingPage,
} from "../../../routes/onboardingRoutes";
import * as Styles from "./TermsInfoStyles";
import { Input, Checkbox, Select } from "antd";
import countryCodesJson from "./countryCodes.json";
import { useEffect, useState } from "react";
import { getOnboardingStorage, setOnboardingStorage } from "../storage";
import { useDispatch, useSelector } from "react-redux";
import * as CoachActions from "redux/coach/coach.actions";
import * as AuthActions from "redux/auth/auth.actions";
import { IS_LOCAL } from "configs/utils";
import { getPreparedRegistrationData } from "../utils";
import { REGISTER_STEP } from "../storage";
import OnboardingLoader from "../wrapper/OnboardingLoader";

function TermsInfo({ currentPath }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const coach = useSelector((state) => state.coach.coach);

  const [loading, setLoading] = useState(false);
  const [registrationError, setRegistrationError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [fieldData, setFieldData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dataCheckbox: false,
    termsCheckbox: false,
    selectedCountryCode: "+49",
  });

  useEffect(() => {
    const coach_website_url = IS_LOCAL
      ? "https://sebastian-fci1.foodiary.app"
      : `${window.location.protocol}//${window.location.host}`;

    if (coach) return;

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);

  useEffect(() => {
    setValidationErrors({});
  }, [fieldData]);

  const countryCodes = countryCodesJson.map((country) => ({
    ...country,
    label: `${country.flag} ${country.dial_code}`,
    value: country.dial_code,
  }));

  const handleInputChange = (field, newValue) => {
    setFieldData({
      ...fieldData,
      [field]: newValue,
    });
  };

  const validateFields = (fields = fieldData) => {
    const fieldNames = [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "dataCheckbox",
      "termsCheckbox",
    ];
    const errors = {};
    let foundErrors = false;
    errors["firstName"] = !fields["firstName"].length ? "Erforderlich" : null;
    errors["lastName"] = !fields["lastName"].length ? "Erforderlich" : null;
    errors["email"] = !fields["email"].length ? "Erforderlich" : null;
    errors["phoneNumber"] = !fields["phoneNumber"].length
      ? "Erforderlich"
      : null;
    errors["dataCheckbox"] = !fields["dataCheckbox"] ? "Erforderlich" : null;
    errors["termsCheckbox"] = !fields["termsCheckbox"] ? "Erforderlich" : null;

    for (let name of fieldNames) {
      if (errors[name]) foundErrors = true;
    }

    return { ...errors, foundErrors };
  };

  const handleNextPage = () => {
    const validatedFields = validateFields();
    if (validatedFields.foundErrors) {
      return setValidationErrors(validatedFields);
    }

    const storage = getOnboardingStorage();
    const registrationData = getPreparedRegistrationData({
      onboarding_storage: storage,
      coachInfo: coach,
      userInfo: fieldData,
    });

    setLoading(true);

    dispatch(
      AuthActions.registration1Request({
        formValues: registrationData,
        callback: (response) => {
          setLoading(false);

          if (!response.success) {
            return setRegistrationError(
              response.message || "An error has accured, please try again."
            );
          }

          setOnboardingStorage(REGISTER_STEP, { ...response });
          localStorage.setItem("userEmail", fieldData.email);
          history.push(getNextOnboardingPage(currentPath));
        },
      })
    );
  };

  if (loading) return <OnboardingLoader />;

  return (
    <div>
      <div className="step-header">
        <div className="header-content">
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">
              Wir erstellen direkt dein Ernährungsplan
            </div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">
            Weiter
          </span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className="section-titles-wrapper">
            <div className="primary-title">
              Bitte gib deine persönlichen Daten ein
            </div>
            <div className="accent-subtitle">
              Deine Daten benötigen wir zum ersten des Ernährungsplanes.
            </div>
            {validationErrors ? (
              <div className="error-message">{registrationError}</div>
            ) : (
              ""
            )}
          </div>

          <Styles.TermsContainer>
            <div className="grid">
              <div className="input">
                <div className="label">Vorname</div>
                <Input
                  value={fieldData.firstName}
                  onChange={(event) =>
                    handleInputChange("firstName", event.target.value)
                  }
                  placeholder="Vorname"
                  status={validationErrors.firstName ? "error" : ""}
                />
                <div className="error-message">
                  {validationErrors.firstName}
                </div>
              </div>
              <div className="input">
                <div className="label">Nachname</div>
                <Input
                  value={fieldData.lastName}
                  onChange={(event) =>
                    handleInputChange("lastName", event.target.value)
                  }
                  placeholder="Nachname"
                  status={validationErrors.lastName ? "error" : ""}
                />
                <div className="error-message">{validationErrors.lastName}</div>
              </div>
              <div className="input">
                <div className="label">Mail</div>
                <Input
                  value={fieldData.email}
                  onChange={(event) =>
                    handleInputChange("email", event.target.value)
                  }
                  placeholder="E-Mail"
                  status={validationErrors.email ? "error" : ""}
                />
                <div className="error-message">{validationErrors.email}</div>
              </div>
              <div className="input">
                <div className="label">Handynummer</div>
                <Input
                  addonBefore={
                    <Select
                      showSearch
                      options={countryCodes}
                      onChange={(value) =>
                        handleInputChange("selectedCountryCode", value)
                      }
                      defaultValue={fieldData.selectedCountryCode}
                      filterOption={(input, option) =>
                        option.value.includes(input) ||
                        option.name.toLowerCase().includes(input)
                      }
                    />
                  }
                  placeholder="Handynummer"
                  value={fieldData.phoneNumber}
                  onChange={(event) =>
                    handleInputChange("phoneNumber", event.target.value)
                  }
                  status={validationErrors.phoneNumber ? "error" : ""}
                />
                <div className="error-message">
                  {validationErrors.phoneNumber}
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="error-message">
                {validationErrors.dataCheckbox}
              </div>
              <div className="check-item">
                <Checkbox
                  onChange={() =>
                    handleInputChange("dataCheckbox", !fieldData.dataCheckbox)
                  }
                  checked={fieldData.dataCheckbox}
                />
                <span>
                  Der Teilnehmer erklärt sich damit einverstanden, dass seine
                  Daten für die Erstellung des individuellen Ernährungskonzeptes
                  verwendet, verarbeitet und gespeichert werden dürfen.
                </span>
              </div>

              <div className="error-message">
                {validationErrors.termsCheckbox}
              </div>
              <div className="check-item">
                <Checkbox
                  onChange={() =>
                    handleInputChange("termsCheckbox", !fieldData.termsCheckbox)
                  }
                  checked={fieldData.termsCheckbox}
                />
                <span>
                  Der Teilnehmer hat die{" "}
                  <a target="_blank" href="https://foodiary.app/terms/agb-app/">
                    AGB App
                  </a>{" "}
                  und die{" "}
                  <a
                    target="_blank"
                    href="https://foodiary.app/terms/datenschutz/"
                  >
                    Datenschutzbestimmungen
                  </a>{" "}
                  gelesen und verstanden.
                </span>
              </div>
            </div>
          </Styles.TermsContainer>
        </Styles.MainContainer>
      </div>
    </div>
  );
}

export default TermsInfo;
