import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { 
  getPreviousOnboardingPage,
  getNextOnboardingPage
} from '../../../routes/onboardingRoutes'
import * as Styles from './TrainingDaysStyles'
import { v4 as uuid } from 'uuid'
import { Checkbox, Switch, InputNumber } from 'antd'
import {
  getOnboardingStorage,
  setOnboardingStorage,
  TRAINING_DAYS_STEP
} from '../storage'

function TrainingDays ({ currentPath }) {

  const storage = getOnboardingStorage(TRAINING_DAYS_STEP)
  const history = useHistory()
  const [ fixedTrainingDays, setFixedTrainingDays ] = useState(storage.fixedTrainingDays || false)
  const [ fixedTrainingDaysNumber, setFixedTrainingDaysNumber ] = useState(storage.fixedTrainingDaysNumber || 0)
  const [ validationError, setValidationError ] = useState(null)

  const [ dayOptions, setDayOptions ] = useState(storage.dayOptions || [
    { id: uuid(), title: 'Montag', selected: false, requestDayId: 1 },
    { id: uuid(), title: 'Dienstag', selected: false, requestDayId: 2 },
    { id: uuid(), title: 'Mittwoch', selected: false, requestDayId: 3 },
    { id: uuid(), title: 'Donnerstag', selected: false, requestDayId: 4 },
    { id: uuid(), title: 'Freitag', selected: false, requestDayId: 5 },
    { id: uuid(), title: 'Samstag', selected: false, requestDayId: 6 },
    { id: uuid(), title: 'Sonntag', selected: false, requestDayId: 7 },
  ])

  useEffect(() => {
    setValidationError(null)
  }, [ dayOptions, fixedTrainingDays ])

  const updateSelectedDays = id => {
    setDayOptions(dayOptions.map(option => option.id === id ? ({
      ...option,
      selected: !option.selected
    }) : option))
  }

  const handleFixedTrainingNumberChanged = value => {
    if (value < 0 || value > 7) return
    return setFixedTrainingDaysNumber(value)
  }

  const handleNextPage = () => {
    const selectedDays = dayOptions.filter(day => day.selected)

    if (!selectedDays.length && !fixedTrainingDays) {
      return setValidationError('Bitte wähle mindestens ein Trainingstag')
    }

    setOnboardingStorage(
      TRAINING_DAYS_STEP,
      { dayOptions, fixedTrainingDays, fixedTrainingDaysNumber }
    )

    history.push(getNextOnboardingPage(currentPath))
  }

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">An welchen Tagen bist du aktiv?</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className='section-titles-wrapper'>
            <div className="primary-title">An welchen Tagen trainierst du?</div>
            <div className="accent-subtitle">Verrate uns, an welchen Tagen du sportlich aktiv bist.</div>
            { validationError ? <div className="error-message">{validationError}</div> : '' }
          </div>
          <div className="training-days-boxes">
            {dayOptions.map(option => (
              <Styles.ActivityLevelBox key={option.id} onClick={() => updateSelectedDays(option.id)}>
                <div className="box-content">
                  <div className='box-title'>{option.title}</div>
                  <div>
                    <Checkbox
                      checked={!fixedTrainingDays && option.selected}
                      onChange={() => updateSelectedDays(option.id)}
                      disabled={fixedTrainingDays}
                    />
                  </div>
                </div>
              </Styles.ActivityLevelBox>
            ))}
          </div>
          <Styles.Seperator />
          <Styles.Footer>
            <div className="content">
              <div className="headline">An welchen Tagen trainierst du?</div>
            </div>
            <div className="actions">
              <div className="subtitle">Feste Trainingstage</div>
              <Switch
                onChange={setFixedTrainingDays}
                checked={fixedTrainingDays}
                checkedChildren="Ja"
                unCheckedChildren="Nein"
              />
            </div>
          </Styles.Footer>
          {fixedTrainingDays ? (
            <Styles.FixedTrainingDaysBox>
              <div className="box-content">
                <div className='box-title'>Trainingseinheiten pro Woche</div>
                <div className='input-container'>
                  <Styles.InputControl onClick={() => handleFixedTrainingNumberChanged(fixedTrainingDaysNumber - 1)}>-</Styles.InputControl>
                  <InputNumber min={0} max={7} onChange={value => handleFixedTrainingNumberChanged(value)} value={fixedTrainingDaysNumber} defaultValue={0} />
                  <Styles.InputControl onClick={() => handleFixedTrainingNumberChanged(fixedTrainingDaysNumber + 1)}>+</Styles.InputControl>
                </div>
              </div>
            </Styles.FixedTrainingDaysBox>
          ) : ''}
        </Styles.MainContainer>
      </div>
    </div>
  )
}

export default TrainingDays
