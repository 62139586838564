import styled from "styled-components";

const ImageContainer = styled.div``;
const ImageCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 10px;
  flex: 0 0 400px;
  height: fit-content;

  @media (max-width: 992px) {
    flex: none;
    width: 100%;
  }
`;
const ImageView = styled.div`
  position: relative;
  flex: 0 0 400px;
  height: 550px;
  width: 100%;

  @media (max-width: 992px) {
    width: 100%;
    height: 500px;
  }
`;
const ProfileImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 100%;
  @media (max-width: 992px) {
    height: 500px;
  }
`;
const HeaderText = styled.h4`
  margin-bottom: 20px;
  margin-top: 20px;
  color: red;
  text-align: center;
`;

function ImageSection({ children, imagePath, headerText }) {
  return (
    <ImageContainer>
      {headerText && <HeaderText>{headerText}</HeaderText>}
      <ImageCard>
        <ImageView>
          <ProfileImage
            src="https://picsum.photos/200/300"
            alt="Coach profile"
          />
          {children}
        </ImageView>
      </ImageCard>
    </ImageContainer>
  );
}

export default ImageSection;
