import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./NutritionWellBeing.style";
import videoSource from "assets/videos/Coach-Animation-Lernacademy-imac.mp4";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";

const NutritionWellBeing = ({ changeOrder = false, onLeadFormModal }) => {
  const history = useHistory();
  const handleClick = () => history.push("online-kurs/starten");
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <div className="text-content">
        <Chip text="Präventionskurs" margin="0 0 20px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Mit bewusster Ernährung zu mehr Wohlbefinden.
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Im Online-Kurs lernst du in den kommenden Wochen die Grundlagen einer
          gesunden Ernährung. Mit diesem Wissen kannst du deine Ernährung im
          Alltag gezielt umstellen – unabhängig von Trends und
          Marketingversprechen. So triffst du fundierte Entscheidungen und
          entwickelst langfristig gesunde Essgewohnheiten. Zudem erhältst du
          praktische Tipps und Werkzeuge, die dir helfen, deine neuen Routinen
          mühelos in den Alltag zu integrieren.
        </C.Description>
        <C.Action>
          <button className="btn-dark" onClick={handleClick}>
            Kurs starten
          </button>
          <span>Ohne Abo, ohne Kündigung</span>
        </C.Action>
      </div>
      <C.VisualContent>
        {/* <img src={NutritionWellBeingBanner} alt="" /> */}
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default NutritionWellBeing;
