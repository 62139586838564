import React, { useState } from "react";
import * as C from "./CoachingHealthCompanies.styles";

// Images
// import FoodIconOne from "assets/images/App/techniker-krankenkasse-logo.svg";
// import FoodIconTwo from "assets/images/App/aok.svg";
// import FoodIconThree from "assets/images/App/DAK-Gesundheit_logo.svg";
// import FoodIconFour from "assets/images/App/barmer_krankenkasse_logo.svg";
// import FoodIconFive from "assets/images/App/ikk-gesundplus.svg";
import FoodIconOne from "assets/images/Aok-Foodiary-1.jpg";
import FoodIconTwo from "assets/images/Barmer-Foodiary.jpg";
import FoodIconThree from "assets/images/Bkk-Foodiary.jpg";
import FoodIconFour from "assets/images/DAK-foodiary-1.jpg";
import FoodIconFive from "assets/images/mhplus-foodiary.jpg";
import CircleImg from "assets/images/circle-img.png";
import Chip from "components/General/formComponents/chip/Chip";

const CoachingHealthCompanies = ({
  title = "100% Cashback von der Krankenkasse",
  bgColor = undefined,
  top = 0,
  hideImage = false,
  chipText = "ONLINE KURS",
}) => {
  return (
    <C.MealPlanContainer bgColor={bgColor}>
      {!hideImage && (
        <C.CircleImgeWrapper>
          <C.CircleImage top={top} src={CircleImg} />
        </C.CircleImgeWrapper>
      )}

      <div>
        <C.ContentWrapper>
          <Chip
            className="title"
            text={chipText}
            margin="0 0 20px 0"
            bg={!bgColor ? "white" : "#E7FAF3"}
          />
          <C.Title className="ct-headline foodiary-h2  color-dark font-medium">
            {title ?? "100% Cashback von deiner Krankenkasse"}
          </C.Title>
        </C.ContentWrapper>
        <C.HorizonalContentWrapper>
          <p className="color-dark ">Zertifizierte Kursleitung</p>
          <div className="divider" />
          <p className="color-dark ">Zugelassen nach §20 SGB V</p>
        </C.HorizonalContentWrapper>
        <C.RestaurauntsBox bgColor={bgColor}>
          <div className="restaurant">
            <img src={FoodIconOne} alt="" />
          </div>
          <div className="restaurant">
            <img src={FoodIconTwo} alt="" />
          </div>
          <div className="restaurant">
            <img src={FoodIconThree} alt="" />
          </div>
          <div className="restaurant">
            <img src={FoodIconFour} alt="" />
          </div>
          <div className="restaurant">
            <img src={FoodIconFive} alt="" />
          </div>
        </C.RestaurauntsBox>
      </div>
    </C.MealPlanContainer>
  );
};

export default CoachingHealthCompanies;
