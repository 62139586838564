import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;

  .activity-level-boxes {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;

    .activity-level-boxes {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
  
  @media (min-width: 1300px) {
    width: 45%;
    margin: auto;
  }
`

export const ActivityLevelBox = styled.div`
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .input-container {
    display: flex;
    gap: 5px;

    > * {
      padding: 5px 10px;
    }
  }

  .box-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .box-title {
      font-weight: 500;
      font-size: 20px;
    }
  }
  
  @media (min-width: 1000px) {
    .box-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > * {
        flex: 1;
      }
    }
  }
`

export const Seperator = styled.div`
  background: #E2E4E9;
  height: 1px;
  margin: 20px 0px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  .content {
    .headline {
      font-family: "Rubik", sans-serif !important;
      font-optical-sizing: auto;
      font-style: normal;
      color: #142c1f;
      font-weight: 500;
      max-width: 500px;
    }
    
    .subtitle {
      color: #818D88;
      max-width: 500px;
    }
  }
`
