import styled from "styled-components";

export const Wrap = styled.div`
  position: relative;
`;
export const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 600px) {
    margin-top: 5rem;
    display: block;
    gap: 20;
  }
`;
export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 2px solid #e3e5e9;
  border-radius: 18px;
  gap: 30px;
  padding: 10px 0px;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    gap: 0px;
    border: none;
    // horozontal scroll
    display: flex !important;
    overflow-x: auto;
    flex-direction: row !important;
    scroll-snap-type: x mandatory;
    touch-action: auto;
    border: none;
    & > *:nth-child(1) {
      background-size: contain;
      border-top-color: #e3e5e9;
      border-right-color: #e3e5e9;
      border-bottom-color: #e3e5e9;
      border-left-color: #e3e5e9;
      border-top-width: 0.2rem;
      border-right-width: 0.2rem;
      border-bottom-width: 0.2rem;
      border-left-width: 0.2rem;
      border-top-style: solid;
      border-right-style: none;
      border-bottom-style: solid;
      border-left-style: solid;
      flex-direction: column;
      display: flex;
      text-align: left;
      align-items: center;
      gap: 2.4rem;
      background-repeat: no-repeat;

      border-radius: 2rem;
      border-top-left-radius: 2rem;
      border-top-right-radius: 0rem;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 0rem;
      background-position: 0% 100%;
    }
    & > *:nth-child(2) {
      background-size: contain;
      border-top-color: #e3e5e9;
      border-right-color: #e3e5e9;
      border-bottom-color: #e3e5e9;
      border-left-color: #e3e5e9;
      border-top-width: 0.2rem;
      border-right-width: 0.2rem;
      border-bottom-width: 0.2rem;
      border-left-width: 0.2rem;
      border-top-style: solid;
      border-right-style: none;
      border-bottom-style: solid;
      border-left-style: none;
      flex-direction: column;
      display: flex;
      text-align: left;
      align-items: center;
      gap: 2.4rem;
      background-repeat: no-repeat;

      border-radius: 0rem;
      background-position: 0% 100%;
    }
    & > *:nth-child(3) {
      background-size: contain;
      border-top-color: #e3e5e9;
      border-right-color: #e3e5e9;
      border-bottom-color: #e3e5e9;
      border-left-color: #e3e5e9;
      border-top-width: 0.2rem;
      border-right-width: 0.2rem;
      border-bottom-width: 0.2rem;
      border-left-width: 0.2rem;
      border-top-style: solid;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: none;
      flex-direction: column;
      display: flex;
      text-align: left;
      align-items: center;
      gap: 2.4rem;
      background-repeat: no-repeat;

      border-radius: 2rem;
      border-top-left-radius: 0px;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 0px;
      background-position: 0% 100%;
    }
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
  scroll-snap-align: start;
  padding-inline: 0px;

  img {
    width: 100%;
  }

  &:hover {
    transform: translateY(-10px);
  }
  h5 {
    font-family: sans-serif;
    text-align: center;
    color: #142c1f;
    margin: 8px 0px;
  }
  @media (max-width: 992px) {
    gap: 0px;
    padding: 20px 10px;
    margin-left: -10px !important;
    margin-right: -10px !important;

    img {
      width: 350px;
    }

    &:hover {
      transform: translateY(0px);
    }
  }

  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;
export const ContentWrapper = styled.div`
  background: #e6f4f4;
  padding: 20px;
  border-radius: 0 0 20px 20px;
  text-align: left !important;
  @media (max-width: 992px) {
    height: 200px;
  }
  @media (max-width: 600px) {
    height: 180px;
  }
`;
export const CardTitle = styled.h6`
  color: #142c1f;
  text-align: left;
  margin: 20px 0px 25px;

  @media (max-width: 600px) {
    font-size: 16px;
    text-align: center;
  }
`;
export const Title = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 20px;

  b {
    font-weight: 500;
  }

  @media (max-width: 992px) {
    margin-bottom: 20px;
    text-align: left;
    font-size: 3.2rem;
  }
`;
export const CardDescription = styled.p`
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: left;
  max-width: 100%;
  margin: 10px 0px;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const PlusIconWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 36.5%;
  transform: translate(-50%, -50%);

  z-index: 1;
  @media (max-width: 1200px) {
    top: 30%;
  }
  @media (max-width: 992px) {
    top: 28%;
    /* display: none; */
  }
  @media (max-width: 600px) {
    top: 27.3%;
  }
`;

export const PlusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1c9d87;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 24px;
  box-shadow: 0px 4px 12px rgba(46, 207, 150, 0.2);

  &::after {
    content: "+";
    font-size: 5rem;
  }
  @media (max-width: 992px) {
    width: 55px;
    height: 55px;
    &::after {
      content: "+";
      font-size: 4rem;
    }
    /* display: none; */
  }
`;

export const CardContainer = styled.div`
  position: relative;

  @media (max-width: 992px) {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const CircleEclipse = styled.div`
  /* width: 60px;
  height: 60px; */
  /* border-radius: 50%; */
  /* background: #1c9d87; */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }
`;
export const CircleImage = styled.div`
  /* height: 210px; */
  width: 100%;
  img {
    width: 100% !important;
    height: 210px !important;
  }
`;
export const CardsSegment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border-radius: 18px;
  position: relative;
  @media (max-width: 992px) {
    gap: 20px;
    // horozontal scroll
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    touch-action: auto;
  }
`;
export const CardSegmentMobileWrapper = styled.div`
  padding: 15px;
  background: white;
  border: 2px solid #e3e5e9;
  border-radius: 18px;
`;
export const CardSegment = styled.div`
  background: ${(props) => props.bgColor};
  border-radius: 18px;
  padding: 20px;
  height: 100%;

  @media (max-width: 992px) {
    width: 70vw;
  }

  .card-wrapper {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  h5 {
    font-family: sans-serif;
    text-align: center;
    color: #142c1f;
  }
  h6 {
    text-align: center;
    font-family: sans-serif;
    text-align: center;
    margin-top: 8px;
    color: #303030;
    font-weight: 400;
  }
`;
export const CardSegmentTile = styled.div`
  width: 100%;
  padding: 2rem 6rem;
  background: white;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 18px;
  flex-direction: column;
  display: flex;
  gap: 6px;
  @media (max-width: 992px) {
    padding: 2rem;
  }
  .title {
    /* font-size: 16px; */
    color: #142c1f;
    text-align: center;
    font-weight: 500;
  }
  .desc {
    color: #7a8780;
    text-align: center;
    font-weight: 400;
  }
`;
