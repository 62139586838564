import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import {
  getPreviousOnboardingPage,
  getNextOnboardingPage
} from '../../../routes/onboardingRoutes'
import * as Styles from './GoalStyles'
import { Checkbox } from 'antd'
import Slimming from 'assets/images/onboarding/slimming.svg'
import HealthDiet from 'assets/images/onboarding/healthDiet.svg'
import MuscleBuilding from 'assets/images/onboarding/muscleBuilding.svg'
import MuscleBuildingBulk from 'assets/images/onboarding/muscleBuildingBulk.svg'
import {
  getOnboardingStorage,
  setOnboardingStorage,
  GOAL_STEP
} from '../storage'

function Goal ({ currentPath }) {
  const [ selectedGoal, setSelectedGoal ] = useState(getOnboardingStorage(GOAL_STEP))
  const [ validationError, setValidationError ] = useState(null)
  const history = useHistory()

  const options = [
    {
      title: 'Abnehmen',
      subtitle: 'Wir reduzieren deine Kalorien für ein Defizit.',
      value: 'Abnehmen',
      icon: Slimming,
      registrationValue: 1
    },
    {
      title: 'Gesunde Ernährung',
      subtitle: 'Deine Kalorien entsprechen dem Gesamtumsatz.',
      value: 'Gesunde Ernährung',
      icon: HealthDiet,
      registrationValue: 2
    },
    {
      title: 'Muskelaufbau',
      subtitle: 'Dein Kalorien befinden sich über dem Gesamtumsatz.',
      value: 'Muskelaufbau',
      icon: MuscleBuilding,
      registrationValue: 3
    },
    {
      title: 'Muskelaufbau pro',
      subtitle: 'Deine Kalorien werden deutlich erhöht.',
      value: 'Muskelaufbau pro',
      icon: MuscleBuildingBulk,
      registrationValue: 4
    },
  ]

  useEffect(() => {
    setValidationError(null)
  }, [ selectedGoal ])

  const handleNextPage = () => {
    if (!selectedGoal.value) {
      return setValidationError('Bitte wähle das Ziel')
    }

    setOnboardingStorage(GOAL_STEP, selectedGoal)
    history.push(getNextOnboardingPage(currentPath))
  }

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">Wir erstellen deinen Plan.</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className='section-titles-wrapper'>
            <div className="primary-title">Welches Ziel möchtest du in den kommenden 12 Wochen verfolgen ?</div>
            <div className="accent-subtitle">Das Ziel hat einen Einfluss auf deinen Kalorienbedarf.</div>
            <div className="small-subtitle">Wähle dein Ziel</div>
          </div>
          { validationError ? <div className="error-message">{validationError}</div> : '' }
          <div className="goal-options">
            {options.map(option => (
              <Styles.GoalOption
                key={option.value}
                onClick={() => setSelectedGoal(option)}
                selected={selectedGoal.value === option.value}
              >
                <div className="option-content">
                  <div className="icon">
                    <img src={option.icon} alt="" />
                  </div>
                  <div>
                    <div className="name">{option.title}</div>
                    <div className='subtitle'>{option.subtitle}</div>
                  </div>
                </div>
                <div className="option-checkbox">
                  <Checkbox
                    checked={selectedGoal.value === option.value}
                    onChange={() => setSelectedGoal(option)}
                  />
                </div>
              </Styles.GoalOption>
            ))}
          </div>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

export default Goal
