import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import {
  getPreviousOnboardingPage,
  getNextOnboardingPage
} from '../../../routes/onboardingRoutes'
import * as Styles from './ActivityLevelStyles'
import { Button, Select } from 'antd'
import { v4 as uuid } from 'uuid'
import { useEffect, useState } from 'react'
import {
  getOnboardingStorage,
  setOnboardingStorage,
  ACTIITY_LEVEL_STEP
} from '../storage'

function ActivityLevel ({ currentPath }) {
  const storage = getOnboardingStorage(ACTIITY_LEVEL_STEP)
  const [ validationError, setValidationError ] = useState(null)
  const history = useHistory()
  const [ totalHours, setTotalHours ] = useState(0)
  const [ remainingHours, setRemainingHours ] = useState(24)

  const hourOptions = Array.from({ length: 25 }, (_, hour) => ({
    label: `${hour}h`,
    value: hour
  }))

  const [ boxOptions, setBoxOptions ] = useState(storage.boxOptions || [
    { id: uuid(), title: 'Schlafen', movementtypeID: 1, hours: 0 },
    { id: uuid(), title: 'Liegen', movementtypeID: 2, hours: 0 },
    { id: uuid(), title: 'Stehen', movementtypeID: 3, hours: 0 },
    { id: uuid(), title: 'Sitzen', movementtypeID: 4, hours: 0 },
    { id: uuid(), title: 'Gehen', movementtypeID: 5, hours: 0 },
    { id: uuid(), title: 'Trainieren', movementtypeID: 6, hours: 0 },
  ])

  useEffect(() => {
    const total = boxOptions.reduce((acc, curr) => acc += curr.hours, 0)
    const remaining = 24 - total
    setTotalHours(total)
    setRemainingHours(remaining >= 0 ? remaining : 0)
    if (validationError) setValidationError(null)
  }, [ boxOptions ])

  const handleNextPage = () => {
    if (totalHours !== 24) return setValidationError('Der Tag muss genau 24 Stunden haben.')
    setOnboardingStorage(ACTIITY_LEVEL_STEP, { boxOptions })
    history.push(getNextOnboardingPage(currentPath))
  }

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">Wie aktiv bist du im Alltag?</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className='section-titles-wrapper'>
            <div className="primary-title">Wie setzt sich ein Tagesablauf zusammen</div>
            <div className="accent-subtitle">Wie gestaltest du einen durchschnittlichen Wochentag?</div>
            { validationError ? <div className="error-message">{validationError}</div> : '' }
          </div>
          <div className="activity-level-boxes">
            {boxOptions.map(option => (
              <Styles.ActivityLevelBox>
                <div className="box-content">
                  <div className='box-title'>{option.title}</div>
                  <div>
                    <Select
                      defaultValue={option.hours}
                      onChange={newHour => {
                        setBoxOptions(prevOptions => prevOptions.map(prevOption => {
                          if (prevOption.id === option.id) return ({
                            ...prevOption,
                            hours: newHour
                          })
                          return prevOption
                        }))
                      }}
                      value={option.hours}
                      options={hourOptions}
                      style={{ width: '100%', height: '45px' }}
                    />
                  </div>
                </div>
              </Styles.ActivityLevelBox>
            ))}
          </div>
          <Styles.Seperator />
          <Styles.Footer>
            <div className="content">
              <div className="headline">Gesamt</div>
              <div>noch {remainingHours} Stunden</div>
            </div>
            <div className="actions">
              <Button>{totalHours} Stunden</Button>
            </div>
          </Styles.Footer>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

export default ActivityLevel
