import styled from "styled-components";

export const Wrap = styled.div`
  .grid-cols-2 {
    gap: 50px;
  }
  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
