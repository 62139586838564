import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }

    @media (max-width: 991.99px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  color: #44554c;
`;

export const VisualContent = styled.div`
  img {
    width: 100%;
    max-width: 700px;
  }
`;

export const Action = styled.div`
  margin-top: 20px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: flex-start;
  }
`;
