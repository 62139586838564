import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;

  .goal-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .ant-checkbox-inner, .ant-checkbox-input {
    transform: scale(1.5) translateX(-10%);
  }

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;

    .ant-checkbox-inner, .ant-checkbox-input {
      transform: scale(1.7) translateX(-25%);
    }
  }
  
  @media (min-width: 1300px) {
    width: 45%;
    margin: auto;
  }
`

export const GoalOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  border-radius: 10px;
  ${props => props.selected ? 'border: 1px solid #73BCAE' : 'border: 1px solid #d0d5dd'};
  box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
  cursor: pointer;
  transition: all 200ms ease-in-out;

  .option-content {
    display: flex;
    align-items: center;
    gap: 20px;

    .name {
      font-weight: bold;
    }
    
    .icon img {
      width: 30px;
    }
    
    .subtitle {
      font-size: 14px;
    }
  }
  
  @media (min-width: 1000px) {
    padding: 25px;

    .option-content {
      .subtitle {
        font-size: 16px;
      }
    }
  }
`
