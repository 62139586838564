import styled from "styled-components";

export const Container = styled.div`
  max-width: 154.3rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 15px;
  @media (max-width: 600px) {
    padding-inline: 30px;
  }
  .scroll-icon {
    margin-top: -5rem;
    display: block;
    margin-inline: auto;
  }
`;

export const ContainerNavbar = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  max-width: 161.7rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 15px;
`;

export const GeneralWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  &.NutritionCoachWrap {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

export const GeneralLeft = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  &.imageResponsive {
    img {
      max-width: 100%;
    }
  }
`;

export const GeneralRight = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
  }
`;

export const PlanBoxIcons = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`;

export const PlanBoxIconBox = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;

  img {
    width: 4rem;
    height: 4rem;
  }
`;

export const PlanBoxInformation = styled.span`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  width: calc(100% - 40px);
  padding-left: 20px;

  &.ProcedureTop {
    /* font-size: 1.25rem !important;
    padding-top: 10px; */
  }
  &.StandatProgramText {
    /* font-size: 1.25rem !important;
    font-weight: 500 !important; */
  }
`;

export const ScrollDownImagewrap = styled.div`
  position: relative;
  /* z-index: 1; */
  &:hover {
    /* transform: translateY(-10px);
    transition: all 0.3s ease-in-out; */
  }
`;

export const ScrollDownImage = styled.img`
  position: absolute;
  left: 50%;
  top: -80px;
  transform: translate(-50%, -100%);
  &.position-change {
    position: absolute;
    left: 50% !important;
    transform: translate(-50%, 100%) !important;
    @media screen and (max-width: 600px) {
      position: absolute;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, 38%) !important;
    }
  }

  @media screen and (max-width: 600px) {
    left: 50%;
    top: -60px;
    transform: translate(-50%, -55%);
    width: 25%;
    /* display: none; */
  }
`;
