import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./TestedKnowledge.style";
import StartTogetherImage from "assets/images/Foodiary-rocket-coach.jpg";

const TestedKnowledge = ({ onLeadFormModal }) => {
  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.TextContent>
        <Chip text="Coaching PLUS" margin="0 0 20px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Geprüftes Wissen für deinen langfristigen Erfolg.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Mein digitales Coaching ist ideal, wenn du die Grundlagen einer
          gesunden Ernährung mit digitalen Lerninhalten erlernen möchtest. Nach
          dem Erstgespräch und der Erstellung deines Ernährungsplans erhältst du
          3 Monate Zugriff auf die Lernacademy mit einem Präventionskurs,
          geprüften Wissensmodulen und täglichen Gruppencalls. Der Kurs wird von
          deiner Krankenkasse bezuschusst – bis zu 100 % Kostenrückerstattung
          möglich.
        </C.Description>
        <C.Action>
          <div>
            <button className="btn-dark" onClick={() => onLeadFormModal()}>
              Erstgespräch vereinbaren
            </button>
          </div>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <img src={StartTogetherImage} alt="together" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default TestedKnowledge;
