import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;

  .body-details-boxes {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 40%;
    margin: auto;
  }
`

export const BodyDetailsBox = styled.div`
  height: 100px;
  border-radius: 10px;
  padding: 0px 20px;
  box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .input-container {
    display: flex;
    gap: 5px;

    > * {
      padding: 5px 10px;
    }
  }

  .box-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .box-title {
      font-weight: 500;
    }
  }
  
  @media (min-width: 1000px) {
    .box-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`

export const InputControl = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
  border: 1px solid #d0d5dd;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: .8;
  }
`

export const BmiBox = styled.div`
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
  border: 2px solid rgba(46,207,150,.8);
  font-size: 14px;
  background: #F1FFFA;

  > * {
    margin-bottom: 5px;
  }

  .title {
    display: flex;
    align-items: flex-start;
    transform: translateX(0px);
    gap: 10px;

    span {
      font-weight: bold;
    }
  }
  
  .content {
    color: #818d88;
  }
  
  @media (min-width: 1000px) {
    padding: 20px 40px;

    .title {
      transform: translateX(-25px);
    }
  }
`
