import styled from "styled-components";

export const MealPlanContainer = styled.div`
  padding: ${(props) => (props?.bgColor ? "0px 20px" : "60px 20px")};
  background: ${(props) =>
    props.bgColor ??
    " transparent linear-gradient(90deg, #e5f5f2 0%, #edfbf6 100%)"};

  @media (max-width: 600px) {
    padding: 7rem 30px !important;
  }
  > div {
    max-width: 151.7rem;
    margin: 0px auto;
    -webkit-box-align: center;
    align-items: center;
  }
  .left-title {
    letter-spacing: 0.4px;
    color: #142c1f;
    font-size: 2.5rem;
    font-family: "RubikMedium";
    margin-bottom: 2rem;
    text-align: center;
  }

  @media (max-width: 991.99px) {
  }
`;

export const Title = styled.h3`
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  /* font-size: 2.5rem; */

  @media (max-width: 991.99px) {
    margin-bottom: 20px;
  }
`;

export const RestaurauntsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;

  @media (max-width: 1300px) {
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px 0;
    scroll-snap-type: x mandatory;
    touch-action: pan-x;
    margin-left: -30px;
    margin-right: -30px;
    padding-bottom: 4rem;
    & > *:nth-child(1) {
      margin-left: 30px;
    }
    & > *:last-child {
      margin-right: 30px;
    }

    .restaurant {
      flex: 0 0 auto;
    }

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar on mobile */
    }
  }
  .restaurant {
    flex: 0 0 auto; /* Prevent items from shrinking */
    height: 22rem;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0px 20px 40px #02332a1a;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 200px;
      width: 100%;
    }

    @media (max-width: 600px) {
      height: 12rem;
      width: 18rem;
      box-shadow: ${(props) =>
        props?.bgColor ? "0px 20px 40px #02332a1a;" : "none"};

      img {
        max-width: 120px;
      }
    }
  }
`;

export const VisualContent = styled.div`
  height: 600px;
  img {
    width: auto;
    height: 100%;
    display: block;
    margin-inline: auto;
  }
  @media (max-width: 991.99px) {
    margin-top: 70px;
  }

  @media (max-width: 600px) {
    height: 400px;
    display: none;
  }
`;

export const VisualContentInside = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
    margin-inline: auto;
    object-position: center center;
    max-width: 30rem;
    /* margin-left: 10%; */
    /* scale: 1.3; */
  }
  @media (max-width: 991.99px) {
    margin-top: 70px;
  }

  @media (min-width: 600.99px) {
    display: none;
  }
`;

export const CardBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const CardBox = styled.div`
  padding: 30px;

  &.active,
  &:hover {
    background: #ffffff;
    box-shadow: 0px 50px 99px #02332a1a;
    border-radius: 30px;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
`;

export const CardBoxIcon = styled.div`
  width: 8rem;
  min-width: 8rem;
  height: 8rem;
  background: #e5f5f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 6rem;
    height: 3rem;
  }
`;

export const CardBoxRight = styled.div`
  margin-left: 20px;
  padding: 15px 0 0;
`;

export const PlanTitle = styled.h4`
  color: #142c1f;
  /* font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 19px; */
`;

export const PlanText = styled.p`
  color: #7a8780;
  /* font-size: 1rem;
  font-weight: 400; */
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 600px) {
    display: block;
    text-align: left;
  }
`;
export const HorizonalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px 60px 0px;
  gap: 15px;
  .divider {
    height: 20px;
    border-right: 3px solid #e5e5e5;
  }
  p {
    font-size: 18px;
  }
  @media (max-width: 600px) {
    margin: 20px 0px 20px 0px;
    gap: 5px;
    justify-content: flex-start;
    p {
      font-size: 12px;
    }
  }
`;
export const CircleImgeWrapper = styled.div`
  position: relative;
  float: right;
  @media (max-width: 1200px) {
    display: none;
  }
`;
export const CircleImage = styled.img`
  position: absolute;
  right: -20px;
  top: 0px;
  height: 230px;
  transform: translateY(-25%);
`;
