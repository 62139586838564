import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import { getPreviousOnboardingPage } from '../../../routes/onboardingRoutes'
import * as Styles from './DoneStyles'
import iphoneBanner from 'assets/images/onboarding/iphone-banner.svg'
import androidBanner from 'assets/images/onboarding/android-banner.svg'

function Done ({ currentPath }) {
  const history = useHistory()
  const handleNextPage = () => history.push('/')

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Ernährungsform</div>
            <div className="sub-headline">Starte mit unserer Unterstützung</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Zur Startseite</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <Styles.DoneContainer>
            <div className="title">Du kannst direkt starten.</div>
            <div className="description">Lade dir direkt unsere App in deinem App Store herunter.</div>
            <div className="description">Melde dich mit deiner Zugangsdaten an und starte deine Ernährungsumstellung.</div>
            <div className="actions">
              <div className="action-title">Hier findest du die App:</div>
              <div className="banners">
                <a target="_blank" href='https://apps.apple.com/de/app/foodiary-rezepte-di%C3%A4t/id1502395764'>
                  <div>
                    <img src={iphoneBanner} alt="" />
                  </div>
                </a>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.foodiary.nutrition'>
                  <div>
                    <img src={androidBanner} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </Styles.DoneContainer>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

export default Done
