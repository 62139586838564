import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import * as F from "./coachingWithMe.style";
import Coach from "assets/images/placeholder.jpg";
import Chip from "components/General/formComponents/chip/Chip";

const defaultDescription =
  "Von Beginn an wirst du von mir durch die Ernährungsumstellung geführt. So starten wir in einem persönlichen Gespräch damit, deinen eigenen Ernährungsplan zu erstellen und die Ernährungsplaner-App einzurichten. Im Anschluss unterstütze ich dich während deiner Ernährungsumstellung und stehe dauerhaft als Ansprechpartner an deiner Seite.";

const CoachingWithMe = ({ openSurveyModal }) => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_lp_picture_1
    ? getS3ImageUrl(coach?.cw_lp_picture_1)
    : Coach;

  const coachDescription = coach?.cw_lp_textfield_1 || defaultDescription;
  const prename = coach?.prename || "Coach name";

  return (
    <F.CoachOverviewContainer>
      <div className="overview-content">
        <Chip text="Ernährungscoaching" bg="white" color="rgb(28, 157, 135)" />
        <F.Title className="ct-headline color-dark font-semibold">
          Gesunde Ernährung -
          <br />
          so einfach wie noch nie!
        </F.Title>
        <div className="overview-description-small">
          <p>
            Hi, ich bin {prename}. Als Ernährungsscoach unterstütze ich dich
            gemeinsam mit Foodiary bei der Planung und Umsetzung deiner
            Ernährungsumstellung.
          </p>
        </div>
        <div className="overview-description-main color-paragraph">
          <p>{coachDescription}</p>
        </div>
        <div className="overview-cta">
          <button onClick={openSurveyModal} className="btn-dark">
            Erstgespräch vereinbaren
          </button>
        </div>
      </div>
      <div className="overview-image">
        <div className="overview-image-wrapper">
          <img src={imgUrl} alt="" />
        </div>
      </div>
    </F.CoachOverviewContainer>
  );
};

export default CoachingWithMe;
