import React from 'react'
import * as Styles from './OnboardingWrapper.styles'
import { Steps } from 'antd'
import Logo from './Logo'
import progressSteps from './progressSteps'

function OnboardingWrapper (props) {


  return (
    <Styles.OnboardingWrapperContainer>
      <MobileProgressNav progressStep={props.progressStep} />
      <DesktopProgressNav progressStep={props.progressStep} />
      <MainContent>
        {React.isValidElement(props.children)
          ? React.cloneElement(props.children, { ...props })
          : props.children
        }
      </MainContent>
    </Styles.OnboardingWrapperContainer>
  )
}

function MobileProgressNav ({ progressStep }) {
  return (
    <div className="wrapper-mobile-progress">
      <div className="logo">
        <Logo />
      </div>
      <div className='mobile-steps-header'>
        <span>Schritte</span>
      </div>
      <div className='mobile-steps'>
        <Steps size='small' type="inline" current={progressStep} items={progressSteps} />
      </div>
    </div>
  )
}

function DesktopProgressNav ({ progressStep }) {
  return (
    <div className="wrapper-desktop-progress">
      <div className="logo">
        <Logo />
      </div>
      <span className="desktop-progress-headline">Herzlich Willkommen zum Erstgespräch. 👋</span>
      <div className='desktop-steps-header'>Schritte</div>
      <div className='desktop-steps'>
        <Steps direction="vertical" type="vertical" current={progressStep} items={progressSteps} />
      </div>
    </div>
  )
}

function MainContent (props) {
  return (
    <div className="wrapper-content">
      {React.isValidElement(props.children)
        ? React.cloneElement(props.children, { ...props })
        : props.children
      }
    </div>
  )
}

export default OnboardingWrapper
