import styled from "styled-components";

export const Card = styled.div`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  min-width: ${(props) => props.minWidth || "auto"};
  background: ${(props) => (props.minWidth ? "#ffffff" : "#f0f4f3")};
  border-radius: ${(props) => (props.minWidth ? "10px" : "30px")};
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  box-shadow: ${(props) =>
    props.minWidth ? "0 5px 20px 0px rgba(0, 0, 0, 0.1)" : "none"};

  @media (max-width: 992px) {
    min-width: ${(props) => props.minMobileWidth || "auto"};
  }
`;

export const Title = styled.h3`
  color: #1c9d87;
`;

export const Description = styled.p`
  color: #44554c;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 60px;
    margin-top: -40px;
  }
`;
