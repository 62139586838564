import styled from "styled-components";

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})``;

export const CardsWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    position: relative !important;
    top: 14px !important;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 50% !important;
  }
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  touch-action: auto;
  gap: 30px;
  margin-top: 50px;
  padding: 20px 30px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
    padding: 20px 10px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionTitle = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;

  b {
    font-weight: 500;
  }

  @media (max-width: 992px) {
    text-align: left;

    br {
      display: none;
    }
  }
`;

export const SectionTitleMobile = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-size: 2.3rem;
  font-weight: 300;

  b {
    font-weight: 500;
  }

  @media (min-width: 600.99px) {
    display: none;
  }
`;
