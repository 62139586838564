import { useEffect, useState } from 'react'
import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import {
  getPreviousOnboardingPage,
  getNextOnboardingPage
} from '../../../routes/onboardingRoutes'
import * as Styles from './BodyDetailStyles'
import { InputNumber, DatePicker } from 'antd'
import InfoIcon from 'assets/images/info-icon.svg'
import dayjs from 'dayjs'
import {
  getOnboardingStorage,
  setOnboardingStorage,
  BODY_DETAILS_STEP
} from '../storage'

const MIN_HEIGHT = 120
const MIN_WEIGHT = 40

function BodyDetails ({ currentPath }) {
  console.log('currentPath : ', currentPath)

  const storage = getOnboardingStorage(BODY_DETAILS_STEP)
  const history = useHistory()
  const [ height, setHeight ] = useState(storage.height || MIN_HEIGHT)
  const [ weight, setWeight ] = useState(storage.weight || MIN_WEIGHT)
  const [ dateOfBirth, setDateOfBirth ] = useState(storage.dateOfBirth || null)
  const [ calculatedBmi, setCalculatedBmi ] = useState(null)
  const [ validationError, setValidationError ] = useState(null)

  useEffect(() => {
    setValidationError(null)
  }, [ dateOfBirth ])

  useEffect(() => {
    const heightInMeters = height / 100
    setCalculatedBmi((weight / (heightInMeters ** 2)).toFixed(2))
  }, [ height, weight ])

  const onHeightChange = value => {
    if (value < MIN_HEIGHT) return
    return setHeight(value)
  }

  const onWeightChange = value => {
    if (value < MIN_WEIGHT) return
    return setWeight(value)
  }

  const onDateChange = (date, dateString) => {
    console.log('date, dateString : ', date, dateString)
    setDateOfBirth(dateString)
  }

  const onNextPage = () => {
    console.log('next clicked !')
    if (!dateOfBirth) {
      return setValidationError('Bitte geben Sie Ihr Geburtsdatum ein')
    }

    setOnboardingStorage(BODY_DETAILS_STEP, { height, weight, dateOfBirth })
    history.push(getNextOnboardingPage(currentPath))
  }

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">Wir erstellen deinen Plan.</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={onNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className='section-titles-wrapper'>
            <div className="primary-title">Deine personenbezogenen Daten.</div>
            <div className="accent-subtitle">Das Verhältnis zwischen Alter, Gewicht und Größe ist wichtig für deinen Kalorienbedarf.</div>
            <div className="small-subtitle">Nenne uns deine Daten</div>
            { validationError ? <div className="error-message">{validationError}</div> : '' }
          </div>
          <div className="body-details-boxes">
            <Styles.BodyDetailsBox>
              <div className="box-content">
                <div className='box-title'>Dein Geburtsdatum</div>
                <div className='input-container'>
                  <DatePicker
                    onChange={onDateChange}
                    {...(dateOfBirth ? { value: dayjs(dateOfBirth, 'DD.MM.YYYY') } : {})}
                    placeholder='Geburtsdatum'
                    format={'DD.MM.YYYY'}
                    style={{ height: '40px' }}
                  />
                </div>
              </div>
            </Styles.BodyDetailsBox>
            <Styles.BodyDetailsBox>
              <div className="box-content">
                <div className='box-title'>Deine Körpergröße (cm)</div>
                <div className='input-container'>
                  <Styles.InputControl onClick={() => onHeightChange(height - 1)}>-</Styles.InputControl>
                  <InputNumber prefix="cm" onChange={val => onHeightChange(val)} value={height} min={MIN_HEIGHT} defaultValue={MIN_HEIGHT} />
                  <Styles.InputControl onClick={() => onHeightChange(height + 1)}>+</Styles.InputControl>
                </div>
              </div>
            </Styles.BodyDetailsBox>
            <Styles.BodyDetailsBox>
              <div className="box-content">
                <div className='box-title'>Dein Körpergewicht (kg)</div>
                <div className='input-container'>
                  <Styles.InputControl onClick={() => onWeightChange(weight - 1)}>-</Styles.InputControl>
                  <InputNumber prefix="kg" onChange={val => onWeightChange(val)} value={weight} min={MIN_WEIGHT} defaultValue={MIN_WEIGHT} />
                  <Styles.InputControl onClick={() => onWeightChange(weight + 1)}>+</Styles.InputControl>
                </div>
              </div>
            </Styles.BodyDetailsBox>
            {calculatedBmi ? (
              <Styles.BmiBox>
                <div className="title">
                  <span><img src={InfoIcon} alt="" /></span>
                  <span>Wir haben deinen BMI berechnet</span>
                </div>
                <div className="content">Dein aktueller BMI beträgt: {calculatedBmi}. Beachte, dass der BMI nur eine grobe Einschätzung ist und als Vergleichsindikator dient.</div>
              </Styles.BmiBox>
            ) : ''}
          </div>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

export default BodyDetails
