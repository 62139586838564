import styled from "styled-components";

export const Wrap = styled.div`
  /* @media (min-width: 1000px) {
    margin-top: -20rem;
  } */
`;
export const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 600px) {
    margin-top: 7rem;
    display: block;
    gap: 20;
  }
`;
export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  padding: 10px 0px;

  @media (max-width: 1000px) {
    gap: 20px;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  .card-content {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 100%;
  }

  @media (max-width: 1000px) {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  @media (max-width: 1000px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 600px) {
    /* padding-bottom: 5rem; */
    gap: 40px;
    box-shadow: none;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 20px #02332a1a;
  border-radius: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  flex: 0 0 auto;
  border: 2px solid #e3e5e9;

  .image-wrapper {
    padding: 20px 0px;
    background: white;
    border-radius: 20px 20px 0 0;
    border: 2px solid #e3e5e9;
    margin: 20px 20px 0 20px;
    border-bottom: none;
    @media (max-width: 1000px) {
      margin: 0;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10px;

    img {
      width: 100%;
    }

    &:hover {
      transform: translateY(0px);
    }
  }

  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;
export const ContentWrapper = styled.div`
  background: #e6f4f4;
  padding: 20px;
  border-radius: 0 0 20px 20px;
  text-align: left !important;
  @media (max-width: 1000px) {
    height: 200px;
  }
  @media (max-width: 600px) {
    height: 180px;
  }
`;
export const CardTitle = styled.h6`
  color: #142c1f;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 16px;
    text-align: center;
  }
`;
export const Title = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 20px;

  b {
    font-weight: 500;
  }

  @media (max-width: 991.99px) {
    margin-bottom: 20px;
    text-align: left;
    font-size: 3.2rem;
  }
`;
export const CardDescription = styled.p`
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: left;
  max-width: 100%;
  margin: 10px 0px;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const PlusIconWrapper = styled.div`
  position: absolute;
  right: -45px; /* Half of the gap */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const PlusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2ecf96;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 24px;
  box-shadow: 0px 4px 12px rgba(46, 207, 150, 0.2);

  &::after {
    content: "+";
  }
`;

export const CardContainer = styled.div`
  position: relative;

  &:last-child {
    .plus-icon-wrapper {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const CircleImage = styled.div`
  /* height: 210px; */
  width: 100%;
  img {
    width: 100% !important;
    height: 210px !important;
  }
`;
