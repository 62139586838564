import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  margin-top: 180px;
  z-index: 1;
  @media (max-width: 992px) {
    flex-direction: column;
    margin: 5rem 0;
    gap: 16;
    margin-top: 150px;
  }
`;
export const BreakLine = styled.div`
  width: 100%;
  height: 1px;
  background: #e2e4e9;
  margin: 16px 0 24px 0;
`;

export const InfoCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 30px 0px;
  flex: 1;
  border: 1px solid #e2e4e9;
  height: 570px;
  @media (max-width: 992px) {
    height: 100%;
    padding: 20px 0px;
  }
`;
export const ResponsiveStarRatings = styled.div`
  .star-ratings {
    display: inline-block;
    @media (max-width: 992px) {
      margin-left: -10px;
      margin-right: 10px;
    }
  }

  .star-container {
    height: 20px !important;
    width: 20px !important;

    @media (max-width: 992px) {
      height: 14px !important;
      width: 14px !important;
      margin-top: -10px;
    }

    @media (max-width: 768px) {
      height: 12px !important;
      width: 12px !important;
    }
  }
`;

export const OverviewWrapper = styled.div`
  min-height: calc(30vh - 80px);
  padding: 40px 10%;
  display: flex;
  @media (max-width: 992px) {
    padding: 20px 5%;
  }
  .backColor {
    content: "";
    background: transparent linear-gradient(90deg, #e5f5f2 0%, #edfbf6 100%);
    height: 70vh;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
  }
`;

export const SocialButtons = styled.div`
  position: absolute;
  bottom: 30px;
  left: 16px;
  display: flex;
  gap: 8px;
  background: white;
  padding: 8px;
  border-radius: 24px;
  left: 50%;
  transform: translateX(-50%);
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  color: #666;

  &:hover {
    color: #333;
  }
`;

export const InfoSection = styled.div`
  flex: 1;
`;

export const Header = styled.div`
  margin-bottom: 24px;
  padding: 0 25px;
`;

export const Name = styled.h1`
  font-size: 24px;
  margin: 0 0 8px 0;
`;

export const Age = styled.span`
  color: #2ecf96;
  margin-right: 16px;
`;

export const Reviews = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
  @media (max-width: 768px) {
    gap: 2px;
  }
  .img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;
export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 0 15px;
  }
`;

export const GridItem = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    display: block;
    flex-direction: row;
    justify-content: center;
    gap: 10%;
  }

  &:nth-child(1)::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: #2ecf96;
    right: -20px;
  }

  &:nth-child(2)::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: #2ecf96;
    right: 12.5px;
  }
  &:nth-child(3)::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: #2ecf96;
    right: -2.5px;
  }
  @media (max-width: 768px) {
    &:nth-child(1)::after,
    &:nth-child(2)::after,
    &:nth-child(3)::after {
      display: none;
    }
  }
`;
export const GridTitle = styled.p`
  color: #666;
  margin: 0 0 4px 0;
`;
export const GridText = styled.h3`
  margin: 0;
`;
export const Qualifications = styled.div`
  h3 {
    font-size: 16px;
    margin: 35px 0 20px 0;
    padding: 0 25px;
  }
`;

export const QualificationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  color: #666;
  font-size: 14px;
  padding-left: 25px;
  .border {
    color: #2ecf96;
    font-weight: bold;
  }
`;

export const Button = styled.button`
  width: 32%;
  padding: 12px;
  background: white;
  color: black;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease;
  display: block;
  margin: 80px 30px 0 auto;

  @media (max-width: 768px) {
    width: 80%;
    margin: 35px auto 0;
  }

  &:hover {
    background: #333;
    color: white;
  }
`;
export const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 10%;
  gap: 10%;
`;
export const VideoCard = styled.div`
  gap: 20px;
  h1 {
    font-size: 1vw;
    margin: 0 0 8px 0;
  }
  p {
    font-size: 1vw;
    margin: 0;
    word-break: break-word;
  }
`;
export const VideoSectionWrapper = styled.div`
  padding: 40px 10% 100px 10%;
  background: #f2f3f3;

  @media (max-width: 992px) {
    padding: 40px 5%;
  }
`;

export const SectionTag = styled.span`
  background: #e5f5f2;
  color: #2ecf96;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 24px;
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 15%;
  align-items: center;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 32px;
  }
`;

export const ContentSection = styled.div`
  h1 {
    font-size: 36px;
    color: #ff4444;
    font-weight: 600;
    margin-bottom: 24px;
    line-height: 1.2;

    @media (max-width: 992px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 32px;
    color: red;
  }
`;

export const ConsultButton = styled.button`
  background: #445c56;
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #3a4f4a;
    transform: translateY(-2px);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
`;

export const CoachImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const TestimonialsSection = styled.div`
  padding: 60px 10%;
  background: #f8f9fa;

  @media (max-width: 992px) {
    padding: 40px 5%;
  }
`;

export const TestimonialHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #2ecf96;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const TestimonialCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 24px;
  position: relative;
`;

export const TestimonialAvatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  right: 24px;
  top: 24px;
`;

export const TestimonialName = styled.h3`
  font-size: 18px;
  margin: 0 0 4px 0;
`;

export const TestimonialRole = styled.p`
  color: #666;
  font-size: 14px;
  margin: 0 0 16px 0;
`;

export const TestimonialText = styled.p`
  color: #333;
  font-size: 14px;
  line-height: 1.6;
  margin-right: 80px;
`;

export const ConsultationSection = styled.div`
  background: #f8f9fa;
  padding: 80px 10%;
  text-align: center;

  @media (max-width: 992px) {
    padding: 60px 5%;
  }
`;

export const ConsultationTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 16px;
`;

export const ConsultationText = styled.p`
  color: #666;
  font-size: 16px;
  margin-bottom: 32px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;
export const BannerSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 10%;
  padding: 200px 10% 60px 10%;
  background: white;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    margin: 0px 5%;
    padding: 50px 0px 140px 0px;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BannerHeading = styled.h2`
  color: #ff4444;
  margin: 0;
`;

export const BannerText = styled.p`
  font-size: 16px;
  color: red;
  margin: 0;
  margin-top: 10px;
`;

export const ConsultationButton = styled.button`
  background: #445c56;
  color: white;
  border: none;
  padding: 10px 22px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  transition: all 0.2s ease;
  margin-top: 20px;

  &:hover {
    background: #3a4f4a;
    transform: translateY(-2px);
  }
`;
export const ImagesSection = styled.div`
  background: #eeeeee;
  padding: 60px 10% 0px 10%;
`;
export const ImageSectionTitle = styled.h4`
  color: #142c1f;
  text-align: center;
  font-family: RubikRegular;
`;
export const ImageSectionText = styled.p`
  color: #142c1f;
  text-align: center;
  margin-top: 5px;
`;
export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 30px;
  justify-content: center;
  background: #eeeeee;
  padding-bottom: 40px;
  gap: 20px;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
`;

export const ImageSectionParent = styled.div`
  padding: 10px;
  background: white;
  width: 270px;
  height: 320px;
  border-radius: 20px;
  &:nth-child(2),
  &:nth-child(4) {
    margin-top: 90px;
  }

  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    margin: 0 auto !important;
    &:nth-child(2),
    &:nth-child(4) {
      margin-top: 0;
    }
  }
`;

export const SectionImage = styled.img`
  object-fit: cover;
  border-radius: 8px;
  width: 250px;
  height: 300px;
  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
`;
