
const NutritionTypes = {
    GET_USER_DATA_REQUEST: 'GET_USER_DATA_REQUEST',
    GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
    GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',

    GET_NUTRITION_TYPE_REQUEST: 'GET_NUTRITION_TYPE_REQUEST',
    GET_NUTRITION_TYPE_SUCCESS: 'GET_NUTRITION_TYPE_SUCCESS',
    GET_NUTRITION_TYPE_FAILURE: 'GET_NUTRITION_TYPE_FAILURE',

    GET_ALL_NUTRITION_TYPES_REQUEST: 'GET_ALL_NUTRITION_TYPES_REQUEST',
    GET_ALL_NUTRITION_TYPES_SUCCESS: 'GET_ALL_NUTRITION_TYPES_SUCCESS',
    GET_ALL_NUTRITION_TYPES_FAILURE: 'GET_ALL_NUTRITION_TYPES_FAILURE',

    UPDATE_NUTRITION_TYPE_REQUEST: 'UPDATE_NUTRITION_TYPE_REQUEST',
    UPDATE_NUTRITION_TYPE_SUCCESS: 'UPDATE_NUTRITION_TYPE_SUCCESS',
    UPDATE_NUTRITION_TYPE_FAILURE: 'UPDATE_NUTRITION_TYPE_FAILURE',

    UPDATE_NUTRITION_CALORIES_REQUEST: 'UPDATE_NUTRITION_CALORIES_REQUEST',
    UPDATE_NUTRITION_CALORIES_SUCCESS: 'UPDATE_NUTRITION_CALORIES_SUCCESS',
    UPDATE_NUTRITION_CALORIES_FAILURE: 'UPDATE_NUTRITION_CALORIES_FAILURE',

    GET_NUTRITION_ADVANTAGES_REQUEST: 'GET_NUTRITION_ADVANTAGES_REQUEST',
    GET_NUTRITION_ADVANTAGES_SUCCESS: 'GET_NUTRITION_ADVANTAGES_SUCCESS',
    GET_NUTRITION_ADVANTAGES_FAILURE: 'GET_NUTRITION_ADVANTAGES_FAILURE',

    GET_NUTRITION_RECIPES_REQUEST: 'GET_NUTRITION_RECIPES_REQUEST',
    GET_NUTRITION_RECIPES_SUCCESS: 'GET_NUTRITION_RECIPES_SUCCESS',
    GET_NUTRITION_RECIPES_FAILURE: 'GET_NUTRITION_RECIPES_FAILURE',

    GET_WEEK_WISE_CALORIES_REQUEST: 'GET_WEEK_WISE_CALORIES_REQUEST',
    GET_WEEK_WISE_CALORIES_SUCCESS: 'GET_WEEK_WISE_CALORIES_SUCCESS',
    GET_WEEK_WISE_CALORIES_FAILURE: 'GET_WEEK_WISE_CALORIES_FAILURE',

    GET_MACRO_NUTRIENTS_DONUT_REQUEST: 'GET_MACRO_NUTRIENTS_DONUT_REQUEST',
    GET_MACRO_NUTRIENTS_DONUT_SUCCESS: 'GET_MACRO_NUTRIENTS_DONUT_SUCCESS',
    GET_MACRO_NUTRIENTS_DONUT_FAILURE: 'GET_MACRO_NUTRIENTS_DONUT_FAILURE',

    GET_ALL_REFUNDS_REQUEST: "GET_ALL_REFUNDS_REQUEST",
    GET_ALL_REFUNDS_SUCCESS: "GET_ALL_REFUNDS_SUCCESS",
    GET_ALL_REFUNDS_FAILURE: "GET_ALL_REFUNDS_FAILURE",
  
    GET_ALL_REFUNDS_LOADING_START: "GET_ALL_REFUNDS_LOADING_START",
    GET_ALL_REFUNDS_LOADING_STOP: "GET_ALL_REFUNDS_LOADING_STOP",
    GET_MACRO_NUTRIENTS_PIE_CHART_DATA_REQUEST: "GET_MACRO_NUTRIENTS_PIE_CHART_DATA_REQUEST",
}

export default NutritionTypes;
