export const LOGIN = "/login";
export const ROOT = "/";
export const MY_COACHING = "/mein-coaching";
export const ABOUT_ME = "/ueber-mich";
export const INITIAL_CONSULTATION = "/erstgespraech";
export const COACHING_PRO = "/coaching-pro";
export const SUCCESS = "/successful-booking-pro-r&narim0ken8";
export const COACHING_PRO_APP = "/app";
export const COACHING_PRO_COACHING = "/coaching";
export const BOOK_APPOINTMENT = "/kennenlernen";

export const TRAINER_SIGNUP = "/trainerSignup";
export const DASHBOARD = "/dashboard";
export const WHAT_AWAITS_YOU = "/whatAwaitsYou";
export const REGISTRATION = "/registration/:id";
export const CHANGE_PASSWORD = "/nutrition/changePassword";
export const NUTRITION_CREATION = "/nutrition/creation";
export const NUTRITION_FORM = "/nutrition/nutritionForm";
export const NUTRITION_FORM_ADVANTAGES = "/nutrition/formAdvantages";
export const NUTRITION_RECIPES = "/nutrition/recipes";
export const NUTRITION_CALORIES = "/nutrition/calories";
export const NUTRITION_MACRO_NUTRIENTS = "/nutrition/macroNutrients";
export const NUTRITION_DONE = "/nutrition/done";
export const HEALTH_SCORE_INVESTIGATION = "/healthscore/investigation";
export const HEALTH_SCORE_IMPROVEMENT = "/healthscore/improvement";
export const OVERVIEW = "/overview";
export const OVERVIEW_BASIC = "/overview/basic";
export const ORDER_DETAILS = "/orderDetails";
export const FOODIARY_FAMILY = "/foodiary/family";
export const PREMIUM_COACHING = "/premiumCoaching";
export const PREMIUM_COACHING_SUCCESS = "/premiumCoaching/success";
export const HEALTH_SCORE_TIPS = "/healthscore/tips";
export const HEALTH_SCORE_PRIORITY = "/healthscore/priority";
export const HEALTH_SCORE_EVALUATION = "/healthscore/evaluation";
export const HEALTH_SCORE_DAILY_BUDGET = "/healthscore/dailyBudget";
export const SUPPLEMENTS = "/Supplements";
export const SUBSCRIPTIONS = "/subscriptions";
export const SUBSCRIPTIONS_DETAILS = "/subscription/details";
export const START = "/start";
export const CLIENT_SUCCESSFUL = "/client-successful";
export const SELECT_COACH = "/select-coach";
export const PAYMENT = "/payment";
export const ONLINE_KURS = "/online-kurs";
export const ONLINE_KURS_STARTEN = "/online-kurs/starten";
export const LINKTREE = "/linktree";
export const DIGITAL_TOOLS = "/digitale-tools";
export const COACHING_PLUS = "/coaching-plus";
