import { useSelector } from "react-redux";
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather";
import Slider from "react-slick";
import { getS3ImageUrl } from "configs/utils";
import Placeholder from "assets/images/placeholder.jpg";
import * as C from "components/TestimonialSlider/testimonialSlider.style";
import React from "react";
import styled from "styled-components";

const NavigationIcon = styled.div`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: ${(props) => (props.disabled ? "#e2e4e9" : "currentColor")};
`;

function NextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <ArrowRightCircle
      className={className}
      style={{ ...style }}
      onClick={onClick}
      strokeWidth={1}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick, disabled } = props;
  return (
    <NavigationIcon disabled={disabled}>
      <ArrowLeftCircle
        className={className}
        style={{ ...style }}
        onClick={disabled ? () => {} : onClick}
        strokeWidth={1}
      />
    </NavigationIcon>
  );
}
const Data = [
  {
    id: 1,
    prename: "Max",
    lastname: "Mustermann",
    text: "Die App ist einfach nur genial 🥳 Das ist das erste Tool, das ich kenne, das wirklich auch bei der Ernährung hilft - und das super leicht. Die Rezepte sind lecker und abwechslungsreich und viele Funktionen helfen mir im Alltag sehr (z.B die Einkaufsliste). Ich kann sie jedem empfehlen, der seinem Körper was gutes tun will 😊",
    picture: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    prename: "Max",
    lastname: "Mustermann",
    text: "Die App ist einfach nur genial 🥳 Das ist das erste Tool, das ich kenne, das wirklich auch bei der Ernährung hilft - und das super leicht. Die Rezepte sind lecker und abwechslungsreich und viele Funktionen helfen mir im Alltag sehr (z.B die Einkaufsliste). Ich kann sie jedem empfehlen, der seinem Körper was gutes tun will 😊",
    picture: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    prename: "Max",
    lastname: "Mustermann",
    text: "Die App ist einfach nur genial 🥳 Das ist das erste Tool, das ich kenne, das wirklich auch bei der Ernährung hilft - und das super leicht. Die Rezepte sind lecker und abwechslungsreich und viele Funktionen helfen mir im Alltag sehr (z.B die Einkaufsliste). Ich kann sie jedem empfehlen, der seinem Körper was gutes tun will 😊",
    picture: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    prename: "Max",
    lastname: "Mustermann",
    text: "Die App ist einfach nur genial 🥳 Das ist das erste Tool, das ich kenne, das wirklich auch bei der Ernährung hilft - und das super leicht. Die Rezepte sind lecker und abwechslungsreich und viele Funktionen helfen mir im Alltag sehr (z.B die Einkaufsliste). Ich kann sie jedem empfehlen, der seinem Körper was gutes tun will 😊",
    picture: "https://via.placeholder.com/150",
  },
];
const TestimonialSlider = ({ list, baseUrl }) => {
  const sliderRef = React.useRef(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const settings = {
    className: "",
    slidesToShow: 2,
    speed: 500,
    nextArrow: <NextArrow className="" />,
    prevArrow: <PrevArrow disabled={currentSlide == 0} className="" />,
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
        Das sagen zufriedene Klienten
      </C.Title>
      <C.SliderCard className="user-slider">
        <Slider className="slick-slider" {...settings}>
          {Data?.map((item, i) => (
            <div className="slick-item" key={item.id}>
              <div className="sliderInner">
                <C.UserContent>
                  <h2 className="ct-text-block color-dark text-xl font-medium mb-2">{`${item?.prename} ${item?.lastname}`}</h2>
                  <small className="ct-text-block color-primary">
                    Zufriedener Klient
                  </small>
                </C.UserContent>
                <C.UserDescription className="ct-text-block text-base color-paragraph text-left mt-10">
                  {item?.text}
                </C.UserDescription>
                <C.UserProfile>
                  <img src={item.picture} alt="feedback user" />
                </C.UserProfile>
              </div>
            </div>
          ))}
        </Slider>
      </C.SliderCard>
    </>
  );
};

export default TestimonialSlider;
