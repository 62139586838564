import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./HollisticSupport.style";
import videoSource from "assets/videos/support-video.mp4";
import ReactPlayer from "react-player";

const HollisticSupport = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <div className="text-content">
        <Chip text="Gesamtpaket" margin="0 0 20px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Ganzheitlicher Support mit meinem All-In-One Angebot.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          In einem unverbindlichen Gespräch lernen wir uns persönlich kennen.
          Ich führe eine IST-Analyse durch und erstelle direkt deinen
          individuellen Ernährungsplan, den du kostenlos erhältst. Danach
          entscheidest du frei, ob du mit mir und dem Coaching PLUS oder
          Coaching PRO sowie meiner kontinuierlichen Unterstützung starten
          möchtest.
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>
            Erstgespräch vereinbaren
          </button>
        </C.Action>
      </div>
      <C.VisualContent>
        {/* <img src={HollisticSupportBanner} alt="" /> */}
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default HollisticSupport;
