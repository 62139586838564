import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 60%;
    margin: auto;
  }
`

export const MacroNutrientsContainer = styled.div`
  .title {
    font-weight: bold;
  }

  .description {
    color: #818D88;
  }

  .charts {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    .box-title {
      color: #142C1F;
      font-weight: 500;
      text-align: center;
    }
    
    .chart-box {
      border: 2px solid rgb(226, 228, 233);
      padding: 20px;
      border-radius: 20px;
      box-shadow: rgba(214, 214, 214, 0.16) 0px 13px 20px;
    }
    
    .chart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
    }

    .bullet {
      width: 20px;
      height: 20px;
      background: black;
      border-radius: 50%;
    }
    
    .protein { background: #C74343; }
    .carbs { background: #2ECF96; }
    .fats { background: #FFC403; }

    .details {
      display: flex;
      flex-direction: column;
      gap: 5px;

      > * {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        span {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  
  @media (min-width: 1000px) {
    .charts {
      grid-template-columns: 1fr 1fr;
    }
  }
`
