export const meal_categories = [
  { requestValue: 'vegetarian', value: 'Vegetarisch', label: 'Vegetarisch' },
  { requestValue: 'vegan', value: 'Vegan', label: 'Vegan' },
  { requestValue: '', value: 'Fleischhaltig', label: 'Fleischhaltig' },
]

export const allMeals = {
  "Vegetarisch": {
    label: 'Vegetarisch',
    value: 'Vegetarisch',
    meal1: [
      { "label": "Joghurt mit Früchten", "value": "Joghurt mit Früchten" },
      { "label": "Toast mit Ei und Avocado", "value": "Toast mit Ei und Avocado" },
      { "label": "Smoothiebowl", "value": "Smoothiebowl" },
      { "label": "Spiegeleier", "value": "Spiegeleier" },
      { "label": "Granola", "value": "Granola" }
    ],
    meal2: [
      { "label": "Salat mit Avocado und Eier", "value": "Salat mit Avocado und Eier" },
      { "label": "Nudelpfanne mit Gemüse", "value": "Nudelpfanne mit Gemüse" },
      { "label": "Gebackene Avocado mit Ei", "value": "Gebackene Avocado mit Ei" }
    ],
    meal3: [
      { "label": "Salatbowl mit Ei", "value": "Salatbowl mit Ei" },
      { "label": "Bratkartoffeln", "value": "Bratkartoffeln" },
      { "label": "Hummus mit Falafel", "value": "Hummus mit Falafel" }
    ],
    meal4: [
      { "label": "Nüsse", "value": "Nüsse" },
      { "label": "Banane", "value": "Banane" },
      { "label": "Maiswaffeln", "value": "Maiswaffeln" }
    ],
    meal5: [
      { "label": "Klassisches Rührei", "value": "Klassisches Rührei" },
      { "label": "Oatmeal mit Pfirsich und Himbeeren", "value": "Oatmeal mit Pfirsich und Himbeeren" },
      { "label": "Chiapudding mit Himbeeren", "value": "Chiapudding mit Himbeeren" }
    ]
  },
  "Vegan": {
    label: 'Vegan',
    value: 'Vegan',
    meal1: [
      { "label": "Joghurt mit Früchten", "value": "Joghurt mit Früchten" },
      { "label": "Toast mit Ei und Avocado", "value": "Toast mit Ei und Avocado" },
      { "label": "Smoothiebowl", "value": "Smoothiebowl" },
      { "label": "Spiegeleier", "value": "Spiegeleier" },
      { "label": "Granola", "value": "Granola" }
    ],
    meal2: [
      { "label": "Brokkoli Couscous Pfanne", "value": "Brokkoli Couscous Pfanne" },
      { "label": "Reis mit gebratenem Tofu", "value": "Reis mit gebratenem Tofu" },
      { "label": "Salat mit Ziegenkäse und Birnen", "value": "Salat mit Ziegenkäse und Birnen" }
    ],
    meal3: [
      { "label": "Brot mit Avocado", "value": "Brot mit Avocado" },
      { "label": "Falafel mit Couscous-Salat", "value": "Falafel mit Couscous-Salat" },
      { "label": "Hummus mit Falafel", "value": "Hummus mit Falafel" }
    ],
    meal4: [
      { "label": "Salat mit Nussmischung", "value": "Salat mit Nussmischung" },
      { "label": "Belugalinsensalat", "value": "Belugalinsensalat" },
      { "label": "Reis mit Mango", "value": "Reis mit Mango" }
    ],
    meal5: [
      { "label": "Bandnudeln in Käse-Sahne Sauce", "value": "Bandnudeln in Käse-Sahne Sauce" },
      { "label": "Gnocchi-Gemüse Pfanne", "value": "Gnocchi-Gemüse Pfanne" },
      { "label": "Salat mit Guacamole", "value": "Salat mit Guacamole" }
    ]
  },
  "Fleischhaltig": {
    label: 'Fleischhaltig',
    value: 'Fleischhaltig',
    meal1: [
      { "label": "Joghurt mit Früchten", "value": "Joghurt mit Früchten" },
      { "label": "Toast mit Ei und Avocado", "value": "Toast mit Ei und Avocado" },
      { "label": "Smoothiebowl", "value": "Smoothiebowl" },
      { "label": "Spiegeleier", "value": "Spiegeleier" },
      { "label": "Granola", "value": "Granola" }
    ],
    meal2: [
      { "label": "Apfel", "value": "Apfel" },
      { "label": "Magerquark", "value": "Magerquark" },
      { "label": "Nüsse", "value": "Nüsse" }
    ],
    meal3: [
      { "label": "Avocado", "value": "Avocado" },
      { "label": "Pfirsich", "value": "Pfirsich" },
      { "label": "Reiswaffeln", "value": "Reiswaffeln" }
    ],
    meal4: [
      { "label": "Oatmeal", "value": "Oatmeal" },
      { "label": "Rührei", "value": "Rührei" },
      { "label": "Smoothiebowl", "value": "Smoothiebowl" }
    ],
    meal5: [
      { "label": "Chili con carne", "value": "Chili con carne" },
      { "label": "Griechischer Salat mit Feta", "value": "Griechischer Salat mit Feta" },
      { "label": "Lachs mit Spargel", "value": "Lachs mit Spargel" }
    ]
  }
}
