import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import * as F from "./whyIamYourCoach.style";
import Chip from "components/General/formComponents/chip/Chip";
import DeafultImage from "assets/images/DefaultCoachbin.jpg";

const defaultDescription =
  "Als Ernährungsberater und Coach bei Foodiary begleite ich dich in den nächsten 12 Wochen Schritt für Schritt bei der Planung und Umsetzung deiner Ernährungsumstellung. Gemeinsam werden wir deine Ernährung gezielt anpassen und individuell auf deine persönlichen Bedürfnisse und Ziele abstimmen. Mit der Ernährungsplaner-App erhältst du praktische Unterstützung im Alltag, und die umfangreiche Lernacademy versorgt dich mit wertvollem theoretischen Wissen rund um Ernährung. Zudem stehe ich dir als dein persönlicher Coach jederzeit zur Seite, unterstütze dich bei Fragen und betreue dich aktiv auf deinem Weg zu einem gesünderen Lebensstil, damit du nachhaltige Erfolge erzielst.";

const WhyIamYourCoach = ({ openSurveyModal }) => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_lp_picture_2
    ? getS3ImageUrl(coach?.cw_lp_picture_2) || DeafultImage
    : DeafultImage;
  const isDefaultImg = coach?.cw_lp_picture_2 ? false : true;

  return (
    <F.WhyIamYourCoachContainer
      isDefaultImg={isDefaultImg}
      shadow={imgUrl !== DeafultImage}
    >
      <div className="image-preview">
        <div className="image-preview-wrapper">
          <img src={imgUrl} alt="" />
        </div>
      </div>
      <div className="content-preview">
        <Chip text="ÜBER MICH" />
        <div className="section-headline">
          <h3 className="ct-headline color-dark font-medium">
            Du kannst dich auf mich verlassen - Ich bin dein Coach.
          </h3>
        </div>
        <div className="section-description">
          <p
            dangerouslySetInnerHTML={{
              __html: coach?.cw_lp_textfield_2 || defaultDescription,
            }}
          ></p>
        </div>
        <div>
          <button onClick={openSurveyModal} className="btn-dark">
            Erstgespräch vereinbaren
          </button>
        </div>
      </div>
    </F.WhyIamYourCoachContainer>
  );
};

export default WhyIamYourCoach;
