import React from "react";
import * as C from "./PlanBox.style";
import Chip from "components/General/formComponents/chip/Chip";
import Garantie from "assets/images/myCoaching/icon-garantie.svg";
import ActiveUser from "assets/images/Page3/50k-nutzer.svg";
import SinglePlan from "./SinglePlan";

const PlanBoxApp = ({ openLeadFormModal }) => {
  return (
    <C.CoachingGlanceContainer>
      <C.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 20px 0" />

        <C.GlanceTitle className="ct-text-block color-dark text-left font-medium">
          Zahlreiche Vorteile die
          <br />
          begeistern.
        </C.GlanceTitle>

        <C.GlanceText className="ct-text-block color-paragraph text-base text-left">
          Schluss mit dubiosen Diäten- und Ernährungsmythen. Wir kennen uns aus,
          wissen wie man eine Ernährungsumstellung richtig angeht und wir helfen
          dir diese dauerhaft durchzuführen, ohne sich im Alltag einzuschränken.
          <br />
          <br />
          <span style={{ color: "#A5B5AF" }}>
            Nutze die Vorteile der Foodiary App in deinem Alltag.
          </span>
        </C.GlanceText>

        <C.GlanceTags>
          <img src={Garantie} alt="tag" />
          <img src={ActiveUser} alt="user" />
        </C.GlanceTags>
      </C.CoachingGlanceLeft>

      <C.PlansBox className="singleBox">
        <C.OnePlan>
          <SinglePlan openLeadFormModal={openLeadFormModal} />
        </C.OnePlan>
      </C.PlansBox>
    </C.CoachingGlanceContainer>
  );
};

export default PlanBoxApp;
