import React from "react";
import { Link, useHistory } from "react-router-dom";
import * as F from "./footerNew.style";

// Images
import Logo from "assets/images/myCoaching/Foodiary-Logo-White.png";
import PlayStore from "assets/images/myCoaching/play-store.png";
import AppStore from "assets/images/myCoaching/app-store.png";
import Instagram from "assets/images/new/instagram.svg";
import Pinterest from "assets/images/new/pinterest.svg";
import Linkedin from "assets/images/new/linkedin.svg";
import LeftImage from "assets/images/circle_white_left.png";
import { useSelector } from "react-redux";

function FooterNew() {
  const coach = useSelector((state) => state.coach.coach);
  const history = useHistory();
  const handleClick = () => {
    history.push("/erstgespraech");
  };
  return (
    <F.FooterWrap className="section-300-15">
      <F.Container className="ct-section-inner-wrap">
        <F.FooterContent>
          {/* <F.ConditionContainer>
            <div className="color-paragraph-alt">
              <p>
                Smarte Ernährungsplanung und individuelle Betreuung für ein
                gesundes Leben voller Energie und Selbstbewusstsein.
              </p>
            </div>
            <F.DividerLight />
          </F.ConditionContainer> */}
          <div className="top-footer">
            <F.FooterInfo>
              <Link to="/">
                <F.Logo src={Logo} alt="Footer Logo" />
              </Link>
              <div className="app-logos">
                <a
                  href="https://www.foodiary.app/get-the-app/"
                  className="ct-link hover-float"
                >
                  <img
                    id="image-32-15"
                    className="ct-image ios"
                    src={AppStore}
                    alt="Ios App"
                  />
                </a>
                <a
                  href="https://www.foodiary.app/get-the-app/"
                  className="ct-link hover-float"
                >
                  <img
                    id="image-32-15"
                    className="ct-image google"
                    src={PlayStore}
                    alt="Android App"
                  />
                </a>
              </div>
              <div className="color-paragraph-alt">
                <p>
                  Smarte Ernährungsplanung und individuelle Betreuung für ein
                  gesundes Leben voller Energie und Selbstbewusstsein.
                </p>
              </div>
            </F.FooterInfo>
            <F.FooterLinksContainer>
              <div>
                <F.GuideLineContainer>
                  <p className="text-base color-white">
                    Als dein persönlicher Ernährungscoach begleite ich dich
                    Schritt für Schritt bei deiner Ernährungsumstellung und
                    unterstütze dich mit wertvollen Tipps und Tricks.
                  </p>
                  <F.DividerMobile />
                  <button
                    onClick={handleClick}
                    className="guide-btn hover-float"
                  >
                    Erstgespräch
                  </button>
                </F.GuideLineContainer>
                <F.Divider />
              </div>
              <F.FooterLinks>
                <F.FooterContentUlSectionInner>
                  <F.Title className="text-2xl color-white">Angebot</F.Title>
                  <div className="links">
                    <Link
                      className="text-base color-paragraph-alt"
                      to="/erstgespraech"
                    >
                      Erstgespräch
                    </Link>
                    <Link
                      className="text-base color-paragraph-alt"
                      to="/coaching-plus"
                    >
                      Coaching Plus
                    </Link>
                    <Link
                      className="text-base color-paragraph-alt"
                      to="/coaching-pro"
                    >
                      Coaching PRO
                    </Link>
                    <a className="text-base color-paragraph-alt" href="/app">
                      Foodiary App
                    </a>
                    <Link
                      className="text-base color-paragraph-alt"
                      to="/online-kurs"
                    >
                      Online-Kurs
                    </Link>
                  </div>
                </F.FooterContentUlSectionInner>
                <F.FooterContentUlSectionInner>
                  <F.Title className="text-2xl color-white">About</F.Title>
                  <div className="links">
                    <a
                      className="text-base color-paragraph-alt"
                      href="https://www.foodiary.app"
                    >
                      Über Foodiary
                    </a>
                    <a
                      className="text-base color-paragraph-alt"
                      href="http://foodiary.app/magazin/"
                    >
                      Foodiary Magazin
                    </a>
                    <a
                      className="text-base color-paragraph-alt"
                      href="https://foodiary.app/partner/coach/"
                    >
                      Coach werden
                    </a>
                    <a
                      className="text-base color-paragraph-alt"
                      href={`mailto:${coach?.email}`}
                    >
                      Kontaktiere mich
                    </a>
                    <a
                      className="text-base color-paragraph-alt"
                      href="https://foodiary.app/terms/agb-coaching/"
                    >
                      Terms & Conditions
                    </a>
                  </div>
                </F.FooterContentUlSectionInner>
                <F.FooterContentUlSectionInner>
                  <F.Title className="text-2xl color-white">Service</F.Title>
                  <div className="links">
                    <a
                      className="text-base color-paragraph-alt"
                      href={`https://my.foodiary.app/`}
                    >
                      Login zur Lernacademy
                    </a>
                    <a
                      className="text-base color-paragraph-alt"
                      href={
                        "https://foodiary.app/get-the-app/?_gl=1*t6zl5l*_gcl_au*MjEzNjIwNTkyNy4xNzMyMjA2NDM3"
                      }
                    >
                      App herunterladen
                    </a>
                  </div>
                </F.FooterContentUlSectionInner>
              </F.FooterLinks>
            </F.FooterLinksContainer>
          </div>
          {/* <div className="bottom-footer">
            <div>
              <span className="ct-text-block text-xs pr-12">
                © {new Date().getFullYear()} Foodiary GmbH. All rights reserved.
              </span>
            </div>
            <div>
              <div>
                <a
                  className="ct-link-text text-sm color-paragraph-alt"
                  href="https://my.foodiary.app"
                >
                  Login für Klienten
                </a>
              </div>
              <div>
                <a
                  className="ct-link-text text-sm color-paragraph-alt"
                  href="#"
                >
                  Mitgliedschaft kündigen
                </a>
              </div>
              <div className="social">
                <a
                  className="ct-link-text text-sm color-paragraph-alt icon"
                  href="http://instagram.com/foodiary.app"
                >
                  <img
                    src={Instagram}
                    alt="instagram"
                    width="30px"
                    height="30px"
                  />
                </a>
                <a
                  className="ct-link-text text-sm color-paragraph-alt icon"
                  href="https://www.pinterest.de/foodiaryapp/"
                >
                  <img
                    src={Pinterest}
                    alt="pinterest"
                    width="30px"
                    height="30px"
                  />
                </a>
                <a
                  className="ct-link-text text-sm color-paragraph-alt icon"
                  href="https://www.linkedin.com/company/34645628/"
                >
                  <img
                    src={Linkedin}
                    alt="linkedin"
                    width="30px"
                    height="30px"
                  />
                </a>
              </div>
            </div>
          </div> */}
        </F.FooterContent>
      </F.Container>
    </F.FooterWrap>
  );
}

export default FooterNew;
