import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 60%;
    margin: auto;
  }
`

export const WeeklyCaloriesContainer = styled.div`
  .title {
    font-weight: bold;
  }
  
  .description {
    margin: 10px 0px;
    color: #44554C;
    font-size: 14px;
  }

  .sep {
    height: 1px;
    background: #E2E4E9;
    margin: 40px 0px;
  }
  
  .action {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
  }
  
  .chart-container {
    padding: 20px;
    margin: 10px 0px;
    box-shadow: rgba(214, 214, 214, 0.16) 0px 13px 20px;
    border: 2px solid rgb(226, 228, 233);
    border-radius: 10px;

    .chart-header {
      display: flex;
      justify-content: space-between;

      div:nth-child(1) {
        font-weight: bold;
      }

      div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        span:nth-child(1) {
          font-weight: bold;
          color: #2DCF96;
          font-size: 20px;
        }
      }
    }
  }
`

export const UpdateModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .week-selection {
    margin-top: 20px;
  }
  
  .cta {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .week-calories {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      font-size: 18px;
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  gap: 5px;

  > * {
    padding: 5px 10px;
    width: 150px;
  }
`

export const InputControl = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
  border: 1px solid #d0d5dd;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: .8;
  }
`
