import React from "react";
import * as F from "./FreeCourseSteps.style";
import StepCard from "components/General/stepCard/StepCard";
import Chip from "components/General/formComponents/chip/Chip";
import { useHistory } from "react-router-dom";

const FreeCourseSteps = ({
  onLeadFormModal,
  list,
  children,
  chipText,
  btnText = "Erstgespräch vereinbaren",
  modal = false,
  routeName,
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (modal) {
      return onLeadFormModal();
    }
    if (btnText) {
      return history.push({
        pathname: "/onboarding/gender",
        state: { params: routeName },
      });
    } else return onLeadFormModal();
  };
  return (
    <F.Wrap>
      <F.SectionTitle className="ct-text-block color-dark text-left font-medium">
        {chipText ? (
          <>
            <F.ChipWrapper>
              <Chip text={chipText} />
            </F.ChipWrapper>
          </>
        ) : (
          ""
        )}
        {children}
      </F.SectionTitle>

      <F.CardsWrap>
        {list.map((item) => (
          <StepCard
            title={item?.title}
            description={item?.description}
            icon={item?.icon}
            height="280px"
          />
        ))}
      </F.CardsWrap>

      {onLeadFormModal ? (
        <F.Action>
          <button className="btn-dark" onClick={handleClick}>
            {btnText}
          </button>
        </F.Action>
      ) : (
        ""
      )}
    </F.Wrap>
  );
};

export default FreeCourseSteps;
