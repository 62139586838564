import React from "react";
import ReactPlayer from "react-player";

import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./CoachingCover.style";
import CoachingCoverBanner from "assets/images/coaching-banner.svg";
import videoSource from "assets/videos/coaching-cover.mp4";

const CoachingCover = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <div className="text-content">
        <Chip
          color={"#1C9D87"}
          bg={"white"}
          text="Mein Coaching"
          margin="0 0 20px 0"
        />

        <C.Title className="ct-headline color-dark font-semibold">
          Ich begleite dich auf dem Weg zu deinem Ziel.
          {/* Ich begleite dich bei deiner <br /> Ernährungsumstellung. */}
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Ich unterstütze dich als dein persönlicher Ernährungsberater und Coach
          auf deinem Weg zu einer gesunden Ernährung. Mit der Lernacademy, der
          Ernährungsplaner-App und meiner Unterstützung als deinem
          Ansprechpartner integrieren wir eine gesunde, zielgerichtete Ernährung
          in dein Leben. Gemeinsam erarbeiten wir Strategien, die dir helfen,
          deine Ziele zu erreichen und langfristige Veränderungen zu erzielen.
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>
            Erstgespräch vereinbaren
          </button>
        </C.Action>
      </div>
      <C.VisualContent>
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default CoachingCover;
