import React from "react";
import * as C from "./PlanBoxCoachingPlus.style";
import Chip from "components/General/formComponents/chip/Chip";
import Garantie from "assets/images/myCoaching/icon-garantie.svg";
import ActiveUser from "assets/images/Page3/50k-nutzer.svg";
import SinglePlan from "./SinglePlan";

const PlanBoxCoachingPro = ({ openLeadFormModal }) => {
  return (
    <C.CoachingGlanceContainer>
      <C.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 20px 0" />

        <C.GlanceTitle className="ct-text-block color-dark text-left font-medium">
          Ein unschlagbares Angebot für deine Gesundheit.
        </C.GlanceTitle>

        <C.GlanceText className="ct-text-block color-paragraph text-base text-left">
          Geprüftes Expertenwissen, umfangreiche Handouts, ein Wissenquiz für
          deinen Lernerfolg und zudem ein persönliches Erstgespräch mit deinem
          eigenen Ernährungsplan. Das alles erwartet dich in meinem Coaching
          PLUS. Das Beste? - Die Kostenrückerstattung von bis zu 100% durch
          deine Krankenkasse für den Präventionskurs. Starte jetzt deine gesunde
          Ernährungsreise!
        </C.GlanceText>

        <C.GlanceTags>
          <img src={Garantie} alt="tag" />
          <img src={ActiveUser} alt="user" />
        </C.GlanceTags>
      </C.CoachingGlanceLeft>

      <C.PlansBox className="singleBox">
        <C.OnePlan>
          <SinglePlan openLeadFormModal={openLeadFormModal} />
        </C.OnePlan>
      </C.PlansBox>
    </C.CoachingGlanceContainer>
  );
};

export default PlanBoxCoachingPro;
