import React from "react";
import { useHistory } from "react-router-dom";
import { PopupButton } from "react-calendly";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./startTogether.styled";
// Images
import StartTogetherImage from "assets/images/Foodiary-rocket-coach.jpg";

const StartTogether = ({ onLeadFormModal }) => {
  const history = useHistory();

  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.TextContent>
        <Chip text="Kennenlernen" margin="0 0 20px 0" />

        <C.Title className="ct-headline color-dark font-medium">
          Mein 3 monatiges Coaching – digital und persönlich.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          In einem unverbindlichen Erstgespräch per Video-Call lernen wir uns
          persönlich kennen und besprechen deine Ziele. Gemeinsam finden wir
          heraus, welche Form der Betreuung am besten für dich geeignet ist.
          Während unseres Gesprächs erstelle ich dir direkt einen individuellen
          Ernährungsplan, der auf deine Bedürfnisse zugeschnitten ist. So kannst
          du sofort mit der Umsetzung beginnen!
        </C.Description>

        <C.Action>
          <div className="mt-10">
            <button className="btn-dark" onClick={onLeadFormModal}>
              Erstgespräch vereinbaren
            </button>
          </div>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <img src={StartTogetherImage} alt="together" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default StartTogether;
