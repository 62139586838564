import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./PersonalSupport.style";
import PersonalSupportImg from "assets/images/personal-support.jpg";
import ReactPlayer from "react-player";
import videoSource from "assets/videos/personal-support.mp4";
const PersonalSupport = ({
  changeOrder = false,
  onLeadFormModal,
  mediaType,
  title,
  description,
  btnText,
  callBack,
}) => {
  return (
    <C.Wrap changeOrder={changeOrder} className={"NutritionCoachWrap "}>
      <div className="text-content">
        <Chip text="Online Kurs" margin="0 0 20px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          {title}
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          {description}
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={callBack}>
            {btnText}
          </button>
        </C.Action>
      </div>
      <C.VisualContent>
        {mediaType == "image" ? (
          <img src={PersonalSupportImg} alt="" />
        ) : (
          <ReactPlayer url={videoSource} playsinline playing muted loop />
        )}
      </C.VisualContent>
    </C.Wrap>
  );
};

export default PersonalSupport;
