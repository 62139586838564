import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./NutritionPlanner.style";
import ReactPlayer from "react-player";
import videoSource from "assets/videos/stats-dashboard.mp4";

const NutritionPlanner = ({ onLeadFormModal }) => {
  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.VisualContent>
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
      <C.TextContent>
        <Chip text="APP" margin="0 0 20px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Über 1700 leckere Rezepte begleiten dich.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Die Ernährungsplaner-App ist kostenlos in meinem Coaching PLUS
          enthalten und unterstützt dich dabei, dein neues Wissen direkt in die
          Praxis umzusetzen. Mit einer vielfältigen Auswahl an gesunden
          Rezepten, einer smarten Einkaufsliste und deinem persönlichen
          Ernährungsplan ist sie dein perfekter Begleiter für die nächsten drei
          Monate. So wird es dir leichtfallen, deine Ernährungsziele langfristig
          zu erreichen und gesunde Gewohnheiten in deinen Alltag zu integrieren.
        </C.Description>
        <C.Action>
          <div>
            <button className="btn-dark" onClick={() => onLeadFormModal()}>
              Erstgespräch vereinbaren
            </button>
          </div>
        </C.Action>
      </C.TextContent>
    </C.Wrap>
  );
};

export default NutritionPlanner;
