import * as Styles from "./PaymentStyles";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getOnboardingStorage, REGISTER_STEP } from "../storage";
import { useHistory } from "react-router-dom";
import { createStripeCustomer, createStripeSubscription } from "./api";
import OnboardingLoader from "../wrapper/OnboardingLoader";
import CheckoutForm from "./CheckoutForm";
import { STRIPE_PUBLISHABLE_KEY } from "configs/utils";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function Payment() {
  const storage = getOnboardingStorage(REGISTER_STEP);
  const history = useHistory();
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    const nutritionConcept = storage["$nutritionconcept"];

    if (!nutritionConcept) {
      console.error("Registration data is not available !");
      return history.push("/");
    }

    const initStripe = async function () {
      const {
        userID,
        trainerID,
        user: { prename, surname, email },
      } = nutritionConcept;
      const stripeCustomer = await createStripeCustomer({
        email,
        name: prename + " " + surname,
      });
      const stripeSubscription = await createStripeSubscription({
        userID,
        trainerID,
        customer_id: stripeCustomer.id,
        price_id: "price_1N819zA7QE5sDrDRZqpzYbAF",
      });

      setClientSecret(stripeSubscription.clientSecret);
    };

    initStripe();
  }, []);

  if (!clientSecret || !stripePromise) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <div className="step-header">
        <div className="header-content">
          <div>
            <div className="headline">Zahlung</div>
            <div className="sub-headline">
              Bitte folge den Anweisungen von Stripe
            </div>
          </div>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <Styles.PricingBoxContainer>
            <div className="headline">Abonnement</div>
            <div className="content">
              <div className="content-preview">
                <div className="box">pro</div>
                <div>
                  <div className="headline">Ernährungsplaner-App</div>
                  <div className="subheading">
                    Pro Funktionen mit 1700 Rezepten
                  </div>
                  <span>3 Monate Laufzeit</span>
                </div>
              </div>
              <div className="content-pricing">
                <span>28,99 €</span>
                <span>Quartalsweise</span>
              </div>
            </div>
            <div className="footer">
              <div>Heute</div>
              <div>
                <span>28,99 €</span>
                <span>inkl. MwSt.</span>
              </div>
            </div>
          </Styles.PricingBoxContainer>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        </Styles.MainContainer>
      </div>
    </div>
  );
}

export default Payment;
