import React from "react";
import * as F from "./stepCard.styles";

// Images
import IconFood from "assets/images/myCoaching/Icon-food.svg";

const StepCard = (props) => {
  return (
    <F.Card
      width={props.width}
      minMobileWidth={props.minMobileWidth}
      height={props.height}
      margin={props.margin}
      style={props.styles}
      {...props}
    >
      <div>
        <F.Title
          className="ct-text-block color-primary font-medium text-xl"
          dangerouslySetInnerHTML={{ __html: props?.title }}
        />
        <F.Description
          className="ct-text-block mt-8 color-paragraph text-base font-normal"
          dangerouslySetInnerHTML={{ __html: props?.description }}
        />
      </div>

      <F.IconBox>
        <img src={props?.icon || IconFood} alt="food" />
      </F.IconBox>
    </F.Card>
  );
};

export default StepCard;
