import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./nextSteps.styled";

// Images
import NextStepsPng from "assets/images/Coach-Zoom.jpg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";

const NextSetps = ({ onLeadFormModal }) => {
  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="Termin" margin="0 0 20px 0" />
        <C.Title className="ct-headline color-dark font-medium">
          Schritte zu einem kostenlosen Ernährungsplan.
        </C.Title>
        <C.PlanBoxIcons className="procedure">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Öffne das Formular, indem du auf „Erstgespräch vereinbaren”
              klickst, und beantworte mir drei kurze Fragen. So kann ich besser
              verstehen, wie ich dich am besten unterstützen kann.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Ich werde mich innerhalb von 48 Stunden telefonisch bei dir
              melden, um einen geeigneten Termin für unser Erstgespräch zu
              finden.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Zum vereinbarten Zeitpunkt treffen wir uns in einem Video-Call, um
              die IST-Analyse durchzugehen und deinen persönlichen
              Ernährungsplan zu erstellen.
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>
            Erstgespräch vereinbaren
          </button>
        </C.Action>
      </C.TextContent>

      <C.VisualContent>
        <img src={NextStepsPng} alt="zoom" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default NextSetps;
