import styled from "styled-components";

export const SetPasswrordContainer = styled.div`
  padding: 0 15%;
  @media (max-width: 768px) {
    padding: 0;
  }
  .direction-column {
    display: flex;
    flex-direction: column;
  }
  .direction-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin-top: 10px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .label {
    font-size: 14px;
    color: #142c1f;
    margin-bottom: 5px;
  }
`;

export const Input = styled.input`
  border: 1px solid ${(props) => (props.error ? "#FF4D4F" : "#e2e4e9")};
  border-radius: 10px;
  background-color: ${(props) => (props.name == "email" ? "#E2E4EA" : "white")};
  color: black;
  font-size: 14px;
  width: 275px;
  padding: 9px 15px;
  outline: none;
`;
export const ErrorText = styled.span`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
`;
