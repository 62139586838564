import styled from "styled-components";

export const Wrap = styled.div``;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 50px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
  }

  /* @media(max-width: 600px) {
    grid-template-columns: 1fr;
  } */
`;

export const SectionTitle = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  /* font-size: 2.3rem; */
  font-weight: 300;

  b {
    font-weight: 500;
  }

  @media (max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SectionTitleMobile = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-size: 2.3rem;
  font-weight: 300;

  b {
    font-weight: 500;
  }

  @media (min-width: 600.99px) {
    display: none;
  }
`;
export const Title = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 20px;

  b {
    font-weight: 500;
  }

  @media (max-width: 991.99px) {
    margin-bottom: 20px;
    text-align: left;
    font-size: 3.2rem;
  }
`;
export const VisualContent = styled.div`
  /* height: 600px; */
  img {
    width: 100%;
    max-width: 700px;
    height: 100%;
    display: block;
  }
  @media (max-width: 991.99px) {
    margin-top: 70px;
  }

  @media (max-width: 768px) {
    /* height: 400px; */
    display: none;
  }
`;

export const TextContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  padding: 3rem;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;

  &:not(.hover-none):hover {
    cursor: pointer;
  }

  &.selected,
  &:not(.hover-none):hover {
    box-shadow: 0px 20px 40px 0px rgba(2, 51, 42, 0.1);
  }
  .selected-img-on-mobile {
    max-width: 80%;
    margin-inline: auto;
    width: 100%;
    display: block;
  }

  @media (min-width: 768.99px) {
    .selected-img-on-mobile {
      display: none;
    }
  }
`;
