import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import { getPreviousOnboardingPage, getNextOnboardingPage } from '../../../routes/onboardingRoutes'
import * as Styles from './MacroNutrientsStyles'
import { getOnboardingStorage, REGISTER_STEP } from '../storage'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import OnboardingLoader from '../wrapper/OnboardingLoader'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'

function MacroNutrients ({ currentPath }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const storage = getOnboardingStorage(REGISTER_STEP)
  const concept = storage['$nutritionconcept']
  const nutritiontypeID = concept?.fitnessdata?.nutritiontypeID
  const [ nutritionChartData, setNutritionChartData ] = useState(null)

  useEffect(() => {
    dispatch(
      NutritionActions.getMacroNutrientsPieChartDataRequest({
        payload: { nutritiontypeID },
        callback: (res, error) => {
          if (error) return
          const { nutritiontype, pieChartData } = res
          setNutritionChartData({
            nutritiontype,
            pieChartData
          })
        },
      })
    )
  }, [ dispatch ])


  if (!nutritiontypeID) return history.push('/')
  if (!nutritionChartData) return <OnboardingLoader />

  const handleNextPage = () => (
    history.push(getNextOnboardingPage(currentPath))
  )

  const { pieChartData } = nutritionChartData
  const [ trainingDay, restDay ] = pieChartData
  console.log(JSON.stringify(trainingDay, null, 2))

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Auswertung</div>
            <div className="sub-headline">Unsere Empfehlung für dich</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <Styles.MacroNutrientsContainer>
            <div className="title">Deine persönliche Nährstoffverteilung</div>
            <div className="description">Deine Makronährstoffe haben wir anhand deiner Ernährungsform bestimmt.</div>
            <div className="description">Bei deiner Ernährungsform {nutritionChartData.nutritiontype.de_name} setzen sich deine Kalorien aus folgender Verteilung zusammen:</div>
            <div className="charts">
              <div className="chart-box">
                <div className="box-title">Trainingstag :</div>
                <div className="chart">
                  <NutritionPieChart data={trainingDay} />
                </div>
                <div className="details">
                  <div>
                    <div className="bullet protein"></div>
                    <span>{parseFloat(trainingDay.proteinData).toFixed(0)}%  Eiweiß</span>
                  </div>
                  <div>
                    <div className="bullet carbs"></div>
                    <span>{parseFloat(trainingDay.carbohydratesData).toFixed(0)}%  Kohlenhydrate</span>
                  </div>
                  <div>
                    <div className="bullet fats"></div>
                    <span>{parseFloat(trainingDay.fatData).toFixed(0)}%  Fett</span>
                  </div>
                </div>
              </div>
              <div className="chart-box">
                <div className="box-title">Trainingsfrei :</div>
                <div className="chart">
                  <NutritionPieChart data={restDay} />
                </div>
                <div className="details">
                  <div>
                    <div className="bullet protein"></div>
                    <span>{parseFloat(restDay.proteinData).toFixed(0)}%  Eiweiß</span>
                  </div>
                  <div>
                    <div className="bullet carbs"></div>
                    <span>{parseFloat(restDay.carbohydratesData).toFixed(0)}% Kohlenhydrate</span>
                  </div>
                  <div>
                    <div className="bullet fats"></div>
                    <span>{parseFloat(restDay.fatData).toFixed(0)}%  Fett</span>
                  </div>
                </div>
              </div>
            </div>
          </Styles.MacroNutrientsContainer>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

const NutritionPieChart = ({ data }) => {
  data = [
    { name: 'Carbohydrates', value: parseInt(data.carbohydratesData) },
    { name: 'Protein', value: parseInt(data.proteinData) },
    { name: 'Fat', value: parseInt(data.fatData) },
  ];

  const COLORS = ['#2DCF96', '#C74243', '#FFC303']

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius="80%"
          innerRadius="60%"
          paddingAngle={5}
          dataKey="value"
          stroke="#ffffff"
          strokeWidth={1}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default MacroNutrients
