import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.changeOrder ? "2fr 1fr" : "1fr 2fr"};
  gap: 60px;
  align-items: center;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }

    @media (max-width: 991.99px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }
`;

export const TextContent = styled.div``;

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 20px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`;
export const Description = styled.p`
  color: #44554c;
`;

export const InsuranceCompanies = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 20px;

  .company-item {
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 10px 20px #02332a1a;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 100px;

    @media (max-width: 991.99px) {
      width: auto;
      height: auto;
    }

    img {
      width: 100%;
      object-fit: cover;
      filter: grayscale(100%);
    }
  }
`;

// export const VisualContent = styled.div`
//   height: 600px;

//   img {
//     width: 100%;
//     height: 100%;
//   }

//   @media (max-width: 767.99px) {
//     height: 400px;
//   }
// `;

export const VisualContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
    display: block;
    max-width: 700px;
    height: auto;
    object-fit: cover;
  }

  // position: relative;

  // & > div {
  //   max-width: 600px;
  //   width: 100% !important;
  //   height: auto !important;
  // }

  // img {
  //   width: 100%;
  //   height: 100%;
  //   max-width: 500px;
  //   margin-left: auto;
  //   display: block;
  // }

  // @media (max-width: 1000px) {
  //   margin-inline: auto;
  //   order: 1;
  // }

  // @media (max-width: 767.99px) {
  //   height: auto;
  // }
`;

export const Action = styled.div`
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
  }
`;
