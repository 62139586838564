import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import { getPreviousOnboardingPage, getNextOnboardingPage } from '../../../routes/onboardingRoutes'
import * as Styles from './NutritionRecipesStyles'
import { getOnboardingStorage, REGISTER_STEP } from '../storage'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import OnboardingLoader from '../wrapper/OnboardingLoader'
import SliderArrowLeft from 'assets/images/onboarding/slider-arrow-left.svg'
import SliderArrowRight from 'assets/images/onboarding/slider-arrow-right.svg'

const availableMeals = [
  { id: 1, label: 'Frühstück' },
  { id: 2, label: 'Mittagessen' },
  { id: 3, label: 'Abendessen' }
]

const getNutritionImageUrl = (path, image, type = null) => {
  const BASE_URL = "https://app.foodiary.app/"
  if (!type) return BASE_URL + path + image
  return BASE_URL + path + "/" + image + "." + type
}

function NutritionRecipes ({ currentPath }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [ selectedMeal, setSelectedMeal ] = useState(availableMeals[0])
  const [ meals, setMeals ] = useState(null)
  const [ displayedMealsIndex, setDisplayedMealsIndex ] = useState(0)
  const [ loading, setLoading ] = useState(false)
  const storage = getOnboardingStorage(REGISTER_STEP)
  const concept = storage['$nutritionconcept']
  const nutritiontypeID = concept?.fitnessdata?.nutritiontypeID

  useEffect(() => {
    setLoading(true)
    dispatch(
      NutritionActions.getNutritionRecipesRequest({
        nutritiontypeID,
        mealId: selectedMeal.id,
        callback: (data, error) => {
          setLoading(false)
          if (error || !data) return
          setMeals(data)
          setDisplayedMealsIndex(0)
        }
      })
    )

  }, [ selectedMeal ])

  if (!nutritiontypeID) return history.push('/')
  if (!meals || loading) return <OnboardingLoader />

  const handleNextPage = () => (
    history.push(getNextOnboardingPage(currentPath))
  )

  const handleNextMealDisplayed = () => {
    if (displayedMealsIndex + 2 >= meals.length) {
      return setDisplayedMealsIndex(0)
    }

    setDisplayedMealsIndex(displayedMealsIndex + 2)
  }

  const handlePreviousMealDisplayed = () => {
    if (displayedMealsIndex === 0) {
      return setDisplayedMealsIndex(meals.length - 2)
    }

    setDisplayedMealsIndex(displayedMealsIndex - 2)
  }

  const displayMeals = meals.slice(
    displayedMealsIndex,
    displayedMealsIndex + 2
  )

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Auswertung</div>
            <div className="sub-headline">Unsere Empfehlung für dich</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <Styles.NutrientsRecipesContainer>
            <div className="title">Rezeptbeispiele aus über 1700 Rezepten</div>
            <div className="description">Keine Einschränkungen im Alltag und völlige Flexibilität</div>
            <div className="weekdata-container">
              <div className="week-selection">
                {availableMeals.map(meal => (
                  <span
                    className={meal.id === selectedMeal.id ? 'active' : ''}
                    onClick={() => setSelectedMeal(meal)}
                    key={meal.id}>
                      {meal.label}
                    </span>
                ))}
              </div>
              <div className="slider-container">
                <div className="slider-heading">
                  <span>Eine kleine Auswahl unserer Rezepte</span>
                  <div className='slider-controls'>
                    <div onClick={handlePreviousMealDisplayed}><img src={SliderArrowLeft} alt="" /></div>
                    <div onClick={handleNextMealDisplayed}><img src={SliderArrowRight} alt="" /></div>
                  </div>
                </div>
                <div className="slider-meals">
                  {displayMeals.map(meal => <MealCard key={meal.id} meal={meal} />)}
                </div>
              </div>
            </div>
          </Styles.NutrientsRecipesContainer>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

function MealCard ({ meal }) {
  const bgImage = getNutritionImageUrl( meal?.imgpath, meal?.imgname, meal?.imgtype )
  const bgStyles = {
    background: `url(${bgImage}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }

  return (
    <div className='meal-card'>
      <div className='preview'>
        <span style={bgStyles}></span>
      </div>
      <div className='content'>
        <span>{meal.recipeName}</span>
        <span>{meal.mealName}</span>
      </div>
      <div className='calories'>
        <span>{meal.calories}</span>
        <span>kcal</span>
      </div>
    </div>
  )
}

export default NutritionRecipes
