import React, { useState } from "react";
import * as C from "./DietPlanning.styled";

// Images
import FoodIconOne from "assets/images/App/Prepmymeal.svg";
import FoodIconTwo from "assets/images/App/Foodspring.svg";
import FoodIconThree from "assets/images/App/deananddavid.svg";
import FoodIconFour from "assets/images/App/rocka.svg";
import FoodIconFive from "assets/images/App/on.svg";

const DietPlanning = () => {
  return (
    <C.MealPlanContainer>
      <C.Title className="ct-headline foodiary-h2 color-dark uppercase">
        Flexible Planung
        <C.Title>
          <b>Mit Meal Prep und Restaurants</b>
        </C.Title>
      </C.Title>

      <C.RestaurauntsBox>
        <div className="restaurant">
          <img src={FoodIconOne} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconTwo} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconThree} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconFour} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconFive} alt="" />
        </div>
      </C.RestaurauntsBox>
    </C.MealPlanContainer>
  );
};

export default DietPlanning;
