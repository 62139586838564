import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getNextOnboardingPage } from '../../../routes/onboardingRoutes'
import * as Styles from './NutritionTypeStyles'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import * as NutritionActions from 'redux/nutrition/nutrition.actions'
import {
  getOnboardingStorage,
  REGISTER_STEP,
  setOnboardingStorage
} from '../storage'
import nutritionTypeIcon from 'assets/images/onboarding/nutrition-type-icon.svg'
import OnboardingLoader from '../wrapper/OnboardingLoader'

function NutritionType ({ currentPath }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [ updateNutritionModal, setUpdateNutritionModal ] = useState({})
  const [ nutritionTypeList, setNutritionTypeList ] = useState(null)
  const [ nutritionTypeAdvantages, setNutritionTypeAdvantages ] = useState(null)
  const [ selectedNutritionType, setSelectedNutritionType ] = useState(null)
  const [ loading, setLoading ] = useState(
    !nutritionTypeList
    || !nutritionTypeAdvantages
    || !selectedNutritionType
  )

  useEffect(() => {

    const storage = getOnboardingStorage(REGISTER_STEP)

    // should not be here if no onboarding data is available
    if (!storage['$nutritionconcept']) {
      return history.push('/')
    }

    fetchNutritionData()

  }, [ dispatch ])

  if (loading) return <OnboardingLoader />

  function fetchNutritionData () {
    const storage = getOnboardingStorage(REGISTER_STEP)
    const nutritionConcept = storage['$nutritionconcept']

    const aimID = nutritionConcept?.fitnessdata?.aimID
    const sex = nutritionConcept?.user?.sex
    const nutritiontypeID = nutritionConcept?.fitnessdata?.nutritiontypeID

    dispatch(
      NutritionActions.getAllNutritionTypesRequest({
        aimID,
        sex,
        callback: typesListData => {
          dispatch(
            NutritionActions.getNutritionTypeRequest({
              nutritiontypeID,
              aimID,
              sex,
              callback: currentNutritionType => {
                dispatch(
                  NutritionActions.getNutritionAdvantagesRequest({
                    nutritiontypeID,
                    aimID,
                    sex,
                    callback: advantages => {
                      setNutritionTypeList(typesListData)
                      setSelectedNutritionType(currentNutritionType)
                      setNutritionTypeAdvantages(advantages)
                      setLoading(false)
                    }
                  })
                )
              }
            })
          )
        }
      })
    )
  }

  const handleNextPage = () => {
    history.push(getNextOnboardingPage(currentPath))
  }

  const closeUpdateNutritionModal = () => (
    setUpdateNutritionModal({})
  )

  const openUpdateNutritionModal = () => (
    setUpdateNutritionModal({ ...selectedNutritionType, show: true })
  )

  const updateNutritionTypeIdStorage = newId => {
    const storage = getOnboardingStorage(REGISTER_STEP)
    const concept = storage['$nutritionconcept']

    const updatedConcept = {
      ...concept,
      fitnessdata: {
        ...concept.fitnessdata,
        nutritiontypeID: newId,
        nutritiontype: selectedNutritionType
      }
    }

    setOnboardingStorage(REGISTER_STEP, {
      ...storage,
      '$nutritionconcept': updatedConcept
    })
  }

  const handleSaveNewType = () => {
    setLoading(true)
    closeUpdateNutritionModal()

    const storage = getOnboardingStorage(REGISTER_STEP)
    const nutritionConcept = storage['$nutritionconcept']
    const aimID = nutritionConcept?.fitnessdata?.aimID
    const sex = nutritionConcept?.user?.sex
    const nutritiontypeID = selectedNutritionType.nutritiontypeID || selectedNutritionType.id
    const nutritionconceptID = nutritionConcept?.fitnessdata?.nutritionconceptID

    dispatch(
      NutritionActions.updateNutritionTypeRequest({
        nutritiontypeID,
        aimID,
        sex,
        nutritionconceptID,
        callback: (response) => {
          updateNutritionTypeIdStorage(nutritiontypeID)
          fetchNutritionData()
        }
      })
    )
  }

  const updateNutritionModalContent = (
    <Modal
      title="Ernährungsform bearbeiten"
      centered
      open={updateNutritionModal.show}
      footer={false}
      onCancel={closeUpdateNutritionModal}
      width={'auto'}
    >
      <Styles.ModalContent>
        <div className="list">
          {nutritionTypeList.map(type => (
            <div
              key={type.name}
              className={selectedNutritionType.name === type.name ? 'choice active' : 'choice'}
              onClick={() => setSelectedNutritionType(type)}
            >
              <div className="icon"><img src={nutritionTypeIcon} alt="" /></div>
              <span>{type.de_name}</span>
            </div>
          ))}
        </div>
        <div className="content">
          {selectedNutritionType['01_text']}
        </div>
        <div className="action">
          <Button onClick={handleSaveNewType} type='primary'>Änderung speichern</Button>
        </div>
      </Styles.ModalContent>
    </Modal>
  )

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <div>
            <div className="headline">Auswertung</div>
            <div className="sub-headline">Unsere Empfehlung für dich</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        { updateNutritionModalContent }
        <Styles.MainContainer>
          <Styles.NutritionTypeContainer>
            <div className="type-container">
              <div className="icon"><img src={nutritionTypeIcon} alt="" /></div>
              <div className="name">{selectedNutritionType.de_name}</div>
              <div className="description">{selectedNutritionType['01_text']}</div>
              <Button onClick={openUpdateNutritionModal}>Ernährungsform bearbeiten</Button>
            </div>

            <Styles.Separator />

            <div className="advantages-container">
              <div className="headline">Deine Vorteile</div>
              <div className="list">
                <div>
                  <span className='advantage-icon'>1</span>
                  <span>{nutritionTypeAdvantages['01_advantage']}</span>
                </div>
                <div>
                  <span className='advantage-icon'>2</span>
                  <span>{nutritionTypeAdvantages['02_advantage']}</span>
                </div>
                <div>
                  <span className='advantage-icon'>3</span>
                  <span>{nutritionTypeAdvantages['03_advantage']}</span>
                </div>
              </div>
            </div>
          </Styles.NutritionTypeContainer>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

export default NutritionType
