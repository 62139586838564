import styled from "styled-components";

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  align-items: center;
  justify-content: center;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
`;

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})``;

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 20px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`;
export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  color: #7a8780;
`;

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: 100%;
  }

  & > div {
    max-width: 700px;
    width: 100% !important;
    height: auto !important;
  }

  @media (max-width: 767.99px) {
    height: 400px;
  }
`;

export const Action = styled.div`
  margin-top: 20px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: flex-start;
  }
`;
