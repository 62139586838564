import styled from "styled-components";

export const CoachingAccordianContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }

    @media (max-width: 991.99px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }
`;

export const CoachingAccordianLeft = styled.div``;

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 20px;
`;

export const CoachingAccordianRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 700px;
  }
`;

export const Info = styled.p`
  color: #142c1f;
  margin-bottom: 40px;
  @media (max-width: 991.99px) {
    margin-bottom: 0px;
  }
`;
