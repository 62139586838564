import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./PlannerApp.style";
import PlannerAppBanner from "assets/images/planner-app.svg";
import { useHistory } from "react-router-dom";
import videoSource from "assets/videos/stats-dashboard.mp4";
import ReactPlayer from "react-player";

const PlannerApp = ({ onLeadFormModal }) => {
  const history = useHistory();

  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.TextContent>
        <Chip text="APP" margin="0 0 20px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium ">
          Verwende die Ernährungsplaner - App für deinen Alltag.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Nutze die Vorteile der Foodiary App, um eine bewusste Ernährung
          einfach in deinen Alltag zu integrieren. Plane deine Mahlzeiten
          gezielt, priorisiere deine Gesundheit und dein Wohlbefinden – jetzt
          ist der perfekte Zeitpunkt, deine Ernährungsumstellung zu beginnen!
          Mit Funktionen wie der Wochenplanung, einer dynamischen Einkaufsliste
          und einem Rezeptfilter findest du jederzeit passende Gerichte.
        </C.Description>

        <C.Action>
          <div>
            <button className="btn-dark" onClick={() => history.push("/app")}>
              Mehr zur App
            </button>
          </div>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default PlannerApp;
