import axios from "axios";

// Default API will be your root
const TIMEOUT = 20000;

const API_KEY =
  process.env.REACT_APP_ENV === "development"
    ? "hjod58dh5ghh6XxJAN362EHEGUNfmDxtn3WiRGlKkPe56ez83opUu2jaBsjedqMN1rbvnF8snWIpPalqkLJH7dfuLigaWWmtlK1Hbl6uLrP3gWerT4g2v0x5caJHMxp2"
    : "uEbwWdT5hhgikMjeSfOf5UwxnK9fPZRMveP5t3lFEyuR7rlKlixSs53wlrU9Vj9zsbnVCrlkrbCzcIap7hmjgjcGFPXcFNzELRb0dG3LrL2TWGkuKJH88QrCtLtsg0fw";

const HEADERS = {
  "Api-Version": "v1",
  responseType: "application/json",
  "Content-Type": "application/json",
  Accept: "application/json",
  "x-api-key": API_KEY,
};
const trainerAuthorization = () => localStorage.getItem("trainer_token");
const Authorization = () => localStorage.getItem("token");

class ApiService {
  constructor({
    baseURL,
    loginType,
    timeout = TIMEOUT,
    headers = HEADERS,
    auth,
  }) {
    const client = axios.create({
      baseURL,
      timeout,
      headers,
      auth,
    });
    if (loginType === "user") {
      client.interceptors.request.use(
        function (config) {
          if (Authorization()) {
            config.headers.Authorization = "Bearer " + Authorization();
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
    }
    if (loginType === "trainer") {
      client.interceptors.request.use(
        function (config) {
          if (trainerAuthorization()) {
            config.headers.Authorization = "Bearer " + trainerAuthorization();
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
    }
    this.client = client;
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    return Promise.reject(error);
  }

  get(path) {
    return this.client.get(path).then((response) => response.data);
  }

  post(path, payload) {
    return this.client
      .post(path, JSON.stringify(payload))
      .then((response) => response.data);
  }

  postWithoutAuthorization(baseURL, path, payload) {
    return axios
      .post(baseURL + path, payload, {
        headers: {
          "x-api-key": API_KEY,
          responseType: "application/json",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  }

  imgPost(path, payload) {
    return this.client.post(path, payload).then((response) => response.data);
  }

  put(path, payload) {
    return this.client
      .put(path, JSON.stringify(payload))
      .then((response) => response.data);
  }

  patch(path, payload) {
    return this.client
      .patch(path, JSON.stringify(payload))
      .then((response) => response.data);
  }

  delete(path) {
    return this.client.delete(path).then((response) => response.data);
  }
}

export default ApiService;
