import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import CoachingWithMe from "components/CoachingWithMe/CoachingWithMe";
import WhyIamYourCoach from "components/WhyIamYourCoach/WhyIamYourCoach";
import StartNutritionChange from "components/StartNutritionChange/StartNutritionChange";
import FreeInitialConsultation from "components/FreeInitialConsultation/FreeInitialConsultation";
import FooterNew from "components/FooterNew/FooterNew";
import MealPlanTools from "components/MealPlanTools/MealPlanTools";
import CoachingGlance from "components/CoachingGlance/CoachingGlance";
import CoachingAccordian from "components/CoachingAccordian/CoachingAccordian";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";

// Redux
import * as CoachActions from "redux/coach/coach.actions";

// Styles
import * as G from "styles/General.Styles";
import * as C from "./myCoaching.styles";
import FeaturesCards from "components/FeaturesCards/FeaturesCards";
import CoachingProFeatures from "components/CoachingProFeatures/CoachingProFeatures";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import FreeInitialConsultationWithSurvey from "components/FreeInitialConsultationWithSurvey/FreeInitialConsultationWithSurvey";
import HelpWithSurvey from "components/HelpWithSurvey/HelpWithSurvey";
import ReimburseHealthInsurance from "components/ReimburseHealthInsurance/ReimburseHealthInsurance";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import { IS_LOCAL } from "configs/utils";
import PersonalSupport from "components/PersonalSupport/PersonalSupport";
import AppPrevention from "components/AppPrevention/AppPrevention";
import CoachingHealthCompanies from "components/CoachingHealthCompanies/CoachingHealthCompanies";

// test coaches :
// 'https://jeff-fci99843.foodiary.app/'
// 'https://ronja-fci49818.foodiary.app'
// 'https://sebastian-fci1.foodiary.app'

const MyCoaching = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    const coach_website_url = IS_LOCAL
      ? "https://ronja-fci49818.foodiary.app"
      : `${window.location.protocol}//${window.location.host}`;

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <StickyNavbar openSurveyModal={() => setOpenLeadModal(!openLeadModal)} />

      <div class="overview-wrapper">
        <G.Container>
          <CoachingWithMe
            openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
          />
        </G.Container>
      </div>

      <G.Container>
        <SectionWrapper margin="5rem 0" mobileMargin="7rem 0 10rem">
          <FeaturesCards />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0" mobileMargin="0rem 0 7rem 0">
          <WhyIamYourCoach
            openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
          />
        </SectionWrapper>
      </G.Container>

      <SectionWrapper margin="15rem 0" mobileMargin="7rem 0">
        <HelpWithSurvey>
          <div className="survey-content">
            <div className="survey-headline">
              <h3 className="font-medium">
                Gemeinsam erreichen wir dein Ziel. Lerne mich jetzt kennen.
              </h3>
            </div>
            <div className="survey-description">
              <p>
                Als dein persönlicher Ernährungsberater begleite ich dich auf
                dem Weg zu deinem Ziel. Damit ich dich bestmöglich unterstützen
                kann, ist es wichtig, deine Wünsche und Bedürfnisse genau zu
                verstehen. Teile sie mir ganz unverbindlich über das
                Kontaktformular mit – und wir finden gemeinsam den passenden Weg
                für dich!
              </p>
            </div>
          </div>
        </HelpWithSurvey>
      </SectionWrapper>

      <G.Container>
        <SectionWrapper mobileMargin="7rem 0 0 0">
          <PersonalSupport
            mediaType="image"
            onLeadFormModal={() => setOpenLeadModal(true)}
            title={"Gemeinsam zu einer nachhaltigen und gesunden Ernährung."}
            description={
              "Ich begleite dich im Alltag und unterstütze dich dabei, eine Ernährung zu finden, die nicht nur gesund, sondern auch nachhaltig in deinen Lebensstil passt. Im Coaching erhältst du eine individuelle Beratung, die auf deine Bedürfnisse abgestimmt ist. Zusätzlich stelle ich dir praktische Tools und Ressourcen zur Verfügung, die deinen Alltag einfacher machen – damit du deine Ziele langfristig erreichst."
            }
            btnText="Mehr zum Coaching"
            callBack={() => history.push("/coaching")}
          />
        </SectionWrapper>
        <SectionWrapper margin="15rem 0" mobileMargin="10rem 0 0">
          <AppPrevention
            changeOrder
            onLeadFormModal={() => setOpenLeadModal(true)}
          />
        </SectionWrapper>
      </G.Container>
      <SectionWrapper margin="15rem 0" mobileMargin="7rem 0">
        <CoachingHealthCompanies />
      </SectionWrapper>
      <G.Container>
        <SectionWrapper mobileMargin="5rem 0 7rem">
          <PersonalSupport
            mediaType="video"
            onLeadFormModal={() => setOpenLeadModal(true)}
            title={"Erfolge dank Wissensvermittlung in meinem Online Kurs."}
            description={
              "Mit der Lernacademy und dem 12-Wochen-Online-Kurs erhältst du fundiertes, wissenschaftlich geprüftes Wissen über die gesunde Ernährung. Du lernst die grundlegenden Prinzipien der Ernährungsplanung sowie wichtige Informationen zu Makro- und Mikronährstoffen. Der Kurs kann flexibel im Selbststudium absolviert werden oder als ideale Ergänzung zu meinem persönlichen Coaching."
            }
            btnText={"Mehr zum Online Kurs"}
            callBack={() => history.push("/online-kurs")}
          />
        </SectionWrapper>
        <SectionWrapper margin="15rem 0" mobileMargin="10rem 0 0 0">
          <TestimonialSlider />
        </SectionWrapper>
      </G.Container>
      {/* <G.Container>

        <SectionWrapper margin="25rem 0" mobileMargin="5rem 0">
          <CoachingAccordian />
        </SectionWrapper>

        <SectionWrapper margin="25rem 0" mobileMargin="5rem 0">
          <ReimburseHealthInsurance onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="5rem 0" mobileMargin="5rem 0">
          <TestimonialSlider />
        </SectionWrapper>

      </G.Container> */}

      <SectionWrapper>
        <InitialConsultationBox
          openLeadFormModal={() => setOpenLeadModal(true)}
        />
      </SectionWrapper>

      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default MyCoaching;
