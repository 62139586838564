import styled from "styled-components";
import { LoginSubHeading } from "pages/login/login.styles";

export const InformationHeading = styled(LoginSubHeading)`
  padding-top: 45px;
`;

export const InformationDescription = styled.div`
  font-size: 16px;
  font-family: "Rubik-Regular";
  color: #44554c;
  margin: 0 0 40px 0;
`;

export const Header = styled.header`
  background: transparent linear-gradient(90deg, #e5f5f2 0%, #edfbf6 100%);
`;

export const HeaderInner = styled.div`
  @media (max-width: 600px) {
    margin-top: -40px;
  }
`;
export const MeinCoaching = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ImageSectionTitle = styled.h4`
  color: #142c1f;
  text-align: center;
  font-family: RubikRegular;
`;
export const ImageSectionText = styled.p`
  color: #142c1f;
  text-align: center;
  margin-top: 5px;
`;
