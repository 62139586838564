import styled from "styled-components";

export const CoachingPlansBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: #ffffff;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 50px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 767.99px) {
    padding: 15px;
  }
`;

export const PlanBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding: 3rem;
  border-radius: 25px;
  display: flex;
  flex-direction: column;

  @media (max-width: 991.99px) {
    padding: 35px;
    button {
      font-size: 14px !important;
    }
  }

  &.active {
    background: #e7faf3;
    h5 {
      color: #2ecf96;
    }
  }
`;

export const PlanBoxHead = styled.div`
  border-bottom: 1px solid #142c1f26;
  padding-bottom: 40px;
  height: 150px;

  @media (max-width: 767.99px) {
    height: auto;
  }
`;

export const TitleInfo = styled.h5`
  /* font-size: 1.25rem;
  font-weight: 400; */
  margin-bottom: 20px;
  color: #7a8780;

  @media (max-width: 767.99px) {
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 5px;
  /* font-size: 2.3rem;
  font-weight: 500; */

  @media (max-width: 992px) {
    font-size: 30px;
  }
`;

export const SubTitle = styled.h4`
  color: #a5b5af;
  line-height: 20px;
  /* font-size: 1rem;
  font-weight: 400; */
`;

export const PlanLowerBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  height: 350px;
  /* overflow: hodden;
  overflow-y: auto; */
  margin: 10px 0px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  @media (max-width: 575px) {
    height: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`;
