import React from "react";
import * as C from "./HelpWithSurvey.style";
import LeadsSurveyForm from "components/LeadsSurvey/LeadsSurveyForm";

const HelpWithSurvey = ({ children }) => {
  return (
    <C.SectionContainer>
      <div>
        {children}
        <div className="form-wrapper">
          <LeadsSurveyForm isInPagePlacement />
        </div>
      </div>
    </C.SectionContainer>
  );
};

export default HelpWithSurvey;
