import React from "react";
import { useSelector } from "react-redux";
import { InlineWidget } from "react-calendly";
import * as C from "./scheduleInitialConsultation.styled";

const ScheduleInitialConsultation = ({ page = "" }) => {
  const coach = useSelector((state) => state.coach.coach);

  return (
    <C.ScheduleInitialConsultationContainer>
      <C.Title className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-medium text-center">
        JETZT DEIN ERSTGESPRÄCH VEREINBAREN
      </C.Title>
      <C.WidgetWrapper>
        {page === "success" && (
          <InlineWidget
            url={`${coach?.cw_coaching_pro_calendly_url}?hide_gdpr_banner=1`}
          />
        )}
        {page !== "success" && (
          <InlineWidget
            url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
          />
        )}
      </C.WidgetWrapper>
    </C.ScheduleInitialConsultationContainer>
  );
};

export default ScheduleInitialConsultation;
