import React from "react";
import * as C from "./firstConsultation.styled";
import Chip from "components/General/formComponents/chip/Chip";
// Images
import FoodiaryErstgesprach from "assets/images/Coach-Rockedieernaehrung.jpg";

const FirstConsultation = () => {
  return (
    <C.CoachingAccordianContainer>
      <C.CoachingAccordianRight>
        <img src={FoodiaryErstgesprach} alt="consultation" />
      </C.CoachingAccordianRight>
      <C.CoachingAccordianLeft>
        <Chip text="ERSTGESPRÄCH" margin="0 0 20px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Ist das Erstgespräch wirklich kostenlos?
        </C.Title>
        <C.Info className="ct-text-block color-paragraph text-base text-left">
          Ja! Um zu klären, wie ich dich bei deinem Ziel unterstützen kann und
          ob eine Zusammenarbeit sinnvoll ist, lade ich dich zu einem
          kostenlosen Erstgespräch ein. In diesem Gespräch, das auf 30 bis 40
          Minuten angelegt ist, führen wir gemeinsam eine IST-Analyse durch und
          erstellen deinen individuellen Ernährungsplan. So kannst du direkt im
          Anschluss starten und deine Ernährungsumstellung starten.
        </C.Info>
      </C.CoachingAccordianLeft>
    </C.CoachingAccordianContainer>
  );
};

export default FirstConsultation;
