import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "antd";
import * as Styles from "./PaymentStyles";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentElementLoadError, setPaymentElementLoadError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleProcessPayment = async () => {
    if (!stripe || !elements) return;
    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/onboarding/set-password`,
      },
    });

    setIsProcessing(false);

    if (error) {
      return setErrorMessage(error.message || "An unexpected error occured");
    }

    console.log("payment completed !");
  };

  return (
    <Styles.CheckoutFormContainer>
      {errorMessage ? <div className="error-message">{errorMessage}</div> : ""}
      {paymentElementLoadError ? (
        <div className="error-message">{paymentElementLoadError}</div>
      ) : (
        ""
      )}

      <PaymentElement
        options={{ loader: "always" }}
        onLoadError={(error) => setPaymentElementLoadError(error.error.message)}
      />

      <Button
        size="large"
        type="primary"
        block
        onClick={handleProcessPayment}
        disabled={
          isProcessing || !stripe || !elements || paymentElementLoadError
        }
        loading={isProcessing}
      >
        {isProcessing ? "Verarbeitung..." : "Zahlen"}
      </Button>
    </Styles.CheckoutFormContainer>
  );
}
