export default [
  {
    title: 'Anamnese',
    description: 'Wir erstellen deinen Ernährungsplan.'
  },
  {
    title: 'Auswertung',
    description: 'Du lernst deinen Ernährungsplan kennen.'
  },
  {
    title: 'Start',
    description: 'Du startest mit unserer Ernährungsplaner-App.'
  }
]
