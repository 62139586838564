import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as C from "./Personality.styled";
import * as G from "styles/General.Styles";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import Coach from "assets/images/Page3/Foodiary-Coaching-start.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CentralShape from "assets/images/pngs/central-shape.png";
import ScollDown from "assets/images/scroll-down.svg";

const Personality = () => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_fm_picture_2
    ? getS3ImageUrl(coach?.cw_fm_picture_2)
    : Coach;

  return (
    <C.PersonalityContainer>
      <G.ScrollDownImagewrap>
        <G.ScrollDownImage src={ScollDown} />
      </G.ScrollDownImagewrap>
      <C.Wrap>
        <C.VisualContent>
          {coach?.cw_fm_picture_2 && (
            <C.Image maskImage={CentralShape}>
              <img
                src={getS3ImageUrl(coach?.cw_fm_picture_2)}
                alt="Foodiary-Coaching-Bild2"
              />
            </C.Image>
          )}
          {!coach?.cw_fm_picture_2 && (
            <C.DefaultImage>
              <img src={imgUrl} alt="Foodiary-Coaching-Bild" />
            </C.DefaultImage>
          )}
        </C.VisualContent>
        <C.TextContent>
          <Chip text="Persönlich" margin="0 0 20px 0" />
          <C.Title className="ct-headline color-dark font-medium">
            Individuelle Betreuung statt Standard-Programm.
          </C.Title>

          <p className="ct-text-block color-paragraph text-base text-left">
            Mein persönliches Coaching ist völlig individuell und kein
            vorgefertigtes Programm. Du erhältst einen maßgeschneiderten
            Ernährungsplan mit über 1700 leckeren Rezepten und kannst dich
            während den nächsten 3 Monate auf mich als deinen festen
            Ansprechpartner verlassen – für eine nachhaltige und erfolgreiche
            Ernährungsumstellung.
          </p>

          <C.PlanBoxIcons className="">
            <li>
              <C.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </C.PlanBoxIconBox>
              <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
                Ernährungsplaner-App mit 1700 Rezepten
              </C.PlanBoxInformation>
            </li>
            <li>
              <C.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </C.PlanBoxIconBox>
              <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
                Digitale Lernacademy mit Online Kurs
              </C.PlanBoxInformation>
            </li>
            <li>
              <C.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </C.PlanBoxIconBox>
              <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
                Meine persönliche Unterstützung auf deinem Weg
              </C.PlanBoxInformation>
            </li>
          </C.PlanBoxIcons>
        </C.TextContent>
      </C.Wrap>
    </C.PersonalityContainer>
  );
};

export default Personality;
