import axios from "axios"

const BASE_URL = 'https://app.foodiary.app/api'
const CREATE_CUSTOMER = `${BASE_URL}/v1/stripe/customer/create`
const CREATE_SUBSCRIPTION = `${BASE_URL}/v1/stripe/subscription/create`

export {
  createStripeCustomer,
  createStripeSubscription
}

async function createStripeCustomer (payload) {
  try {
    console.log('creating customer with : ', payload)
    const response = await axios.post( CREATE_CUSTOMER, payload )
    if (!response?.data?.data || !response.status === 201) {
      throw new Error(response.error || 'Error accured whilst creating customer')
    }

    console.log('createStripeCustomer response : ', response)
    return response?.data?.data
  } catch (err) {
    console.error('Error creating stripe customer : ', err)
    return { error: err }
  }
}

async function createStripeSubscription (payload) {
  try {
    console.log('creating subscription with : ', payload)
    const response = await axios.post( CREATE_SUBSCRIPTION, payload )
    if (!response.data || !response.status === 201) {
      throw new Error(response.error || 'Error accured whilst creating subscription')
    }

    console.log('createStripeSubscription response : ', response)
    return response.data
  } catch (err) {
    console.error('Error creating stripe subscription : ', err)
    return { error: err }
  }
}
