import { Spin } from "antd"

function OnboardingLoader () {
  return (
    <div className="loader-container">
      <Spin size="large" />
    </div>
  )
}

export default OnboardingLoader
