import styled from "styled-components";

export const CoachingGlanceContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding-inline: 50px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    padding-inline: 0px;
  }
`;

export const CoachingGlanceLeft = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding-top: 30px;
`;

export const GlanceTitle = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-weight: 500;
  font-size: 2.3rem; */
  margin: 0 0 20px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`;

export const GlanceText = styled.p`
  margin-bottom: 30px;

  > * {
    color: #44554c;
  }

  p:last-child {
    color: #a5b5af;
  }
`;

export const GlanceTags = styled.div`
  display: flex;
  margin-top: 30px;
  img {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    img {
      width: 150px;
    }
  }
`;

export const PlansBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const OnePlan = styled.div`
  max-width: 450px;
  /* margin-inline: auto; */
`;

export const CoachingPlansBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 50px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;

export const PlanBox = styled.div`
  padding: 40px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;

  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 991.99px) {
    padding: 25px;
  }

  &.active {
    background: #e7faf3;
    h5 {
      color: #2ecf96;
    }
  }
`;

export const PlanBoxHead = styled.div`
  border-bottom: 1px solid #142c1f26;
  padding-bottom: 40px;
`;

export const TitleInfo = styled.h5`
  /* font-size: 1.1rem; */
  margin-bottom: 20px;
  color: #7a8780;
  /* font-weight: 300; */
`;
export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 5px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`;
export const SubTitle = styled.h4`
  color: #a5b5af;
  /* font-size: 1rem;
  font-weight: 300; */
`;

export const PlanLowerBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  overflow: hidden;
  overflow-y: auto;
  margin: 10px 0px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  @media (max-width: 575px) {
    height: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`;
