import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./LearnIn12Weeks.style";
import videoSource from "assets/videos/Coach-Animated-Video-Course.mp4";

import ReactPlayer from "react-player";

const LearnIn12Weeks = ({
  changeOrder = false,
  firsTitle,
  secondTitle,
  description,
  // videoSource,
  callBack,
  btnText,
}) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <div className="text-content">
        <Chip text="Online-Kurs" margin="0 0 20px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          {firsTitle}

          <br />
          {secondTitle}
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          {description}
        </C.Description>

        <C.Action>
          <button onClick={callBack} className="btn-dark">
            {btnText}
          </button>
        </C.Action>
      </div>
      <C.VisualContent>
        {/* <img src={LearnIn12WeeksBanner} alt="" /> */}
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default LearnIn12Weeks;
