import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as C from "./TogetherWithMe.styled";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import Coach from "assets/images/Page3/Foodiary-Coaching-start.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CentralShape from "assets/images/pngs/central-shape.png";

const TogetherWithMe = ({ onLeadFormModal }) => {
  const coach = useSelector((state) => state.coach.coach);
  const coachPreviewSource = getS3ImageUrl(coach?.cw_cp_picture_2);

  return (
    <C.Wrap>
      <C.VisualContent>
        {coachPreviewSource ? (
          <C.Image maskImage={CentralShape}>
            <img
              src={getS3ImageUrl(coach?.cw_cp_picture_2)}
              alt="Foodiary-Coaching-Bild2"
            />
          </C.Image>
        ) : (
          <img src={Coach} alt="" />
        )}
      </C.VisualContent>

      <C.TextContent>
        <Chip text="COACHING PRO" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Ich führe dich zu einem gesünderen Leben.
        </C.Title>

        <p className="ct-text-block color-paragraph text-base text-left mt-6">
          Mit dem Coaching PRO unterstütze ich dich dabei innerhalb der nächsten
          3 Monate dein persönliches Ziel zu erreichen. Gemeinsam mit dir
          entwickle ich eine Strategie und einen Ernährungsplan, um deine
          Ernährung dauerhaft umzustellen. Als Foodiary Coach betreue ich dich
          persönlich dabei dauerhaft und stehe bei Fragen an deiner Seite.
        </p>

        <div className="cta-overview">
          <button onClick={onLeadFormModal} className="btn-dark mt-5">
            Erstgespräch vereinbaren
          </button>
        </div>
      </C.TextContent>
    </C.Wrap>
  );
};

export default TogetherWithMe;
