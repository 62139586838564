import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import {
  getPreviousOnboardingPage,
  getNextOnboardingPage
} from '../../../routes/onboardingRoutes'
import * as Styles from './DailyRoutineStyles'
import { Button, Modal, Input, TimePicker } from 'antd'
import PlusIcon from 'assets/images/onboarding/icon-plus.svg'
import TrashIcon from 'assets/images/onboarding/trash-icon.svg'
import ArrowDownIcon from 'assets/images/onboarding/arrow-down.svg'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'
import {
  getOnboardingStorage,
  setOnboardingStorage,
  DAILY_ROUTINE_STEP
} from '../storage'

function getTimeFromString(timeString) {
  if (!timeString || !timeString.length) timeString = '00:00'

  const [ hours, minutes ] = timeString.split(':').map(Number)
  const now = new Date()
  now.setHours(hours, minutes, 0, 0)
  return now
}

function DailyRoutine ({ currentPath }) {
  const storage = getOnboardingStorage(DAILY_ROUTINE_STEP)
  const history = useHistory()
  const [ newRoutineModal, setNewRoutineModal ] = useState({})
  const [ validationError, setValidationError ] = useState()

  const defaultRoutineSchedule = [
    { label: 'Aufstehen', name: 'wakeup', value: '07:00', dayroutineID: 1 },
    { label: 'Mittagessenszeit', name: 'lunch', value: '12:00', dayroutineID: 2 },
    { label: 'Trainingszeit', name: 'workout', value: '17:00', dayroutineID: 3 },
    { label: 'Schlafenszeit', name: 'sleep', value: '22:00', dayroutineID: 4 },
  ]

  const [ routines, setRoutines ] = useState(storage.routines || [])

  const openNewRoutineModal = () => (
    setNewRoutineModal({
      show: true,
      name: '',
      schedule: defaultRoutineSchedule
    })
  )

  const closeNewRoutineModal = () => (
    setNewRoutineModal({
      show: false,
      name: '',
      schedule: defaultRoutineSchedule
    })
  )

  const editroutine = routine => (
    setRoutines(prevRoutines => prevRoutines.map(prevRoutine => {
      if (prevRoutine.id === routine.id) return ({
        ...prevRoutine,
        showEdit: !prevRoutine.showEdit
      })
      return { ...prevRoutine, showEdit: false }
    }))
  )

  const removeRoutine = id => setRoutines([
    ...routines.filter(
      routine => routine.id !== id
    )
  ])

  const handleScheduleChange = (scheduleName, newTime) => (
    setNewRoutineModal(prevRoutine => {
      const updatedSchedule = prevRoutine.schedule.map((item) => {
          if (item.name === scheduleName) return { ...item, value: newTime }
          return item
      })
      return { ...prevRoutine, schedule: updatedSchedule }
    })
  )

  const handleScheduleChangeEdit = (scheduleName, newTime, originalRoutine) => {
    setRoutines(prevRoutines => prevRoutines.map(previousRoutine => {
      if (previousRoutine.id === originalRoutine.id) {
        const updatedSchedule = previousRoutine.schedule.map((item) => {
          if (item.name === scheduleName) return { ...item, value: newTime }
          return item
        })
        return { ...previousRoutine, schedule: updatedSchedule }
      }
      return previousRoutine
    }))
  }

  const saveCurrentRoutine = () => {
    setRoutines([
      ...routines,
      {
        id: uuid(),
        name: newRoutineModal.name,
        schedule: newRoutineModal.schedule,
        showEdit: false
      }
    ])

    closeNewRoutineModal()
  }

  const routinesList = !routines.length
    ? ''
    : (<div className='routines-container'>
        {routines.map(routine => (
          <div className="routine-container">
            <div className='main-content'>
              <div className="content">
                <span className='name'>{routine.name}</span>
                <span>Tagesroutine</span>
              </div>
              <div className="controls">
                <span onClick={() => removeRoutine(routine.id)}><img src={TrashIcon} alt="" /></span>
                <span onClick={() => editroutine(routine)}>
                  <img src={ArrowDownIcon} alt="" />
                </span>
              </div>
            </div>
            {routine.showEdit ? (
              <div className="edit-routine">
                <Styles.NewRoutineModal>
                  <div className="schedule-container">
                    {routine.schedule.map(schedule => (
                      <div className="schedule">
                        <div>{schedule.label}</div>
                        <div>
                          <TimePicker
                            changeOnScroll={false}
                            value={dayjs(getTimeFromString(schedule.value))}
                            format={"HH:mm"}
                            onChange={(_, timeString) => handleScheduleChangeEdit(schedule.name, timeString, routine)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Styles.NewRoutineModal>
              </div>
            ) : ''}
          </div>
        ))}
      </div>)

  const newRoutineModalContent =
    newRoutineModal.show
    ? (
      <Modal
        title="Dein Tagesablauf"
        centered
        open={newRoutineModal.show}
        onCancel={closeNewRoutineModal}
        footer={null}
      >
        <Styles.NewRoutineModal>
          <div className='input-container'>
            <div className='label'>Name des Tagesablaufes:</div>
            <Input
              placeholder="Arbeitstag"
              value={newRoutineModal.name}
              onChange={(event) => setNewRoutineModal({
                ...newRoutineModal,
                name: event.target.value
              })}
            />
          </div>
          <div className="schedule-container">
            {newRoutineModal.schedule.map(schedule => (
              <div className="schedule">
                <div>{schedule.label}</div>
                <div>
                  <TimePicker
                    changeOnScroll={false}
                    value={dayjs(getTimeFromString(schedule.value))}
                    format={"HH:mm"}
                    onChange={(_, timeString) => handleScheduleChange(schedule.name, timeString)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="action">
            <Button
              disabled={!newRoutineModal.name}
              onClick={saveCurrentRoutine}>Tagesablauf hinzufügen</Button>
          </div>
        </Styles.NewRoutineModal>
      </Modal>
    )
    : ''
  
  useEffect(() => {
    if (routines.length) setValidationError(null)
  }, [ routines ])
  
  const handleNextPage = () => {
    console.log('routines : ', routines)

    if (!routines.length) {
      return setValidationError('Bitte füge mindestens eine Tagesroutine hinzu')
    }

    setOnboardingStorage(
      DAILY_ROUTINE_STEP,
      { routines: routines.map(routine => ({ ...routine, showEdit: false })) }
    )

    history.push(getNextOnboardingPage(currentPath))
  }

  return (
    <>
      <div>
        <div className="step-header">
          <div className='header-content'>
            <Link to={getPreviousOnboardingPage(currentPath)}>
              <img src={backIcon} alt="back icon" />
            </Link>
            <div>
              <div className="headline">Anamnese</div>
              <div className="sub-headline">Wir erstellen deinen Plan.</div>
            </div>
          </div>
          <div className="actions">
            <span onClick={handleNextPage} className="main-button">Weiter</span>
          </div>
        </div>
        <div className="step-content">
          <Styles.MainContainer>
            <div className='section-titles-wrapper'>
              <div className="primary-title">Dein Plan passt sich deinem Alltag an.</div>
              <div className="accent-subtitle">Du kannst jederzeit in der App weitere Tagesabläufe anlegen und der Wochenroutine hinzufügen.</div>
            </div>
            <Styles.DailyRoutineContainer>
              {newRoutineModalContent}
              <div className="heading-section">
                <div className="small-subtitle">Lege deine Tagesabläufe fest</div>
                <div>
                  <Button
                    onClick={openNewRoutineModal}
                    icon={<img src={PlusIcon} />}
                  >
                    Neue Routine
                  </Button>
                </div>
              </div>

              <div className="error-message">{validationError}</div>

              {routinesList}

            </Styles.DailyRoutineContainer>
          </Styles.MainContainer>
        </div>
      </div>
    </>
  )
}

export default DailyRoutine
