import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 0.9fr;
  gap: 60px;
  align-items: center;
  padding-top: 170px;
  padding-bottom: 100px;
  position: relative;

  .scroll-down-img {
    bottom: -80px;
  }

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 150px 0px 7rem;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }

    @media (max-width: 991.99px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  color: #44554c;
`;

export const VisualContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
`;

export const Action = styled.div`
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;

export const List = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > * {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: #142c1f;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
      }
    }
  }
`;
