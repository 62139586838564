import React from "react";
import * as P from "./Personality.style";
import LernacademHalf from "assets/images/Coach-Academy-D.jpg";
import AppHalf from "assets/images/Coach-App.jpg";
import FileText from "assets/images/file-text.svg";
import Icon_1 from "assets/images/Icon-App.svg";
import Icon_2 from "assets/images/Coach-Lernen.svg";
import Icon_3 from "assets/images/Coach-Talk.svg";
import CoachCoaching from "assets/images/Coach-Coaching.jpg";

import Chip from "components/General/formComponents/chip/Chip";

const PersonalityCards = () => {
  const Cards = [
    {
      image: AppHalf,
      title: "Ernährungsplaner App",
    },
    {
      image: LernacademHalf,
      title: "Lernacademy",
    },
    {
      image: CoachCoaching,
      title: "Mein Coaching",
    },
  ];

  const CoachingPlus = [
    {
      title: "Digitale Betreuung",
      description:
        "Nach deinem Erstgespräch hast du Zugang zur Lernacademy, die einen Online Kurs im Selbstlernprogramm umfasst.",
    },
    {
      title: "Gruppencalls",
      description:
        "In den Gruppencalls wirst du regelmäßig von unserer Kursleitung und erfahrenen Ernährungsberatern betreut und unterstützt.",
    },
  ];
  const CoachingPro = [
    {
      title: "Persönliche Betreuung",
      description:
        "Als dein Foodiary Coach begleite ich dich langfristig bei deiner Ernährungsumstellung.",
    },
    {
      title: "Zielsetzung",
      description:
        "Mir mir als Coach legst du Etappenziele fest und verfolgst deinen Fortschritt, um motiviert an deinen Zielen zu arbeiten.",
    },
    {
      title: "Erfolgsüberprüfung",
      description:
        "In regelmäßigen Abständen wird dein Fortschritt überprüft und wir sprechen über deine Erfolge und Veränderungen.",
    },
    {
      title: "Messenger und Call Support",
      description:
        "Als dein Coach bin ich jederzeit für 1:1-Gespräche sowie Unterstützung über Messenger und Video-Calls verfügbar.",
    },
  ];

  return (
    <P.Wrap>
      <P.CardsWrap>
        {Cards.map((item, i) => (
          <P.CardContainer key={i}>
            <P.Card>
              <P.CircleEclipse>
                <img
                  src={i == 0 ? Icon_1 : i == 1 ? Icon_2 : Icon_3}
                  alt="file-text"
                  fill="white"
                  width={60}
                  height={60}
                />
              </P.CircleEclipse>
              <h5>{item?.title}</h5>
              <img src={item?.image} alt="pro" />
            </P.Card>
          </P.CardContainer>
        ))}
      </P.CardsWrap>
      <P.PlusIconWrapper className="plus-icon-wrapper">
        <P.PlusIcon />
      </P.PlusIconWrapper>
      <P.CardsSegment>
        <P.CardSegmentMobileWrapper>
          <P.CardSegment bgColor="#edfbf6 ">
            <h5>Coaching PLUS</h5>
            <h6>3 Monate</h6>
            <div className="pt-8 pb-20 card-wrapper">
              {CoachingPlus.map((item, i) => (
                <P.CardSegmentTile key={i}>
                  <p className="title text-medium">{item.title}</p>
                  <p className="desc">{item.description}</p>
                </P.CardSegmentTile>
              ))}
            </div>
          </P.CardSegment>
        </P.CardSegmentMobileWrapper>
        <P.CardSegmentMobileWrapper>
          <P.CardSegment bgColor="#F5F5F5">
            <h5>Coaching PRO</h5>
            <h6>3 Monate</h6>
            <div className="pt-8 card-wrapper">
              {CoachingPro.map((item, i) => (
                <P.CardSegmentTile key={i}>
                  <p className="title text-medium">{item.title}</p>
                  <p className="desc">{item.description}</p>
                </P.CardSegmentTile>
              ))}
            </div>
          </P.CardSegment>
        </P.CardSegmentMobileWrapper>
      </P.CardsSegment>
    </P.Wrap>
  );
};

export default PersonalityCards;
