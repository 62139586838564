import styled from "styled-components";

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
`;

export const Wrap = styled.div`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 991.99px) {
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    h3 {
      text-align: left;
    }
  }
`;

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    margin-inline: auto;
    order: 1;
  }
`;

export const Image = styled.div`
  position: relative;
  height: 100%;

  img {
    -webkit-mask: url(${(props) => props.maskImage}) center/contain no-repeat;
    max-width: 100%;
  }
`;

export const TextContent = styled.div`
  img {
    width: 100%;
  }

  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const PlanBoxIcons = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`;

export const PlanBoxIconBox = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const PlanBoxInformation = styled.span`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  width: calc(100% - 40px);
  padding-left: 20px;

  &.ProcedureTop {
    /* font-size: 1.15rem !important; */
    padding-top: 10px;
  }
  &.StandatProgramText {
    /* font-size: 1.15rem !important; */
  }
`;
