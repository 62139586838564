import styled from "styled-components";

export const CoachOverviewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 60px;
  padding-top: 170px;
  padding-bottom: 100px;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-top: 140px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 600px) {
    padding: 0px;
    padding: 150px 0px 7rem;
  }

  .overview-image {
    display: flex;
    justify-content: flex-end;

    .overview-image-wrapper {
      background: white;
      padding: 10px;
      border-radius: 15px;
      height: auto; /* Let content drive height for better flexibility */
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 600px) {
        padding: 8px;
        height: auto;
        img {
          width: 100% !important;
          height: auto !important;
        }
      }

      img {
        display: block; /* Avoid inline spacing issues */
        height: auto; /* Preserve aspect ratio */
        border-radius: inherit; /* Match parent border-radius for consistent look */
        object-fit: cover; /* Ensure image covers the entire area without distortion */
        width: 470px; /* Use max-width instead of fixed width */
        height: 580px;
      }
    }
  }

  .overview-content {
    display: flex;
    flex-direction: column;
    color: black;

    @media screen and (max-width: 600px) {
      .overview-cta {
        display: flex;
        justify-content: flex-start;

        button {
          font-size: 16px;
        }
      }

      h1 {
        font-size: 30px;
      }
    }

    > * {
      margin-bottom: 20px;
    }

    .overview-description-small {
      color: #a5b5af;
    }
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 35px;
`;
