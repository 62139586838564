import styled from "styled-components";

export const CoachingGlanceContainer = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 2fr;
  gap: 100px;

  @media (max-width: 1300px) {
    gap: 70px;
  }

  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
    gap: 50px;
    word-break: break-word;
  }
`;

export const CoachingGlanceLeft = styled.div`
  .stamp {
    display: block;
    margin-inline: auto;

    @media screen and (max-width: 1200px) {
      margin-inline: 0;
      margin-left: 20px;
    }

    @media screen and (max-width: 600px) {
      width: 120px;
    }
  }
`;

export const GlanceTitle = styled.h3`
  color: #142c1f;
  margin-bottom: 20px;
  /* font-weight: 500;
  font-size: 2.2rem;
  margin: 0 0 40px; */
`;

export const GlanceText = styled.p`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
`;

export const GlanceTags = styled.div`
  display: flex;
  margin-top: 20px;

  img {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    img {
      width: 130px;
      height: 130px;
      &:first-child {
        width: 150px;
        height: 150px;
      }
    }
  }
`;
