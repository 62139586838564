import React from "react";
import * as F from "./coachingPlans.styled";
import * as G from "styles/General.Styles";

// Images
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import PlusIcon from "assets/images/plus-icon-plans.svg";

const CoachingPlans = ({ onPaymentModal, onLeadFormModal }) => {
  return (
    <F.CoachingPlansBox className="plansBox">
      <F.PlanBox className="nonActive">
        <F.PlanBoxHead>
          <F.TitleInfo className="ct-text-block uppercase text-base font-normal">
            COACHING PLUS
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            50,00 €
          </F.Title>
          <F.SubTitle className="ct-text-block text-base font-normal">
            Pro Monat - 3 Monate Laufzeit
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Unverbindliches Erstgespräch
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Ernährungsplaner-App mit 1700 Rezepten und smarten Funktionen
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Lernacademy mit Online-Kurs, Handouts und Wissensquiz.
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Wöchentliche Gruppencalls mit Ernährungsberater
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>

        <button onClick={onLeadFormModal} className="btn-dark btn-small">
          Erstgespräch vereinbaren
        </button>
      </F.PlanBox>

      <F.PlanBox className="active">
        <F.PlanBoxHead>
          <F.TitleInfo className="ct-text-block uppercase text-base font-normal active">
            COACHING PRO
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            150,00 €
          </F.Title>
          <F.SubTitle className="ct-text-block text-base font-normal">
            Pro Monat - 3 Monate Laufzeit
          </F.SubTitle>
          <F.SubTitle className="ct-text-block text-base font-normal">
            Alle Coaching PLUS Leistungen zudem:
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={PlusIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Persönlicher Ernährungsberater
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={PlusIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Foodiary App mit Coaching PRO Funktionen
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={PlusIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Fortschrittsgespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={PlusIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Bis zu 100% Kostenübernahme durch deine Krankenkasse
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>

        <button onClick={onLeadFormModal} className="btn-dark btn-small">
          Erstgespräch vereinbaren
        </button>
      </F.PlanBox>
    </F.CoachingPlansBox>
  );
};

export default CoachingPlans;
