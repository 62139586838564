import React from "react";
import * as C from "./sectionWrapper.styles";

const SectionWrapper = (props) => {
  return (
    <C.Wrapper
      width={props.width}
      height={props.height}
      margin={props.margin}
      mobileMargin={props.mobileMargin}
      padding={props.padding}
      mobilePadding={props.mobilePadding}
      style={props.styles}
      {...props}
    >
      {props.children}
    </C.Wrapper>
  );
};

export default SectionWrapper;
