import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 55%;
    margin: auto;
  }
`

export const CheckoutFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const PricingBoxContainer = styled.div`
  margin-bottom: 20px;
  border: 1px solid #E2E4E9;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;

  .content-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    span {
      width: 100%;
    }
    
    span:nth-child(1) {
      background: #F5F5F5;
      text-align: center;
      padding: 7px 15px;
      border-radius: 5px;
      font-weight: bold;
    }
    
    span:nth-child(2) {
      font-size: 14px;
    }
  }

  .content-preview {
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
      font-size: 14px;
    }

    .subheading {
      color: #A5B5AF;
      font-size: 14px;
    }

    .box {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background: #142C1F;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .headline {
    font-weight: bold;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #DDDDDD;

    > *:nth-child(1) {
      font-weight: bold;
    }

    > *:nth-child(2) {
      display: flex;
      flex-direction: column;
      color: #A5B5AF;

      > span:nth-child(1) {
        font-weight: bold;
        font-size: 18px;
        color: #1C9D87;
      }
    }
  }
  
  @media (min-width: 1000px) {
    .content-preview {
      align-items: center;
      flex-direction: row;
    }
  }
`
