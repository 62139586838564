import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IS_LOCAL } from "configs/utils";
import HelpWithSurvey from "components/HelpWithSurvey/HelpWithSurvey";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FreeInitialConsultationWithSurvey from "components/FreeInitialConsultationWithSurvey/FreeInitialConsultationWithSurvey";
import FooterNew from "components/FooterNew/FooterNew";
import NewAttitude from "components/NewAttitude/NewAttitude";
import NutritionPlan from "components/NutritionPlan/NutritionPlan";
import RightCoach from "components/RightCoach/RightCoach";
import FirstConsultation from "components/FirstConsultation/FirstConsultation";
import ScheduleInitialConsultation from "components/ScheduleInitialConsultation/ScheduleInitialConsultation";
import NextSteps from "components/NextSteps/NextSteps";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
// Styles
import * as C from "./initialConsultation.styles";
import * as G from "styles/General.Styles";
// Redux
import * as CoachActions from "redux/coach/coach.actions";
import CoachingWithMe from "components/CoachingWithMe/CoachingWithMe";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import hook from "assets/images/hook.svg";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import ScollDown from "assets/images/scroll-down.svg";

const InitialConsultation = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);

  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (coach) return;

    const coach_website_url = IS_LOCAL
      ? "https://ronja-fci49818.foodiary.app"
      : `${window.location.protocol}//${window.location.host}`;

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: (res) => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar
              openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
            />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip className="font-medium">ERSTGESPRÄCH</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center text-5xl">
                  Lerne mich im <br /> Erstgespräch kennen
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  In einem 40-minütigen Erstgespräch lernen wir uns kennen, und
                  ich <br /> erstelle dir kostenlos deinen persönlichen
                  Ernährungsplan.
                </C.HeaderDescription>
              </C.HeaderContent>
              <G.ScrollDownImagewrap>
                <G.ScrollDownImage
                  className="position-change"
                  src={ScollDown}
                />
              </G.ScrollDownImagewrap>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>

      <G.Container>
        <SectionWrapper margin="15rem 0 0rem" mobileMargin="7rem 0 0">
          <NewAttitude />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <NextSteps onLeadFormModal={() => setOpenLeadModal(!openLeadModal)} />
        </SectionWrapper>
      </G.Container>

      <SectionWrapper margin="15rem 0" mobileMargin="7rem 0">
        <HelpWithSurvey>
          <div className="survey-content">
            <div className="survey-headline">
              <h3 className="ct-headline  color-dark font-medium">
                Gemeinsam erreichen wir dein Ziel. Lerne mich jetzt kennen.
              </h3>
            </div>
            <div className="survey-description">
              <p>
                Als dein persönlicher Ernährungsberater begleite ich dich auf
                dem Weg zu deinem Ziel. Damit ich dich bestmöglich unterstützen
                kann, ist es wichtig, deine Wünsche und Bedürfnisse genau zu
                verstehen. Teile sie mir ganz unverbindlich über das
                Kontaktformular mit – und wir finden gemeinsam den passenden Weg
                für dich!
              </p>
            </div>
          </div>
        </HelpWithSurvey>
      </SectionWrapper>

      <G.Container>
        <SectionWrapper margin="15rem 0 0" mobileMargin="7rem 0 0">
          <FirstConsultation />
        </SectionWrapper>

        {/* <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <FreeInitialConsultationWithSurvey />
        </SectionWrapper> */}

        {/* <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <RightCoach />
        </SectionWrapper> */}

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <TestimonialSlider />
        </SectionWrapper>
      </G.Container>

      <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
        <InitialConsultationBox
          openLeadFormModal={() => setOpenLeadModal(true)}
        />
      </SectionWrapper>

      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default InitialConsultation;
