import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 40%;
    margin: auto;
  }
`

export const DailyRoutineContainer = styled.div`
  .heading-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .routines-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .routine-container {
      padding: 10px;
      box-shadow: 0 1px 2px rgba(16,24,40,.0509803922);
      border: 1px solid #d0d5dd;
      border-radius: 10px;

      .main-content {
        display: flex;
        justify-content: space-between;

        > * {
          display: flex;
          flex-direction: column;
        }
      }

      .edit-routine {
        border-top: 1px solid lightgrey;
        margin-top: 20px;
        padding-top: 10px;
      }

      .name {
        font-weight: bold;
      }
      
      .controls {
        gap: 10px;

        span {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #E2E4E9;
          cursor: pointer;
          transition: all 200ms ease-in-out;

          &:hover {
            transform: scale(.9);
          }

          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  
  @media (min-width: 1000px) {
    .heading-section {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0px;
    }
    
    .routine-container {
      padding: 20px !important;

      .content {
        .name {
          font-size: 20px;
        }
      }
      .controls {
        flex-direction: row;
      }
    }
  }
`

export const NewRoutineModal = styled.div`
  .input-container {
    margin-bottom: 20px;

    .label {
      margin-bottom: 10px;
    }
    
    input {
      padding: 10px;
    }
  }
  
  .schedule-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .schedule {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;
    }
  }
  
  .action {
    margin-top: 10px;
    text-align: center;
  }
`
