import NutritionActionTypes  from './nutrition.types';

  // Get Nutrition Type
  export const getUserDataRequest = () => ({
    type: NutritionActionTypes.GET_USER_DATA_REQUEST,
  });

  export const getUserDataSuccess = payload => ({
    type: NutritionActionTypes.GET_USER_DATA_SUCCESS,
    payload: payload
  });

  export const getUserDataFailure = error => ({
    type: NutritionActionTypes.GET_USER_DATA_FAILURE,
    payload: error
  });

  // Get Nutrition Type
  export const getNutritionTypeRequest = payload => ({
    type: NutritionActionTypes.GET_NUTRITION_TYPE_REQUEST,
    payload: payload
  });
  
  export const getNutritionTypeSuccess = response => {
    return ({
    type: NutritionActionTypes.GET_NUTRITION_TYPE_SUCCESS,
    payload: response
  })};
  
  export const getNutritionTypeFailure = error => ({
    type: NutritionActionTypes.GET_NUTRITION_TYPE_FAILURE,
    payload: error
  });

  // Get All Nutrition Types
  export const getAllNutritionTypesRequest = payload => ({
    type: NutritionActionTypes.GET_ALL_NUTRITION_TYPES_REQUEST,
    payload: payload
  });
  
  export const getAllNutritionTypesSuccess = response => {
    return ({
    type: NutritionActionTypes.GET_ALL_NUTRITION_TYPES_SUCCESS,
    payload: response
  })};
  
  export const getAllNutritionTypesFailure = error => ({
    type: NutritionActionTypes.GET_ALL_NUTRITION_TYPES_FAILURE,
    payload: error
  });

  // Update Nutrition Type
  export const updateNutritionTypeRequest = payload => ({
    type: NutritionActionTypes.UPDATE_NUTRITION_TYPE_REQUEST,
    payload: payload
  });
  
  export const updateNutritionTypeSuccess = response => {
    return ({
    type: NutritionActionTypes.UPDATE_NUTRITION_TYPE_SUCCESS,
    payload: response
  })};
  
  export const updateNutritionTypeFailure = error => ({
    type: NutritionActionTypes.UPDATE_NUTRITION_TYPE_FAILURE,
    payload: error
  });

  // Update Nutrition Type
  export const updateNutritionCaloriesRequest = payload => ({
    type: NutritionActionTypes.UPDATE_NUTRITION_CALORIES_REQUEST,
    payload: payload
  });
  
  export const updateNutritionCaloriesSuccess = response => {
    return ({
    type: NutritionActionTypes.UPDATE_NUTRITION_CALORIES_SUCCESS,
    payload: response
  })};
  
  export const updateNutritionCaloriesFailure = error => ({
    type: NutritionActionTypes.UPDATE_NUTRITION_CALORIES_FAILURE,
    payload: error
  });

  // Get Nutrition Advantages
  export const getNutritionAdvantagesRequest = payload => ({
    type: NutritionActionTypes.GET_NUTRITION_ADVANTAGES_REQUEST,
    payload: payload
  });
  
  export const getNutritionAdvantagesSuccess = response => {
    return ({
    type: NutritionActionTypes.GET_NUTRITION_ADVANTAGES_SUCCESS,
    payload: response
  })};
  
  export const getNutritionAdvantagesFailure = error => ({
    type: NutritionActionTypes.GET_NUTRITION_ADVANTAGES_FAILURE,
    payload: error
  });

  // Get Nutrition Recipes
  export const getNutritionRecipesRequest = payload => ({
    type: NutritionActionTypes.GET_NUTRITION_RECIPES_REQUEST,
    payload: payload
  });
  
  export const getNutritionRecipesSuccess = response => {
    return ({
    type: NutritionActionTypes.GET_NUTRITION_RECIPES_SUCCESS,
    payload: response
  })};
  
  export const getNutritionRecipesFailure = error => ({
    type: NutritionActionTypes.GET_NUTRITION_RECIPES_FAILURE,
    payload: error
  });

  // Get Week Wise Calories Data
  export const getWeekWiseCaloriesRequest = payload => ({
    type: NutritionActionTypes.GET_WEEK_WISE_CALORIES_REQUEST,
    payload: payload
  });
  
  export const getWeekWiseCaloriesSuccess = response => {
    return ({
    type: NutritionActionTypes.GET_WEEK_WISE_CALORIES_SUCCESS,
    payload: response
  })};
  
  export const getWeekWiseCaloriesFailure = error => ({
    type: NutritionActionTypes.GET_WEEK_WISE_CALORIES_FAILURE,
    payload: error
  });

  // Get Macro Nutrients Donut Data
  export const getMacroNutrientsDonutRequest = payload => ({
    type: NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_REQUEST,
    payload: payload
  });
  
  export const getMacroNutrientsDonutSuccess = response => {
    return ({
    type: NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_SUCCESS,
    payload: response
  })};
  
  export const getMacroNutrientsDonutFailure = error => ({
    type: NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_FAILURE,
    payload: error
  });

  // Get All Refunds

export const getAllRefundsRequest = (payload) => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_REQUEST,
  payload,
});

export const getAllRefundsSuccess = (payload) => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_SUCCESS,
  payload,
});

export const getAllRefundsFailure = (error) => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_FAILURE,
  payload: error,
});

export const getAllRefundsLoadingStart = () => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_LOADING_START,
});

export const getAllRefundsLoadingStop = () => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_LOADING_STOP,
});

export const getMacroNutrientsPieChartDataRequest = (payload) => ({
  type: NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_REQUEST,
  payload: payload,
});
