import styled from "styled-components";

export const Title = styled.h3`
  color: #142c1f;
`;

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;

  &.NutritionCoachWrap {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
    flex-direction: column-reverse;
    display: flex;
  }
`;

export const VisualContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Image = styled.div`
  background: white;
  padding: 10px;
  border-radius: 15px;
  height: auto; /* Let content drive height for better flexibility */
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 600px) {
    padding: 8px;
    height: auto;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  img {
    display: block; /* Avoid inline spacing issues */
    height: auto; /* Preserve aspect ratio */
    border-radius: inherit; /* Match parent border-radius for consistent look */
    object-fit: cover; /* Ensure image covers the entire area without distortion */
    width: 470px; /* Use max-width instead of fixed width */
    height: 580px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > * {
    margin: 0px;
  }

  img {
    width: 100%;
  }

  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const PlanBoxIcons = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`;

export const PlanBoxIconBox = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const PlanBoxInformation = styled.span`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  width: calc(100% - 40px);
  padding-left: 20px;

  &.ProcedureTop {
    /* font-size: 1.15rem !important; */
    padding-top: 10px;
  }
  &.StandatProgramText {
    /* font-size: 1.15rem !important; */
  }
`;
