import styled from "styled-components";

export const CoachingAccordianContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  align-items: center;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  /* .center {
    display: flex;
    justify-content: flex-end;
  } */
  img {
    width: 100%;
    height: auto;
    max-width: 600px;
    /* max-height: 550px; */
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      margin-top: 150px;
    }
  }

  @media (max-width: 991.99px) {
    height: auto;
    order: 1;
    img {
      max-width: 500px;
      margin-inline: auto;
      display: block;
    }
  }

  @media (max-width: 767.99px) {
    display: none;
  }
`;

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */

  @media (max-width: 767.99px) {
    /* font-size: 25px; */
  }
`;
export const Description = styled.p`
  margin-bottom: 50px;
  margin-top: 20px;
`;
export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`;
