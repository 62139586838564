import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 0.9fr;
  align-items: center;
  padding-top: 170px;
  padding-bottom: 100px;
  margin-bottom: 20px;
  gap: 60px;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 150px 0 7rem;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }

    @media (max-width: 991.99px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  /* color: #44554c; */
`;

export const VisualContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
`;

export const Action = styled.div`
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
  }
`;
