import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as C from "./newAttitude.styled";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import Coach from "assets/images/Page3/Foodiary-Coaching-start.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CentralShape from "assets/images/pngs/central-shape.png";

const NewAttitude = () => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_fm_picture_2
    ? getS3ImageUrl(coach?.cw_fm_picture_2)
    : Coach;

  return (
    <C.Wrap>
      <C.VisualContent>
        {coach?.cw_fm_picture_2 && (
          <C.Image maskImage={CentralShape}>
            <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
          </C.Image>
        )}
        {!coach?.cw_fm_picture_2 && (
          <C.DefaultImage>
            <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
          </C.DefaultImage>
        )}
      </C.VisualContent>
      <C.TextContent>
        <Chip text="ABLAUF" margin="0 0 20px 0" />
        <C.Title className="ct-headline color-dark font-medium">
          Der Weg zu einem neuen Lebensgefühl.
        </C.Title>
        <C.PlanBoxInformation className="padding-zero ct-text-block foodiary-list-item text-base color-dark text-left marg font-normal">
          Das Erstgespräch ist in 3 Phasen unterteilt. So lernen wir uns zuerst
          persönlich kennen, und zudem du erhältst direkt und unverbindlich
          einen eigenen Ernährungsplan.
        </C.PlanBoxInformation>
        <C.PlanBoxIcons className="procedure">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Gemeinsam besprechen wir dein Ziel und erstellen zusammen deinen
              persönlichen Ernährungsplan.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Wir werfen einen Blick auf deine aktuelle IST-Situation und nehmen
              hier wichtige Daten für die Analyse auf.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Ich erstelle dir deinen persönlichen Ernährungsplan und gebe dir
              detaillierte Informationen über die Empfehlungen.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Du erhältst von mir wertvolle Tipps und Tricks rund um das Thema
              der Ernährungsumstellung.
            </C.PlanBoxInformation>
          </li>
          {/* <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Nach unserem Gespräch biete ich dir die Möglichkeit, gemeinsam mit
              mir als deinem Coach zu starten und deine Ziele zu erreichen.
            </C.PlanBoxInformation>
          </li> */}
        </C.PlanBoxIcons>
      </C.TextContent>
    </C.Wrap>
  );
};

export default NewAttitude;
