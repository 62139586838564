const saveRoute = {
  name: "",
};
const routerNameToSave = (name) => {
  if (name == "") return;
  saveRoute.name = name;
};
const getRouteName = () => {
  return saveRoute.name;
};
export { routerNameToSave, getRouteName };
