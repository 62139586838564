import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import ReactPlayer from "react-player";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./ReimburseHealthInsurance.styled";
// Images
import Logo1 from "assets/images/App/techniker-krankenkasse-logo.svg";
import Logo2 from "assets/images/App/aok.svg";
import Logo3 from "assets/images/App/DAK-Gesundheit_logo.svg";
import Logo4 from "assets/images/App/barmer_krankenkasse_logo.svg";
import Logo5 from "assets/images/App/ikk-gesundplus.svg";
import ReimburseHealthInsuranceGif from "assets/images/ReimburseHealthInsurance.gif";

const ReimburseHealthInsurance = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <C.TextContent className="text-content">
        <Chip text="Online Kurs" margin="0 0 20px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Bis zu 100 % Kostenerstattung von deiner Krankenkasse.
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Im Rahmen der Lernacademy erhältst du eine strukturierte Lernumgebung
          mit 8 Einheiten. Jede Einheit beinhaltet informative Videos, in denen
          das Grundlagenwissen zur gesunden Ernährung und Prävention vermittelt
          wird. Die Videos haben eine Dauer von 45 bis 60 Minuten und sind
          praxisbezogen gestaltet, um dir das Selbstlernen zu erleichtern.
        </C.Description>
        <br />
        <C.Description className="ct-text-block color-dark font-medium text-base mb-4">
          UNTERSTÜTZ DURCH:
        </C.Description>
        <C.InsuranceCompanies>
          <div className="company-item">
            <img src={Logo1} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo2} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo3} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo4} alt="" />
          </div>
          <div className="company-item">
            <img src={Logo5} alt="" />
          </div>
        </C.InsuranceCompanies>

        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>
            Erstgespräch vereinbaren
          </button>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <img src={ReimburseHealthInsuranceGif} alt="" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default ReimburseHealthInsurance;
