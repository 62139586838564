import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 60%;
    margin: auto;
  }
`

export const DoneContainer = styled.div`
  .title, .description {
    text-align: center;
  }
  
  .description {
    color: #818D88;
  }

  .title, .action-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .action-title {
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
  }

  .actions {
    margin-top: 50px;
  }
  
  .banners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;

    div {
      padding: 15px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;
      cursor: pointer;
      transition: all 100ms ease-in-out;

      &:hover {
        transform: translateY(-5px);
      }
    }
  }
  
  @media (min-width: 1000px) {
    .banners {
      flex-direction: row;
    }
  }
`
