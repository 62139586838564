import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;

  @media (min-width: 1000px) {
    width: 60%;
    margin: auto;
  }
  
  @media (min-width: 1300px) {
    width: 50%;
    margin: auto;
  }
`

export const NutrientsRecipesContainer = styled.div`
  .title {
    font-weight: bold;
  }

  .description {
    color: #818D88;
  }
  
  .slider-meals {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .meal-card {
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
    border: 1px solid rgb(208, 213, 221);

    .calories {
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        font-weight: bold;
        color: #1D9D87;
      }
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        font-weight: bold;
      }
    }
    
    .preview {
      height: 80px;
      width: 80px;
      align-self: center;
      box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
      border: 1.5px solid rgb(239, 239, 239);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;

      span {
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
    }
  }

  .week-selection {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    > * {
      color: #a5b5af;
      box-shadow: 0 2px 4px rgba(2,51,42,.0784313725);
      border: 2px solid #e2e4e9;
      cursor: pointer;
      padding: 7px 20px;
      border-radius: 30px;
      font-size: 14px;
      transition: all 100ms ease-in-out;
    }
    
    .active {
      background: #2DCF96;
      color: white;
    }
  }
  
  .slider-container {
    margin-top: 30px;

    .slider-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .slider-controls {
        display: flex;
        gap: 10px;
        > * {
          cursor: pointer;
          transition: all 100ms ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }

        img {
          width: 25px;
        }
      }

      span:nth-child(1) {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
`
