import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./AppPrevention.style";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import videoSource from "assets/videos/stats-dashboard.mp4";

const AppPrevention = ({ changeOrder = false, onLeadFormModal }) => {
  const history = useHistory();

  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <div className="text-content">
        <Chip text="App" margin="0 0 20px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Smarte Tools für eine einfache Planung im Alltag.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Mit der Foodiary Ernährungsplaner-App erstellst du in wenigen Klicks
          deinen individuellen Plan – perfekt für Muskelaufbau, Gewichtsabnahme
          oder eine ausgewogene Ernährung. Über 1.700 Rezepte, eine smarte
          Einkaufsliste und praktische Meal-Prep-Funktionen helfen dir, deinen
          Alltag einfacher und gesünder zu gestalten. Starte jetzt und erreiche
          deine Ziele mit Leichtigkeit!
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={() => history.push("/app")}>
            Mehr zur App
          </button>
        </C.Action>
      </div>
      <C.VisualContent>
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default AppPrevention;
