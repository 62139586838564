import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import Personality from "components/Personality/Personality";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
import StandardProgram from "components/StandardProgram/StandardProgram";
import NutritionCoach from "components/NutritionCoach/NutritionCoach";
import YoyoEffect from "components/YoyoEffect/YoyoEffect";
import CoachFromFoodiary from "components/CoachFromFoodiary/CoachFromFoodiary";
import BenefitsGlance from "components/BenefitsGlance/BenefitsGlance";
import StartNutritionChange from "components/StartNutritionChange/StartNutritionChange";
import StartTogether from "components/StartTogether/StartTogether";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import PaymentModal from "modals/PaymentModal/PaymentModal";
import FailureDialog from "modals/FailureDialog";

// Redux
import * as CoachActions from "redux/coach/coach.actions";
import * as Routes from "routes/routes";

// Styles
import * as G from "styles/General.Styles";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import hook from "assets/images/hook.svg";
import WhyClientsLoveUs from "components/WhyClientsLoveUs/WhyClientsLoveUs";
import LastingSuccessWithKnowledge from "components/LastingSuccessWithKnowledge/LastingSuccessWithKnowledge";
import DietPlanning from "components/DietPlanning/DietPlanning";
import OwnNutritionCoach from "components/OwnNutritionCoach/OwnNutritionCoach";
import FullSupport from "components/FullSupport/FullSupport";
import HealthInsuranceCompanies from "components/HealthInsuranceCompanies/HealthInsuranceCompanies";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import { IS_LOCAL } from "configs/utils";
import CoachingCover from "components/CoachingCover/CoachingCover";
import DigitalToolsCover from "components/DigitalToolsCover/DigitalToolsCover";
import PlannerApp from "components/PlannerApp/PlannerApp";
import LearnIn12Weeks from "components/LearnIn12Weeks/LearnIn12Weeks";
import CoachingHealthCompanies from "components/CoachingHealthCompanies/CoachingHealthCompanies";
import videoSource from "assets/videos/Coach-Animated-Video-Course.mp4";

const DigitalTools = (props) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);

  const coach = useSelector((state) => state.coach.coach);

  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const { success } = queryString.parse(search);
    if (success == "true") {
      setSuccessDialog(true);
      history.push(Routes.SUCCESS);
    }
    if (success == "false") {
      setCancelDialog(true);
    }
  }, [search]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (coach) return;

    const coach_website_url = IS_LOCAL
      ? "https://ronja-fci49818.foodiary.app"
      : `${window.location.protocol}//${window.location.host}`;

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);
  const callBack = () => {
    history.push("/online-kurs");
  };
  return (
    <>
      <StickyNavbar openSurveyModal={() => setOpenLeadModal(!openLeadModal)} />

      <div class="overview-wrapper">
        <G.Container>
          <DigitalToolsCover onLeadFormModal={() => setOpenLeadModal(true)} />
        </G.Container>
      </div>

      <G.Container>
        <SectionWrapper margin="15rem 0 0" mobileMargin="7rem 0 0">
          <PlannerApp onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <LearnIn12Weeks
            onLeadFormModal={() => setOpenLeadModal(true)}
            changeOrder
            title={
              "Die Ernährung im Fokus: Lerne in 12-Wochen wichtige Grundlagen."
            }
            description={
              "Im Rahmen des 12-Wochen Online Kurs erhältst du eine strukturierte Lernumgebung mit 8 Einheiten. Jede Einheit umfasst informative Videos, die dir das Grundlagenwissen zur gesunden Ernährung und Prävention vermitteln. Die Videos sind praxisorientiert und dauern zwischen 45 und 60 Minuten. Um das Selbstlernen zu erleichtern, stehen dir Arbeitsblätter und Handouts zur Verfügung."
            }
            videoSource={videoSource}
            callBack={callBack}
            btnText={"Mehr zum Online Kurs"}
            firsTitle={" Die Ernährung im Fokus: Lerne in"}
            secondTitle={"12-Wochen wichtige Grundlagen."}
          />
        </SectionWrapper>

        <PaymentModal
          isOpen={openPaymentModal}
          onClose={() => setOpenPaymentModal(false)}
          couchProfile={coach}
        />
        <FailureDialog
          open={cancelDialog}
          close={() => setCancelDialog(false)}
        />
      </G.Container>
      <SectionWrapper margin="15rem 0 0" mobileMargin="0 0 0">
        <CoachingHealthCompanies
          title={"VON ALLEN KRANKENKASSEN Anerkannt"}
          subtitle={"Anerkannt ist und Bis zu 100% erstattet werden"}
          bgColor={"white"}
          hideImage={true}
          chipText={"Online Kurs"}
        />

        {/* <HealthInsuranceCompanies
            title={"Ein Online-Kurs, der von Allen Krankenkassen"}
            subtitle={"Anerkannt ist und Bis zu 100% erstattet werden"}
            hideHeader={true}
          /> */}
      </SectionWrapper>
      <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
        <InitialConsultationBox
          openLeadFormModal={() => setOpenLeadModal(true)}
        />
      </SectionWrapper>

      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default DigitalTools;
