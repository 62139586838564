import React from "react";
import { useHistory } from "react-router-dom";
import Chip from "components/General/formComponents/chip/Chip";

import * as F from "./DeliciousHealth.style";
import FoodiaryErstgesprach from "assets/images/Coach-Rezepte-App.jpg";

const DeliciousHealth = ({ onLeadFormModal }) => {
  const history = useHistory();
  const handleClick = () =>
    history.push({
      pathname: "/onboarding/gender",
      state: { params: "app" },
    });

  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="REZEPTE" margin="0 0 0 0" />

        <F.Title className="ct-text-block color-dark text-left font-medium">
          Mit Foodiary geht gesund auch lecker.
        </F.Title>

        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Ein Ernährungsplan von Foodiary bietet dir über 1.700 leckere und
          abwechslungsreiche Rezepte, darunter auch Mamas Lieblingsgerichte. Du
          kannst ganz einfach deine eigenen Rezepte hinzufügen. Alle Gerichte
          werden individuell auf deine Kalorien, Makronährstoffe sowie deine
          Ernährungsform abgestimmt – einschließlich vegetarischer und veganer
          Optionen. So findest du stets die perfekte Balance und kannst gesunde
          Ernährung genießen, ohne auf Genuss verzichten zu müssen!
        </F.Description>
        <div>
          <button className="btn-dark" onClick={handleClick}>
            Ernährungsplan erstellen
          </button>
        </div>
      </F.ContentSection>

      <F.VisualContent>
        <img src={FoodiaryErstgesprach} alt="coach" />
        {/* <ReactPlayer
          url={"https://foodiary.app/data/rezepte-auswaehlen-foodiary.mp4"}
          playsinline
          playing
          muted
          loop
        />
        <div className="white-border" /> */}
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default DeliciousHealth;
