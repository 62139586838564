import styled from "styled-components";

export const CoachingWithMeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Title = styled.h3`
  color: #142c1f;
`;

export const Description = styled.p`
  /* color: #44554c; */
  /* font-size: 1rem;
  font-weight:400; */
`;

export const VisualContent = styled.div`
  position: relative;
  & > div {
    max-width: 700px;
    width: 100% !important;
    height: auto !important;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 700px;
    margin-left: auto;
    display: block;
  }

  .white-border {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100% !important;
    height: 3px !important;
    background-color: #fff;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`;

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
