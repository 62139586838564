import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import { getPreviousOnboardingPage, getNextOnboardingPage } from '../../../routes/onboardingRoutes'
import * as Styles from './WeeklyCaloriesStyles'
import { getOnboardingStorage, REGISTER_STEP } from '../storage'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import OnboardingLoader from '../wrapper/OnboardingLoader'
import { Button, Modal, InputNumber, Select, Input } from 'antd'
import ReactApexChart from 'react-apexcharts'

const MAX_CALORIES = Infinity
const MIN_CALORIES = 100

function NutritionRecipes ({ currentPath }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [ chartData, setChartData ] = useState(null)
  const [ updateModal, setUpdateModal ] = useState({ show: false })
  const [ weekOptions, setWeekOptions ] = useState(null)
  const [ selectedWeek, setSelectedWeek ] = useState(null)
  const storage = getOnboardingStorage(REGISTER_STEP)
  const concept = storage['$nutritionconcept']
  const nutritiontypeID = concept?.fitnessdata?.nutritiontypeID
  const nutritionconceptID = concept?.fitnessdata?.nutritionconceptID
  const aimID = concept?.fitnessdata?.aimID
  const user_id = concept.userID
  const sex = concept.user.sex

  useEffect(() => {
    fetchWeeklyCalories()
  }, [ dispatch ])

  useEffect(() => {
    if (!weekOptions) return
    setSelectedWeek(weekOptions[0])
  }, [ weekOptions ])

  if (!chartData || !weekOptions || !selectedWeek) return <OnboardingLoader />

  const lastWeekCalories = chartData.data['week1'][0].calories
  const firstWeekCalories = chartData.data['week12'][0].calories
  const caloriesDifference = parseFloat(firstWeekCalories - lastWeekCalories).toFixed(2)

  function fetchWeeklyCalories () {
    dispatch(
      NutritionActions.getWeekWiseCaloriesRequest({
        nutritiontypeID,
        aimID,
        sex,
        user_id,
        callback: response => {
          setChartData(response)
          setWeekOptions(Object.entries(response.data).map((weekItem, index) => {
            const [ week, weekCalories ] = weekItem
            return {
              label: `Woche ${index + 1}`,
              value: week,
              weekNumber: index + 1,
              weekCalories
            }
          }))
        }
      })
    )
  }

  const handleNextPage = () => (
    history.push(getNextOnboardingPage(currentPath))
  )

  const handleCloseModal = () => setUpdateModal({ show: false })
  const updateSelectedWeek = weekValue => {
    setSelectedWeek(weekOptions.find(week => week.value === weekValue))
  }

  const updateSelectedWeekCalories = (value, dayType) => {
    if (value >= MAX_CALORIES || value < MIN_CALORIES) return
    setSelectedWeek({
      ...selectedWeek,
      weekCalories: selectedWeek.weekCalories.map(day => {
        if (day.daytypeID === dayType) return { ...day, calories: value }
        return day
      })
    })
  }

  const updateSelectedWek = () => {

    setChartData(null) // to show the loader immediatley
    setUpdateModal({ show: false })

    dispatch(
      NutritionActions.updateNutritionCaloriesRequest({
        nutritiontypeID,
        nutritionconceptID,
        aimID,
        sex,
        week: selectedWeek.weekNumber,
        caloriesData: selectedWeek.weekCalories.map(day => {
          if ( day.daytypeID === 'Erholungstag' ) return { ...day, daytypeID: 1 }
          return { ...day, daytypeID: 2 }
        }),
        callback: (response, error) => {
          if (error || !response) return
          fetchWeeklyCalories()
        },
      })
    )
  }

  const updateModalContent = (
    <Modal
      centered
      footer={false}
      title="Kalorien bearbeiten"
      open={updateModal.show}
      onCancel={handleCloseModal}
    >
      <Styles.UpdateModalContent>
        <div className="week-selection">
        <Select
          showSearch
          placeholder="Woche auswählen"
          optionFilterProp="label"
          value={selectedWeek.value}
          onChange={updateSelectedWeek}
          options={weekOptions}
        />
        </div>
        {selectedWeek.weekCalories.map(day => {
          return (
            <div key={day.daytypeID} className="week-calories">
              <span>{day.daytypeID}</span>
              <Styles.InputContainer>
                <Styles.InputControl onClick={
                  () => updateSelectedWeekCalories(day.calories - 1, day.daytypeID)
                }>-</Styles.InputControl>
                <InputNumber
                  prefix="kcal"
                  onChange={value => updateSelectedWeekCalories(value, day.daytypeID)}
                  value={parseFloat(day.calories).toFixed(2)}
                />
                <Styles.InputControl onClick={
                  () => updateSelectedWeekCalories(day.calories + 1, day.daytypeID)
                }>+</Styles.InputControl>
              </Styles.InputContainer>
            </div>
          )
        })}
        <div className="cta">
          <Button onClick={updateSelectedWek}>Kalorien ändern</Button>
        </div>
      </Styles.UpdateModalContent>
    </Modal>
  )

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Auswertung</div>
            <div className="sub-headline">Unsere Empfehlung für dich</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <Styles.WeeklyCaloriesContainer>
            { updateModalContent }
            <div className="title">Dein persönlicher Tagesbedarf</div>
            <div className="chart-container">
              <div className="chart-header">
                <div>
                  Kalorienverlauf
                </div>
                <div>
                  <span>{caloriesDifference} kcal</span>
                  <span>in 12 Wochen</span>
                </div>
              </div>
              <div className="apex-chart">
                <ChartContent data={chartData.data} />
              </div>
            </div>
            <div className="sep"></div>
            <div className="description">{chartData['02_text']}</div>
            <div className="action">
              <Button onClick={() => setUpdateModal({ show: true })}>Kalorien ändern</Button>
            </div>
          </Styles.WeeklyCaloriesContainer>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

function ChartContent ({ data }) {
  const weeks = Object.keys(data)

  const trainingDayCalories = weeks.map(
    (week) =>
      parseFloat(
        data[week].find((day) => day.daytypeID === "Trainingstag")?.calories || 0
      ).toFixed(2)
  )

  const restDayCalories = weeks.map(
    (week) =>
      parseFloat(
        data[week].find((day) => day.daytypeID === "Erholungstag")?.calories || 0
      ).toFixed(2)
  )

  const chartOptions = {
    chart: { type: "line", height: 400, toolbar: { show: false } },
    markers: { size: 5 },
    legend: { position: "bottom" },
    colors: ['#44554C', '#2ECF96'],
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth' },
    tooltip: {},
  }

  const chartSeries = [
    { name: "Trainingstag", data: trainingDayCalories },
    { name: "Erholungstag", data: restDayCalories },
  ]

  return <ReactApexChart
    options={chartOptions}
    series={chartSeries}
    type="area"
    height={400}
  />
}

export default NutritionRecipes
